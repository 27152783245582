import mixpanel from "mixpanel-browser";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Common/Loader";
import { MyContext } from "../MyContext";
import language_image from "../assets/clarity_language_line.png";
import close from "../assets/close.png";
import email from "../assets/email.png";
import logout from "../assets/log-out.png";
import profile from "../assets/profile-p.png";
import smart from "../assets/smartphone.png";
import subscription from "../assets/subscription.png";
import { getCibilString, getProfileDetail, profilePicture } from "../services/ApiServices";


mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
  debug: true,
});
const Profile = () => {
  const [currentLanguage, setCurrentLanguage] = useState("English");
  const { switchLanguage, languageData, language, cibilString } =useContext(MyContext);
  const [loader, setLoader] = useState(false);
  const [drop, setDrop] = useState(false);
  console.log(languageData,"languagedata")
  const [details, setDetails] = useState();
  const navigate = useNavigate();
  const [subscriptionPresent, setSubscriptionPresent]= useState(false)
  const [profileImage ,setProfileImage] = useState()
  

  
  console.log(process.env.REACT_APP_API_BASE_URL, "envtest");
  useEffect(() => {
    setLoader(true)
    getProfileDetail()
      .then((res) => {
        setDetails(res?.user_profile_dict);
        console.log("details", res?.user_profile_dict);
      })
      .catch((err) => {});
  //   getSubs().then((res)=>{
  //   //console.log(res?.message.status, "res")
  //   setSubscriptionPresent(res.message.status)
  
  // }).catch(()=>{})

   getCibilString()
      .then((res) => {
       
     setSubscriptionPresent(res?.payment_status)
     setLoader(false)
      //console.log(res, "response", res?.payment_status)
      })
      .catch((err) => {
      
      });
      getProfile()
  //console.log(localStorage.getItem('cibilString'), "cibil")
  }, []);
  const fnState = (prop) => {
    switchLanguage(prop === "Hindi" ? true : false);
    setCurrentLanguage(prop);
  };

  const handleDrop = () => {
    setDrop(!drop);
  }; 
  const fnLogout = ()=>{
    localStorage.removeItem('accessToken')
    setTimeout(()=>{
        window.location.href="/"
        //navigate('/')
    },2000)
    
  }
  const getProfile = () => {
    profilePicture().then((res)=>{setProfileImage(res?.data?.presigned_url)}).catch(()=>{})  
  } 

  const fnMangeBtn = ()=>{
    mixpanel.track("Interacted_User_Profile", {"Clicked On":"Manage Subsciption",status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
    navigate('/manage_subscription')
  }

  return (
    <>
      {loader ? <Loader /> : null}
      <section className=" profile-pg subs-padd ">
        <div className="container container-sm">
          <div className="flex align-center profile">
            <img
              src={profileImage ? profilePicture: profile}
              alt="profile"
              width={75}
              height={75}
              className="photo"
            />
            <div className="profile-data">
              <p className="name">{details?.name}</p>
              <div className="flex email align-center">
                <img src={email} alt="email" width={14} height={14} />
                <p>{details?.email}</p>
              </div>
              <div className="flex phone align-center">
                <img src={smart} alt="email" width={14} height={14} />
                <p>{details?.phone_number}</p>
              </div>
            </div>
          </div>
          <div className="profile-menu">
            <div className="list flex align-center">
              <img
                src={language_image}
                alt="lang"
                width={25}
                height={25}
                className="mb-25"
              />
              <p>{languageData === false ?  <>Languages</>:<>{languageData?.language}</>}</p>
              <div className="language flex ">
                <input
                  checked={languageData===false}
                  onClick={() => {
                    fnState("English");
                  }}
                  type="radio"
                  id="english"
                 
                  value="English"
                />
                <label for="english">English</label>
                
                <br />
                <input
                  checked={languageData !== false}
                  onClick={() => {
                    fnState("Hindi");
                  }}
                  type="radio"
                  id="hindi"
                  name="fav_language"
                  value="Hindi"
                />
                <label for="hindi">हिंदी</label>
                <br />
              </div>
            </div>
            {subscriptionPresent === true && (
              <div onClick={()=>{fnMangeBtn()}} className="list flex align-center">
                <img
                  src={subscription}
                  alt="lang"
                  width={25}
                  height={25}
                  className="mb-25"
                />
                <p>
                  <label href="/manage_subscription">
                    {languageData ? (
                      languageData.Manage_Subscription
                    ) : (
                      <>Manage Subscription</>
                    )}
                  </label>
                </p>
              </div>
            )}

            {/* <div className='list'>
                    <div className='flex align-center' onClick={handleDrop}>
                        <img src={approved} alt='lang' width={25} height={25} className='mb-25' />
                        <p>
                            {languageData ? languageData.download_report:<>Download Report</>}
                        </p>
                        <img src={dropdown} alt='lang' width={13} height={11} className='mb-25' style={{marginLeft:"10px"}}/>
                    </div>
                    {drop ? (
                    <div className='dropdown'>
                        <p>Jan 2024</p>
                        <p>Jan 2024</p>
                        <p>Jan 2024</p>
                        <p>Jan 2024</p>
                        <p>Jan 2024</p>
                        <p>Jan 2024</p>
                        <p>Jan 2024</p>
                        <p>Jan 2024</p>
                        <p>Jan 2024</p>
                        <p>Jan 2024</p>
                        <p>Jan 2024</p>
                        <p>Jan 2024</p>
                    </div>
                    ) : null}
                </div> */}
            <div onClick={()=>{fnLogout()}} className="list flex align-center">
              <img
                src={logout}
                alt="lang"
                width={25}
                height={25}
                className="mb-25"
              />
              <p>{languageData === false  ? <>Logout</>:languageData.logout }</p>
            </div>
          </div>
        </div>
        <img
          src={close}
          alt="cross"
          width={55}
          height={55}
          className="close"
          onClick={() => navigate(-1)}
        />
      </section>
    </>
  );
};

export default Profile;
