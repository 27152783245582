// eslint-disable-next-line import/no-anonymous-default-export
export default {
    GET_CIBI_STRING:`${process.env.REACT_APP_API_BASE_URL}cam/api/v1/get-cibil-string`,
    GET_PAYMENT_VALUE:`${process.env.REACT_APP_API_BASE_URL}cam/api/v1/get-payment-values`,
    SUGGESTIONS: `${process.env.REACT_APP_API_BASE_URL}cam/api/v1/get-suggestion`,
    CIBIL_REPORT_EQUIFAX: `${process.env.REACT_APP_API_BASE_URL}cam/api/v1/equifax_cibil_fetch`,
    CIBIL_REPORT_FORM: `${process.env.REACT_APP_API_BASE_URL}cam/api/v1/user-register-web`,
    CREATE_PAYMENT: `${process.env.REACT_APP_API_BASE_URL}cam/api/v1/create-payment`,
    PAYMENT_STATUS: `${process.env.REACT_APP_API_BASE_URL}cam/api/v1/get-transaction-status`,
    CIBIL_PAYMENT_CHECK: `${process.env.REACT_APP_API_BASE_URL}cam/api/v1/cibil-payment-check`,
    CREDIT_FACTOR: `${process.env.REACT_APP_API_BASE_URL}cam/api/v1/credit-factors`,
    CIBIL_REPORT: `${process.env.REACT_APP_Cibil_API_BASE_URL}v1/report/`,
    CREATE_FEEDBACK: `${process.env.REACT_APP_API_BASE_URL}cam/api/v1/create-feedback`,
    APPS_FLYER: `${process.env.REACT_APP_API_BASE_URL}cam/api/v1/create-appsfyer`,
    LOGIN: `${process.env.REACT_APP_API_BASE_URL_LOG}user_manage/api/v1/v2/authentication/app/phone_no_verify/`,
    OTP: `${process.env.REACT_APP_API_BASE_URL_LOG}user_manage/api/v1/v2/authentication/create_onboard_user/`,
    REGISTER: `${process.env.REACT_APP_API_BASE_URL_LOG}user_manage/api/v1/authentication/register_user/`,
    EMAIL_VERIFY: `${process.env.REACT_APP_API_BASE_URL_LOG}user_manage/api/v1/authentication/email_verify/`,
    EMAIL_OTP_VERIFY: `${process.env.REACT_APP_API_BASE_URL_LOG}user_manage/api/v1/authentication/email_otp_verify_register/`,
    STORE_USER_SESSION: `${process.env.REACT_APP_DEEP_API_BASE_URL}api/app/user-session-history/`,
    ENHANCED_REPORT: `${process.env.REACT_APP_API_BASE_URL}cam/api/v1/enhanced-reports`,
    GRAPH_DATA:`${process.env.REACT_APP_API_BASE_URL}cam/api/v1/get-compared-json`,
    AB_TESTING:`${process.env.REACT_APP_API_BASE_URL}cam/api/v1/get-ab-test-number`,
    SAVE_DETAILS:`${process.env.REACT_APP_API_BASE_URL}cam/api/v1/save-details`,
    GET_PROFILE_PICTURE:`${process.env.REACT_APP_API_BASE_URL}cam/api/presigned_url_user_image/`,
    GET_PROFILE: `${process.env.REACT_APP_API_BASE_URL}cam/api/v1/get-user-profile-details`,
    POST_CANCEL: `${process.env.REACT_APP_API_BASE_URL}cam/api/v1/cancel-subscription`,
    GET_MANDATE: `${process.env.REACT_APP_API_BASE_URL}cam/api/v1/mandate-status`,
    GET_SUBS_PLAN: `${process.env.REACT_APP_API_BASE_URL}cam/api/v1/user-subscription-status`,
    POST_PHONPE: `${process.env.REACT_APP_API_BASE_URL}cam/api/v1/phone-pe-subscription`,
    POST_VALIDATE: `${process.env.REACT_APP_API_BASE_URL}cam/api/v1/validate-vpa`,
    EQUIFAX_REPORT: `${process.env.REACT_APP_API_BASE_URL}cam/api/v1/equifax_cibil_report`,
    POST_UPDATE_SUBSCTIPTION:`${process.env.REACT_APP_API_BASE_URL}cam/api/v1/suspend-subscription`,
}

