import { default as React } from 'react';
import hours from "../assets/hours.png";
import alert from "../assets/mobile-alert.png";
import report from "../assets/credit-reportt.png";

const someData = [{
    "image":hours,
    "title":"Tailored suggestions"
},
{
    "image":alert,
    "title":"Personalized Credit report"
},
{
    "image":report,
    "title":"Get Higher Loan limit within 60 Days*"
}
]

function Suggestions(props) {
    const {someData} = props

  return (
    <div>
        <div className="suggestion mb-20">
            {someData.map((some, index)=>{
                return(
                <div key={index} className={index!==0 ?"particularLine mt-5":"particularLine"}>
                    <p className="imageCircle"><img height={18} width={18} src={some.image} alt='icon'/></p>
                    <p className="particularText">
                        {some.title}
                    </p>
                </div>
                )
            })}
           
        </div>
    </div>
  )
}

export default Suggestions