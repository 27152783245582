import { default as React } from 'react';
import imageRight from "../assets/arrow-right.png";

function RightWorks(props) {
    const {appData,descSize=16,  typeRight=false} = props
  return (
    <div> {appData?.map((some, index) => {
            return (
              <div key={index} className="checkFlex">
                <img height={22} width={12} src={imageRight} />
                <div>
                  {typeRight && <p className="checkFlexTitle">{some.title}</p>}
                  <p className="checkFlexDescribe">{some.describe}</p>
                </div>
                <div></div>
              </div>
            );
          })}
        
    </div>
  )
}

export default RightWorks
