import React, { useState, useEffect, useContext } from "react";
import { Loader } from "../Common/Loader";
import { useNavigate } from "react-router-dom";
import { getPaymentAmount, getStatus, upiPay } from "../services/ApiServices";
import Header from "../Common/Header";
import arrowRight from "../assets/arrow-right.png";
import check from "../assets/check.png";
import blank from "../assets/blank-img.png";
import basic from "../assets/basic.png";
import premium from "../assets/premium.png";
import tag from "../assets/discount-tag.png";
import ToastMsg from "../Common/ToastMsg";
import { MyContext } from "../MyContext";
import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
  debug: true,
});

const SelectPlan = () => {
  const {languageData} = useContext(MyContext)
  const [loader, setLoader] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [amount, setAmount] = useState("");
  const [transId, setTransactionId] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const navigate = useNavigate();
  const handleClick = () => {
    setIsActive(true);
    mixpanel.track("Interacted_CA_Select_Plan", { "Clicked on ": "Basic Plan", status: true, "Platform": "app" })
  };
  const handleClickT = () => {
    setIsActive(false);
    mixpanel.track("Interacted_CA_Select_Plan", { "Clicked on ": "Premiuim Plan", status: true, "Platform": "app" })
  };
  const navigateTofail = (props) => {
    navigate("/payment-status", {
      state: {
        transactionId: props?.transaction_id,
      },
    });
  };
  useEffect(() => {
    mixpanel.track("Viewed_CA_Select_Plan", { status: true, "Platform": "app" })
    if (transId) {
      const interval = setInterval(() => {
        getPaymentStatus(transId);
      }, 2000);
      return () => clearInterval(interval);
    }
    handlePaymentValue()
  }, [timeLeft, transId]);

  const submitPayment = () => {

    mixpanel.track("Interacted_CA_Page", { "Clicked on ": `Proceed To Pay`, status: true, "Platform": "app" })
    let eurl = "";
    let data = {
      balance: isActive ? amount?.main_pay : amount?.high_pay,
    };
    if (isButtonDisabled) {
      return;
    }
    upiPay(data)
      .then((res) => {
        console.log(res?.data?.data);
        if (res?.status === 200) {
          setLoader(false);
          console.log(res?.data);
          eurl = res?.data?.data;
          setTransactionId(res?.data?.transaction_id);
          getPaymentStatus(res?.data?.transaction_id);
          console.log(res, eurl, "eurlll");
          setTimeout(() => {
            window.location.href = eurl;
          }, 100);
          setTimeLeft(500);
        }
      })
      .catch((err) => {
        setLoader(false);
        setIsButtonDisabled(true);
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 2500);
        if (err?.response?.status === 401) {
          window.location.href = "/";
          localStorage.clear();
        }
      });
  };
  const getPaymentStatus = (transaction) => {
    let data = {
      transaction_id: transaction ? transaction : transId,
    };
    getStatus(data)
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data?.status === "SUCCESS") {
            navigate("/payment-status?status=success");
          }
          if (res?.data?.status === "FAILURE") {
            navigateTofail(res.data);
          }
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err.response?.status === 401) {
          window.location.href = "/";
          localStorage.clear();
        }
      });
  };
  const handlePaymentValue = () => {
    getPaymentAmount()
      .then((res) => {
        setLoader(false);
        setAmount(res?.data)
      })
      .catch((err) => {
        setLoader(false);
        if (err.response?.status === 401) {
          // console.log(err,"error")
        }
        let error = err?.response?.data?.error || "something went wrong";
        ToastMsg(error, "error");
      });
  };
  let features = [
    {
      featureList:languageData?.Received_tailored_suggestions|| "Get tailored suggestions",
      list: arrowRight,
      basicCheck: check,
      premiumCheck: check,
    },
    {
      featureList: languageData?.Personalized_Credit_Report || "Personalized Credit report",
      list: arrowRight,
      basicCheck: check,
      premiumCheck: check,
    },
    {
      featureList: languageData?.Get_loan_within_60_days|| "Loan within 60 Days",
      list: arrowRight,
      basicCheck: check,
      premiumCheck: check,
    },
    {
      featureList: languageData?.Access_to_Personal_Credit_Expert|| "Access to Personal Credit Expert",
      list: arrowRight,
      basicCheck: blank,
      basicClass: "listClass",
      premiumCheck: check,
    },
    {
      featureList:languageData?.analysis_of_credit_report|| "Analysis of Credit Report",
      list: arrowRight,
      basicCheck: blank,
      basicClass: "listClass",
      premiumCheck: check,
    },
    {
      featureList:languageData?.Credit_Score_Improvement|| "Credit Score Improvement",
      list: arrowRight,
      basicCheck: blank,
      basicClass: "listClass",
      premiumCheck: check,
    },
    {
      featureList:languageData?.Credit_Issue_Resolution|| "Credit Issue Resolution",
      list: arrowRight,
      basicCheck: blank,
      basicClass: "listClass",
      premiumCheck: check,
    },
    {
      featureList:languageData?.Latest_Credit_Reports|| "3 Latest Credit Reports",
      list: arrowRight,
      basicCheck: blank,
      basicClass: "listClass",
      premiumCheck: check,
    },
  ];
  console.log(isActive ? 59 : amount.high_pay, isActive, "balance");
  return (
    <>
      {loader ? <Loader /> : null}
      <section className="select-plan">
        <div className="container container-sm">
          <Header />
          <h1 className="text-center mb-20">{languageData?.select_your_plan|| "Select your Plan"}</h1>
          <div className="parent-plan-sc">
            <div className="child-left-sc">
              <div className="plan-sc flex align-stretch space-between">
                <div className="plan-head-table">
                  <h4>{languageData?.Feature|| "Features"}</h4>
                </div>
                <div
                  className={
                    isActive ? "plan-head-table plan-heead active" : "plan-head-table plan-heead"
                  }
                  onClick={handleClick}
                >
                  <h4>{languageData?.Basic|| "Basic"}
                  {/* <span>1 Month</span> */}
                  </h4>
                </div>
                <div
                  className={
                    !isActive ? "plan-head-table plan-heead active" : "plan-head-table plan-heead"
                  }
                  onClick={handleClickT}
                >
                  <h4>{languageData?.Premium|| "Premium"}
                  {/* <span>12 Months</span> */}
                  </h4>
                </div>
              </div>
              <div className="plan-sc flex align-stretch space-between mb-30">
                <div className="plan-head-table">
                  
                    <ul >
                    {features.map((item, index) => (
                      <li className="flex align-start" key={index}>
                        <img src={item.list} alt="list" width={10} height={10} />
                        {item.featureList}
                      </li>
                    ))}
                    </ul>
                  
                </div>
                <div
                  className={
                    isActive ? "plan-head-table table-content active" : "plan-head-table table-content"
                  }
                  onClick={handleClick}
                >
                  
                    <ul>
                      {/* <li>{item?.basicCheck&&<img src={item.basicCheck} alt='check' width={16} height={16} />}</li> */}
                      {features.map((item, index) => (
                      <li className={item.basicClass} key={index}>
                        <img
                          src={item.basicCheck}
                          alt="check"
                          width={16}
                          height={16}
                        />
                      </li>
                      ))}
                    </ul>
                </div>
                <div
                  className={
                    !isActive ? "plan-head-table table-content active" : "plan-head-table table-content"
                  }
                  onClick={handleClickT}
                >
                  
                    <ul>
                    {features.map((item, index) => (
                      <li key={index}>
                        <img
                          src={item.premiumCheck}
                          alt="check"
                          width={16}
                          height={16}
                        />
                      </li>
                    ))}
                    </ul>
                  
                </div>
              </div>
            </div>
            <div className="child-right-sc">
              <div className="select-price flex align-center space-between">
                <div
                  className={
                    isActive
                      ? "plan-price flex align-center active"
                      : "plan-price flex align-center"
                  }
                  onClick={handleClick}
                >
                  <img src={basic} alt="icon" className="icon" width={36} height={36} />
                  <div className="plan-name">
                    <h4>{languageData?.Basic_plan|| "Basic Plan"}</h4>
                    <h4>
                      ₹{amount?.main_pay}&nbsp;&nbsp;<del>₹{amount?.main_pay_actual}</del>
                    </h4>
                  </div>
                  <div className="discount-tag">
                    <img src={tag} alt="tag" width={69} height={23} />
                    <span>{amount?.main_pay_discount} {languageData?.off||"OFF"}</span>
                  </div>
                </div>
                <div
                  className={
                    !isActive
                      ? "plan-price flex align-center active"
                      : "plan-price flex align-center"
                  }
                  onClick={handleClickT}
                >
                  <img src={premium} alt="icon" className="icon" width={36} height={36} />
                  <div className="plan-name">
                  <h4>{languageData?.Premium_plan|| "Premium Plan"}</h4>
                    <h4>
                      ₹{amount?.high_pay}&nbsp;&nbsp;<del>₹{amount?.high_pay_actual}</del>
                    </h4>
                  </div>
                  <div className="discount-tag">
                    <img src={tag} alt="tag" width={69} height={23} />
                    <span>{amount?.high_pay_discount} {languageData?.off|| "OFF"}</span>
                  </div>
                  <span className="timeline">For 3 Months</span>
                </div>
              </div>
              <div className="button-wrapper text-center mt-100">
                <button className="btn mb-30" onClick={submitPayment}>
                {languageData?.Continue_To_Pay|| "Continue to Pay"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SelectPlan;
