import mixpanel from 'mixpanel-browser';
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Suggestions from "../Common/Suggestions";
import { MyContext } from "../MyContext";
import alert from "../assets/mobile-alert.png";
import report from "../assets/credit-reportt.png";
import imageSide from "../assets/sideCover.png";
import hours from "../assets/hours.png";


mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
  debug: true,
});

function SelectSubscription() {
  const {languageData,language} = useContext(MyContext)
  const [selectedPlan, setSelectedPlan] = useState("YEARLY");
  const navigate = useNavigate();
  //const router = useRouter();
    mixpanel.track("Viewed_Select_Subscription_Plan", { status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })

  const selectSubscription = (data)=>{
    mixpanel.track("Interacted_Select_Subscription_Plan", {"Clicked on":"Subscribe",status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
    localStorage.setItem("subscription",selectedPlan)
    navigate('/subscription')
  }

  const tc=()=>{
    navigate("/term")
    mixpanel.track("Interacted_T&C", {status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })        
  }

  const someData = [{
    "image":hours,
    "title":!languageData?"24/7 credit monitoring with instant alerts":languageData?.credit_monitoring_with_instant_alert
},
{
    "image":report,
    "title": !languageData?"Monthly updates of your full credit reports and scores":languageData?.Monthly_update_of_your_full_credit_reports_and_scores
},
{
    "image":alert,
    "title": !languageData?"Get alerts to spot issues before they damage your score":languageData?.Get_alert_to_spot_issues_before_they_demage_your_score
}
]
  return (
    <div>
      <div className="container someCenter">
        <div className="subs-padd">  

       
        <div className="heading flex space-between align-center mb-30">
          <h1>{!languageData?<>Select Subscription Plan</>:languageData?.Select_Subscription_Plan}</h1>
        </div>
        <p className="subsText">{!languageData?<> Unlock your credit potential with </>:languageData.Credit_Assist}<span className="redIn"> {!languageData?<>Credit Assist</>:languageData.Unlock_your_credit_potential_with}</span></p>
        <Suggestions someData={someData}/>
        <p className="subsText subsText2"><span className="redIn"> {!languageData?<>Subscribe now</>:languageData.Subscribe_now_and_unlock_your_credit_potential}</span>{!languageData?<> and unlock your credit potential! </>:languageData.with}</p>
        </div>
        <div className='radio-sec'>
          <div
            className={selectedPlan === "YEARLY" ? "radioButton active" : "radioButton"}
            onClick={() => {
              localStorage.setItem("subscription","YEARLY")
              setSelectedPlan("YEARLY");
              mixpanel.track("Interacted_Select_Plan", {"Clicked on":'Yearly199',status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType")})
            }}
          >
            <label className="main-check">
              <input checked={selectedPlan === "YEARLY"} type="radio" className="radioInside" />
              <span className="geekmark"></span>
            </label>
            <div className="radioColumn mt-12">
              <img className="imageReco" src={imageSide} alt='img' />
              <div className="radioDiv">

              <p className="insideText">
              ₹12.5/
                {!languageData?<>
                  Month
                </>:languageData?.monthly}
                </p>
              <span className="radioTag">
                  57% off 
              </span>
              </div>
              <div className="rupee">
                <p>
                {!languageData?<>
                  Billed Yearly
                </>:languageData.Billed_Yearly}&nbsp;
                @ ₹149
                </p>
              </div>
            </div>
          
          </div>
          <div
            className={selectedPlan === "MONTHLY" ? "radioButton active" : "radioButton"}
            onClick={() => {
              localStorage.setItem("subscription","MONTHLY")
              setSelectedPlan("MONTHLY");
              mixpanel.track("Interacted_Select_Plan", {"Clicked on":'Monthly29',status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })

            }}
          >
            <label className="main-check">
              <input checked={selectedPlan === "MONTHLY"} type="radio" className="radioInside" />
              <span className="geekmark"></span>
            </label>

            <div className="radioColumn mt-12">
              <div className="radioDiv">
                <p className="insideText">
                ₹29/
                {!languageData?<>
                  Month
                </>:languageData.monthly}
              </p>
              
              </div>
            
              <div className="rupee">
                <p>
                {!languageData?<>
                  Billed Monthly
                </>:languageData.Billed_monthly}&nbsp;
                @ ₹29
                </p>
              </div>
            </div>
            
          </div>
          <div className="textFont">
            <p className="textColor textColor2">
              { !languageData?<>Cancel subscription anytime.</> : languageData?.Cancel_subscription_anytime}
            </p>
            <p className="textColor">
              {!languageData?<>By joining you agree to our</>:languageData?.By_joining_you_agree_to_our}
              <span onClick={()=>{tc()}} className="spanInside">
              {!languageData?<span>&nbsp;Terms and Conditions&nbsp;</span>:<span>&nbsp;{languageData?.term_of_use}&nbsp;</span>}
              </span>
            </p>
          </div>
        </div>
        <div className="button-wrapper text-center mt-90">
          <button onClick={selectSubscription} className="btn mb-10">{!languageData?<>Subscribe</>:languageData?.subscribe}</button>
        </div>
        <div>
        </div>
      </div>
    </div>
  );
}

export default SelectSubscription;
