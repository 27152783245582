import Header from '../Common/Header';
import { Loader } from '../Common/Loader';
import ToastMsg from "../Common/ToastMsg";
import { phoneVerify, emailOtpVerify, emailVerify } from '../services/ApiServices';
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { eventOrg } from '../services/InfluxEvents';
mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
  debug: true,
});

const EmailOtpVerify = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const temp = urlParams.get("phone_number");
    const temp2 = urlParams.get("email");
    setPhone_number(temp);
    setEmail(temp2);
  }, []);
  useEffect(() => {
    mixpanel.track("Viewed_Enter_OTP_Verify", {status:true})
    eventOrg("Viewed", "Viewed_Enter_OTP_Verify", "TRUE")
  }, []);
  const [phone_number, setPhone_number] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [counter, setCounter] = useState(59);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const handleEmailVerification = (e) => {
    e.preventDefault();
    setCounter(59);
    let reqBody = {
      email: email,
      phone_number: phone_number,
    };
    setLoader(true);
    emailVerify(reqBody)
      .then((res) => {
        setLoader(false);
      })
      .catch((err) => {
        let error = err?.response?.data?.error;
        /*istanbul ignore next*/
        ToastMsg(error, "error");
        /*istanbul ignore next*/
        if (err.response.status === 401) {
          navigate("/");
        }
        setLoader(false);
      });
  };

  const handleEmailOtpVerification = (e) => {
    e.preventDefault()
    mixpanel.track("Interacted_Enter_OTP_Verify", {status:true})
    eventOrg("Interacted", "Interacted_Enter_OTP_Verify", "TRUE")
    let reqBody = {
      email: email,
      phone_number: phone_number,
      otp: otp,
    };
    emailOtpVerify(reqBody)
      .then((res) => {
        console.log(res, "emailotpverify")
        localStorage.setItem("email", email);
        // navigate("/dashboard");
        const url = `/dashboard?token=${localStorage.getItem("accessToken")}`
        localStorage.getItem("accessToken");
        window.location.href=url;
        // console.log(url, "final url");
      })
      .catch((err) => {
        let error = err?.response?.data?.error;
        ToastMsg(error, "error");
        if (err.response.status === 401) {
          navigate("/");
        }
        setLoader(false);
      });
  };

  return (
    <>
      {loader ? <Loader /> : null}
      <div className='dashboard new-user'>
        <div className='container container-sm'>
          <Header/>
        </div>
      </div>
      <div className="main-sc">
        <div className="container_wrapper otpverify text-center">
          <h3 className='mb-10'>Enter OTP</h3>
          <p className='mb-30'>
            {`Send to XXXXXX${email?.slice(6, 30)}`}

            <a href="/email-verify">
              {" "}
              <span> Edit</span>
            </a>
          </p>
          <p></p>
          <form>
            <div className="input-wrapper">
              <OtpInput
                containerStyle="otp-input"
                value={otp}
                onChange={(value) => {
                  if (value.match(/^[0-9\b]+$/)) {
                    setOtp(value);
                  }
                }}
                numInputs={4}
                renderInput={(props) => <input {...props} />}
                data-testid="inputtest"
              />
            </div>
            <div className="timer">
              <p>
                <strong>Didn&apos;t receive OTP?</strong>
              </p>
              <p
                onClick={
                  counter === 0
                    ? (e) => {
                      handleEmailVerification(e);
                      }
                    : null
                }
                className={counter === 0 ? "text_click" : ""}
                disabled={!(counter === 0)}
              >
                Resend {counter > 0 ? `in ${counter}s` : ``}
              </p>
            </div>
            <button
              className="btn"
              onClick={handleEmailOtpVerification}
              disabled={!(otp.length === 4)}
            >
              Verify
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default EmailOtpVerify;
