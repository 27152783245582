import React, { useContext } from 'react';
import expert from "../assets/expert.png";
import check from "../assets/check.png";
import { MyContext } from '../MyContext';

const Expert = ({agent_name}) => {
    const {languageData} = useContext(MyContext)
  return (
    <div className='assistant expert flex align-start space-between'>
            <div className='assistant-lft'>
                <img src={expert} alt='assistant' width={115} height={115} />
            </div>
            <div className='assistant-rgt'>
                <span>{languageData?.credit_expert|| "Credit Expert "}<b>{agent_name}</b> {languageData?.is_assigned_to_you|| "is assigned to you. "} {languageData?.He_will_be_in_touch_within_next_24_hours  || "He will be in touch within next 24 hours."}</span>
                <h4>{languageData?.Points_to_be_covered|| "Points to be covered"}</h4>
                <ul>
                    <li><img src={check} alt='check' width={16} height={16} />{languageData?.Cibil_report_Explanation|| "CIBIL Report Explanation."}</li>
                    <li><img src={check} alt='check' width={16} height={16} />{languageData?.Guidance_to_improve_CIBIL_Score || "Guidance to Improve CIBIL Score" }</li>
                    <li><img src={check} alt='check' width={16} height={16} />{languageData?.Resolving_Credit_Issues|| "Resolving Credit Issues"}</li>
                </ul>
            </div>
        </div>
  )
}

export default Expert