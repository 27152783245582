import Header from '../Common/Header';
import { Loader } from '../Common/Loader';
import ToastMsg from "../Common/ToastMsg";
import { phoneVerify, otpVerify } from '../services/ApiServices';
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from 'react-router-dom';
import { eventOrg } from '../services/InfluxEvents'
import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
  debug: true,
});

const OtpVerify = () => {
  useEffect(() => {
    mixpanel.track("Viewed_Enter_OTP", {status:true})
    eventOrg("Viewed", "Viewed_Enter_OTP", "TRUE")
  }, []);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const temp = urlParams.get("phone_number");
    setPhone_number(temp);
  }, []);
  const [phone_number, setPhone_number] = useState("");
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [counter, setCounter] = useState(59);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    mixpanel.track("Interacted_Enter_OTP", {status:true})
    eventOrg("Interacted", "Interacted_Enter_OTP", "TRUE")
    let reqBody = {
      phone_number: phone_number,
      otp: Number(otp),
    };
    setLoader(true);
    otpVerify(reqBody)
      .then((res) => {
        setLoader(false);
        localStorage.setItem("is_new_user", res?.phone_number_verified);
        if (res?.phone_number_verified) {
          const url = `/dashboard?token=${res?.data?.token}`
          localStorage.setItem("accessToken", res?.data?.token);
          window.location.href=url;
        }
        else{
          navigate("/email-verify");
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err.response?.status === 401) {
          window.location.href = "/";
          localStorage.clear();
        }
        let error = err?.response?.data?.error || "something went wrong";
        ToastMsg(error, "error");
      });
  };
  const handleResendOtp = (e) => {
    setOtp("");
    setCounter(59);
    e.preventDefault();
    setLoader(true);
    phoneVerify({ phone_number })
      .then((res) => {
        setLoader(false);
        ToastMsg(res?.message, "success");
        localStorage.setItem("is_new_user", res?.phone_number_verified);
      })
      .catch((err) => {
        setLoader(false);
        if (err.response?.status === 401) {
          window.location.href = "/";
          localStorage.clear();
        }
        let error = err?.response?.data?.error || "something went wrong";
        ToastMsg(error, "error");
      });
  };

  return (
    <>
      {loader ? <Loader /> : null}
      <div className='dashboard new-user'>
        <div className='container container-sm'>
          <Header/>
        </div>
      </div>
      <div className="main-sc">
        <div className="container_wrapper otpverify text-center">
          <h3 className='mb-10'>Enter OTP</h3>
          <p className='mb-30'>
            {`Send to XXXXXX${phone_number?.slice(6, 10)}`}

            <a href="/">
              {" "}
              <span> Edit</span>
            </a>
          </p>
          <form>
            <div className="input-wrapper">
              <OtpInput
                containerStyle="otp-input"
                value={otp}
                onChange={(value) => {
                  if (value.match(/^[0-9\b]+$/)) {
                    setOtp(value);
                  }
                }}
                numInputs={4}
                renderInput={(props) => <input {...props} />}
                data-testid="inputtest"
              />
            </div>
            <div className="timer">
              <p>
                <strong>Didn&apos;t receive OTP?</strong>
              </p>
              <p
                onClick={
                  counter === 0
                    ? (e) => {
                        handleResendOtp(e);
                      }
                    : null
                }
                className={counter === 0 ? "text_click" : ""}
                disabled={!(counter === 0)}
              >
                Resend {counter > 0 ? `in ${counter}s` : ``}
              </p>
            </div>
            <button
              className="btn"
              onClick={handleVerifyOtp}
              disabled={!(otp.length === 4)}
              data-testid="btn"
            >
              Verify
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default OtpVerify;
