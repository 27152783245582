import React, {useContext, useState, useEffect} from 'react';
import { MyContext } from '../MyContext';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import scoreImg from "../assets/score-pop.png";
import meterFrame from "../assets/meter-frame.png";
import scoreArrow from "../assets/score-arrow.png";
import scoreFrame from "../assets/score-frame.png";
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import DownloadCibil from './DownloadCibil';
mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
    debug: true,
});

const PremiumScore = ({score,last_update, sentence}) => {
    const {languageData} = useContext(MyContext)
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    const [openFrom, setOpenFrom] = useState();
    const handleFeedback = () => {
        setOpen(o => !o)
        mixpanel.track("Interacted_Cibil_Info", { "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
    }  
    useEffect(() => {
        const OpenFrom = new URLSearchParams(window.location.search).get(
          "open_from"
        );
        setOpenFrom(OpenFrom);
      }, [openFrom]);
  return (
    <>
        <div className='premium-sc mb-80'>
            <h3 className='mb-25 text-center'>{languageData?.Your_Cibil_Score || "Your Cibil Score"}</h3>
            <div className='flex align-center space-between'>
                <div className='score_sc'>
                    <h3>{languageData?.Your_Cibil_Score || "Your Cibil Score"}</h3>
                    <h2>{score}</h2>
                    <div className='button-wrapper flex align-center'>
                        <button className={sentence==="excellent"?"excellent score-btn":sentence==="good"?"good score-btn":sentence==="fair"?"fair score-btn":sentence==="very poor"?"poor score-btn":"blocked score-btn"}> {sentence === "excellent"
                ? (languageData?.excellent || "Excellent")
                : sentence === "good"
                  ? (languageData?.good || "Good")
                  : sentence === "fair"
                  ? (languageData?.fair || "Good"):sentence === "poor"
                  ? (languageData?.poor || "Poor"): (languageData?.very_poor || "Very Poor")}{" "}
                  </button>
                        <img src={scoreImg} alt='score' width={19} height={19} onClick={() => handleFeedback()}/>
                    </div>
                    <p className='last_update'>{languageData?.Last_Updated||"Last update "} {" : "}{last_update? moment(last_update).format("DD-MM-YYYY"): "DD-MM-YYYY"}</p>
                </div>
                <div className='right_score_meter'>
                    <div className='score_meter'>
                        <img src={meterFrame} alt='meter' width={161} height={88} />
                        <img src={scoreArrow} alt='meter' width={48} height={48} className={sentence==="excellent"?"excellent":sentence==="good"?"good":sentence==="fair"?"fair":sentence==="very poor"?"poor":"blocked"}/>
                    </div>
                    <DownloadCibil/>
                </div>
                <img src={scoreFrame} alt='scoreFrame' width={333} height={78} className='mt-40 score_frame'/>
            </div>
            {openFrom === "Dashboard" ? 
                null
            :
            <>
                {/* {Number(score) < 680 ? 
                    <> */}
                        {/* <p className='loan-status mt-20'>{languageData?.Loan_Status|| "Loan status"}: <b className='red'>{languageData?.Rejected||"Rejected"}</b> </p> */}
                        {/* <div className='flex align-center'>
                            <p className='reason-for-rejection mt-10'>{languageData?.Rejection_Reason||"Reason for Rejection"}: <b className='red'>{languageData?.low_cibil_score||"Low Cibil Score"}</b></p>
                        </div>
                    </>
                    :
                    <>
                        <p className='loan-status mt-20'>{languageData?.Loan_Status|| "Loan status"}: <b className='red'>{languageData?.Rejected||"Rejected"}</b> </p>
                        <div className='flex align-center'>
                            <p className='reason-for-rejection mt-10'>{languageData?.Rejection_Reason||"Reason for Rejection"}: <b className='red'>{languageData?.Cibil_Derogatory||"CIBIL Derogatory"}</b></p>
                            <div className="tooltip mt-10">
                                <img src={scorePic} alt='info' width={16} height={16} />
                                <span className='tooltiptext'>{languageData?.A_derogatory_mark_in_credit_reports||"“A derogatory mark in credit reports is a negative item, such as a late payment or foreclosure. it can hurt your credit scores and may affect your chances of qualifying for credit cards and loans.”"}</span>
                            </div>
                        </div>
                    </>
                } */}
            </>
            }
            
        </div>
        <Popup open={open} closeOnDocumentClick onClose={closeModal} className='feedback-popup-main'>
            <div className='feedback-popup'>
                <h3>{languageData?.Good_Credit_Score||"Good Credit Score"}</h3>
                <p className='mb-20 mt-10'>{ languageData?.A_Score_above_700_will_help_you||"A score above 700 will help you get the best offers on loans and credit cards."}</p>
                <p className='mb-20'>{languageData?.Credit_Score_Range|| "Credit Score Range"}</p>
                <img src={scoreFrame} alt='scoreFrame' width={333} height={78} className='mb-50'/>
                <div className='button-wrapper text-center'>
                    <button className='btn btn-white' onClick={closeModal} style={{maxWidth:"160px"}}>{languageData?.Okay||"Okay"}</button>
                </div>
            </div>
        </Popup>
    </>
  )
}

export default PremiumScore