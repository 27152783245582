import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Loader } from "../Common/Loader";
import ToastMsg from "../Common/ToastMsg";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
// import OtpPopup from "../components/popup/OtpPopup";
import { emailVerify, registerUser } from "../services/ApiServices";
import { eventOrg } from '../services/InfluxEvents';
import Header from "../Common/Header";
import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
  debug: true,
});

const EmailVerify = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [phone_number, setPhone_number] = useState();
  const [isDisabled, setIsdisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const [counter, setCounter] = useState(59);

  useEffect(() => {
    mixpanel.track("Viewed_Enter_Email_ID", {status:true})
    eventOrg("Viewed", "Viewed_Enter_Email_ID", "TRUE")
  }, []);

  const handleOnSuccess = (response) => {
    mixpanel.track("Interacted_Google_Auth", {status:true})
    eventOrg("Interacted", "Interacted_Google_Auth", "TRUE")
    let data = {
      type: "google",
      access_token: response.credential,
      phone_number: phone_number,
    };
    setLoader(true);
    registerUser(data)
      .then((res) => {
        console.log(res, "registerUser");
        })
      .catch((err) => {
        let error = err?.response?.data?.error;
        ToastMsg(error, "error");
        if (err?.response?.status === 401) {
          navigate("/");
        }
        setLoader(false);
      });
      
  };

  const handleEmailVerification = (e) => {
    e.preventDefault();
    mixpanel.track("Interacted_Enter_Email_ID", {status:true})
    eventOrg("Interacted", "Interacted_Enter_Email_ID", "TRUE")
    setCounter(59);
    let reqBody = {
      email: email,
      phone_number: phone_number,
    };
    setLoader(true);
    emailVerify(reqBody)
      .then((res) => {
        setLoader(false);
        navigate(`/email-otp?email=${email}&phone_number=${phone_number}`);
      })
      .catch((err) => {
        let error = err?.response?.data?.error;
        ToastMsg(error, "error");
        if (err.response.status === 401) {
          navigate("/");
        }
        setLoader(false);
      });
  };
  const handleOnError = (err) => {
    console.log(err);
  };
  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      navigate("/");
    }
    setPhone_number(Number(localStorage.getItem("phone_number")));
  }, [navigate]);

  return (
    <>
      {loader ? <Loader /> : null}
      <div className='dashboard new-user'>
        <div className='container container-sm'>
          <Header/>
        </div>
      </div>
      <div className="main-sc">
        <div className="container_wrapper phoneverify emailverify text-center">
          <h3>
            How do we<span> mail you?</span>
          </h3>
          <form>
            <div className="input-wrapper">
              <input
                className="input"
                type="text"
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (e.target.value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{3}$/)) {
                    setIsdisabled(false);
                  } else {
                    setIsdisabled(true);
                  }
                }}
                placeholder=" "
                data-testid="type_email"
              />
              <label className="label">Enter your email address</label>
            </div>
            {/* <input type="submit" className="btn" value="Get OTP"/> */}
            <button
            data-testid="continueverify"
              onClick={handleEmailVerification}
              disabled={isDisabled}
              className="btn"
            >
              Get OTP
            </button>
          </form>
          <div className="email-or">
            <p>
              <span>OR</span>
            </p>
            <br />
            <GoogleOAuthProvider clientId="435990090197-cjdhhppfhvq8e9n0cullbtco1u22mf1g.apps.googleusercontent.com">
              <GoogleLogin
                onSuccess={handleOnSuccess}
                onError={handleOnError}
              />
            </GoogleOAuthProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerify;
