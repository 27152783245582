const {InfluxDB, Point} = require('@influxdata/influxdb-client')

const token ="-eZYOdJ4RGYMz2b0AEkpr-O99dCTTbqAxgGSXuKupI4LLWz4xz79s9DJPHBjLoQxUvsGhKUFjAnMuEbaBpZ2iw=="
const url = "https://argus.paymeindia.in"

export const eventOrg = async(event_name,event_type,argument) => {
    try {
       
    const client = new InfluxDB({url, token})
    let writeClient = client.getWriteApi("PayMe", "PayMe", 'ns')
    let point = new Point('PAYME_WEBSITE').tag(event_name,event_type).intField(argument,1)
   
    writeClient.writePoint(point, (error, response) => {
        if (error) {
            console.log("Error writing point:", error);
        } else {
            console.log("response write",response)
            writeClient.flush((flushError, flushResponse) => {
                if (flushError) {
                    console.log("Error flushing:", flushError);
                } else {
                    console.log("Data written successfully.");
                }
            });
        }
    });
      
    }
    catch(err){
        console.log("error in event",err)
    }
    
};