export const languageJson = {
    Type_your_Reason_Here:"अपना कारण यहां लिखें",
  
    Price:"कीमत",
    Cancel_Subscription:"सदस्यता रद्द",
    Other:"अन्य",
    The_price_is_too_high:"कीमत बहुत ज्यादा है.",
    The_suggestions_doesn_t_help:"सुझाव मदद नहीं करते.",
    This_report_is_inaccurate:"यह रिपोर्ट ग़लत है.",
    I_no_longer_want_to_receive_a_credit_report:"मैं अब क्रेडिट रिपोर्ट प्राप्त नहीं करना चाहता.",
    Select_Reason_for_cancelling_Subscription:"सदस्यता रद्द करने का कारण चुनें",
    Renew_Subscription:"सदस्यता नवीनीकरण",
    click_on:"पर क्लिक करेंगे तो हम आपके खाते के लिए आवर्ती भुगतान रोक देंगे",
    cancel:"रद्द करें",
    Your_subscription_will_not_be_auto:"आपकी सदस्यता स्वतः नवीनीकृत नहीं होगी, और जब आप",
    Your_Subscription_Plan:"आपकी सदस्यता योजना",
    Next_Billing_Date:"अगली बिलिंग तिथि",
    hi:"नमस्ते",
    We_are_unable_to_offer_loan_to_you_due_to_low_Credit_score:"हम आपको कम क्रेडिट स्कोर की वजह से! ऋण प्रदान करने में असमर्थ हैं |",
    how_to_get_instant_loan: 'तुरंत लोन कैसे प्राप्त करें',
    how_to_get_higher_loan_limit: 'उच्च ऋण सीमा कैसे प्राप्त करें',
    your_cibil_score: 'आपका सिबिल स्कोर है',
    very_poor: 'बहुत ही खराब',
    poor: 'खराब',
    fair: 'उचित',
    Fair: 'उचित',
    good: "अच्छा",
    excellent: "उत्कृष्ट",
    For_just:"मात्र",
    off:"की छूट",
    par:"पर",
    For_just_Rs_49_99_50_off: "मात्र @49 पर 99 50% की छूट",
    tailored_suggestion: "अनुरूप सुझाव",
    Starting_from_just:"सिर्फ 17 से शुरुआत",
    Received_tailored_suggestions: "विशिष्ट सुझाव प्राप्त करने के लिए",
    Personalized_Credit_Report: 'व्यक्तिगत क्रेडिट रिपोर्ट के लिए',
    Get_loan_within_60_days: "60 दिन के अंदर लोन प्राप्त करने के लिए",
    Improving_your_credit_with_personalized_advice_can_make_you_loan_eligible_in_60_days: 'व्यक्तिगत सलाह के साथ अपना क्रेडिट सुधारने से आप 60 दिनों में ऋण-पात्र बन सकते हैं।',
    By_clicking_this_I_agree_to_the_Term_Conditions: "इसे क्लिक करके, मैं नियम और शर्तों से सहमत हूँ।",
    Pay_49: "का भुगतान करें",
    Pay_499: "₹499 का भुगतान करें",
    Know_More: "अधिक जानकारी के लिए",
    You_need_to_pay_for_suggestions_1: "सुझावों को अनलॉक करने के लिए आपको 59 ",
    You_need_to_pay_for_suggestions_2:'रुपये का भुगतान करना होगा',
    Payments_Successful:"धन्यवाद, आपका भुगतान सफल हो गया है।",
    Your_details_credit_report_has_been_emailed_to_you:'आपकी क्रेडिट रिपोर्ट आपके ईमेल पर भेज दी गई है!”',
    Download_Credit_Report:"रिपोर्ट डाउनलोड करें",
    View_Suggestions:"सुझाव देखें",
    procees_to_pay:'भुगतान करें',
    payments_failure:"भुगतान विफल हुआ !",
    Your_detailed_credit_report:"आपकी क्रेडिट रिपोर्ट",
    has_been_emailed_to_you:"आपके ईमेल पर भेज दी गई है!",
    update:"अपडेट करें",
    was_this_information_helpful:"क्या इस जानकारी से सहायता मिली",
    Customer_Feedback:"ग्राहक प्रतिक्रिया",
    Submit:"दर्ज करें",
    Done:"समाप्त",
    thank_you:"धन्यवाद",
    your_feedback_is_much_appreciated:"आपकी प्रतिक्रिया बहुत सराहनीय है",
    Type_your_comment_here:"कृपया आपका टिप्पणी विस्तार से दें",
    Retry:"पुनः प्रयास करें",
    Your_payment_has_been_failed:"आपका भुगतान विफल हो गया है !",
    Last_Updated:"नवीनतम अपडेट",
    Total_Credit_Limit:"कुल क्रेडिट की सीमा",
    Total_Loan_Accounts:"कुल ऋण खाते",
    Loan_Amount:"ऋण राशि",
    Total_Overdue :'कुल बकाया',
    Total_Credit_Used:'कुल उपयोग किया गया क्रेडिट',
    Credit_Used:'उपयोग किया गया क्रेडिट',
    Utilization:"उपयोग",
    Impact:'प्रभाव',
    Low:'कम',
    High:"उच्च",
    Medium:'मध्यम',
    Credit_Card_Utilizations:"क्रेडिट कार्ड उपयोग दर",
    Status:"स्थिति",
    Subscription:"सदस्यता योजना",
    Manage_Subscription:"सदस्यता प्रबंधित करें",
    Complete_Autopay_Mandate:"Autopay Mandate पूरा करें।",
    Next_Payment_Date:"अगली भुगतान तिथि",
    Frequency:"अवधि",
    Payment_Amount:"भुगतान राशि",
    Annual_Plan_Active:"वार्षिक योजना सक्रिय",
    Autopay_Mandate_Completed_Successfully:"Autopay Mandate सफलतापूर्वक पूरा हुआ",
    Autopay_Mandate_Failed:"Autopay mandate विफल रहा",
    Please_try_again_with_different_UPI_ID:"कृपया भिन्न UPI ​​आईडी के साथ पुनः प्रयास करें",
    Paytm_Payments_Bank_is_not_supported:"पेटीएम पेमेंट्स बैंक समर्थित नहीं है!",
    Do_not_cancel_refresh_or_go_back_until_process_is_completed:"पूर्ण प्रक्रिया को संपन्न करने तक कृपया रद्द न करें, रीफ्रेश न करें और पिछले पेज पर न जाएं",
    Return_to_this_page_to_complete_process:"इस पृष्ठ पर वापस आकर प्रक्रिया को पूरा करें।",
    Come_back_to_Payme_App:"Payme ऐप पर वापस आएं",
    If_you_don_t_see_it_complete_from_Autopay_Pending_section:"Payme india से Autopay को स्स्वीकृति दें।",
    Approve_the_Autopay_from_Payme_India:"Payme India से Autopay को स्वीकृति दें",
    Autopay_request_should_appear:"Autopay अनुरोध दिखना चाहिए",
    Open_your_UPI_app:"अपना UPI ऐप खोलें।",
    Cancel_subscription_anytime:"किसी भी समय सदस्यता रद्द करें.",
    Access_to_the_Credit_Simulator:"क्रेडिट सिम्युलेटर तक पहुंच",
    Free_access_to_the_Credit_Expert:"क्रेडिट विशेषज्ञ का मुफ्त उपयोग",
    Get_Higher_Loan_limit_within_60_Days:"60 दिनों के भीतर अधिक ऋण सीमा प्राप्त करें",
    Personalized_Credit_report  :"व्यक्तिगत क्रेडिट रिपोर्ट",
    Tailored_suggestions:"विशेष रूप से अनुकूलित सुझाव",
    with:"",
    you_will_get:"के साथ आपको मिलेगा",
    Credit_Assist_Subscription :"क्रेडिट असिस्ट सब्सक्रिप्शन",
    and:"और",
    ha:"से सहमत हैं",
    terms_of_use:"उपयोग की शर्तों",
    privacy_policy:"गोपनीयता नीति",
    By_joining:"शामिल होकर आप हमारी",
    plan_part:`आपके क्रेडिट सहायता सदस्यता योजना के लिए ${localStorage.getItem("subscription") === "MONTHLY"?"मासिक":"वार्षिक"} रूप में ${localStorage.getItem("subscription") === "MONTHLY"?"29":"149"} रुपये की राशि कटौती की जाएगी।`,
    Cancel_anytime_Subscription_auto_renews:"किसी भी समय रद्द करें. सदस्यता स्वतः नवीनीकरण.",
    Effectively:"प्रभावी रूप से",
    month:"महीना",
    With_credit_assist_subscription_you_will_get:"क्रेडिट सहायता से आपको मिलेगा",
    cancel_subscription_anytime:"किसी भी समय सदस्यता रद्द करें",
    subscribe:"सदस्यता लें",
    Cancel_anytime_Subscription_auto_renews_By_joining_you_agree_to_our_privacy_policy_and_terms_of_use:"किसी भी समय रद्द करें. शामिल होने पर सदस्यता स्वतः नवीनीकृत हो जाती है, आप हमारी गोपनीयता नीति और उपयोग की शर्तों से सहमत होते हैं",
    Select_Subscription_Plan:"सदस्यता योजना चुनें",
    how_it_works:"यह कैसे काम करता है?",
    amount_of_pay_debit:"रुपये की राशि कटौती की जाएगी।",
    amount_of_upi_pay:"आपके क्रेडिट असिस्ट सब्सक्रिप्शन प्लान के लिए सालाना",
    do_not_cancel:"पूर्ण प्रक्रिया को संपन्न करने तक कृपया रद्द न करें, रीफ्रेश न करें और पिछले पेज पर न जाएं।",
    Enter_your_UPI_ID_to_setup_autopay:"autopay करने के लिए UPI आईडी दर्ज करें",
    Approve_the_auto_debit_request_sent_by_your_UPI_handle_and_enter_UPI_PIN_to_setup:"अपने UPI हैंडल द्वारा भेजी गई auto debit अनुरोध को स्वीकृति दें और सेटअप करने के लिए UPI पिन दर्ज करें।",
    return_to_this_age:"Return to this page to complete process.",
    come_back_payme_App:"Payme ऐप पर वापस आएं",
    autopay_pending:"यदि आप इसे नहीं देखते हैं, तो ऑटोपे-पेंडिंग अनुभाग से पूरा करें",
    approve_autopay:"पे मी इंडिया से ऑटोपे को स्वीकृति दें।",
    autopay_request:"Autopay अनुरोध दिखना चाहिए।",
    open_autopay:"अपना यूपीआई ऐप खोलें",
    autopay_method:"autopay के लिए पसंदीदा तरीका चुनें",
    setup_autopay:"ऑटोपे सेटअप करने के लिए अपनी यूपीआई आईडी दर्ज करें।",
    setup_upi_handle:"अपने UPI हैंडल द्वारा भेजे गए ऑटो डेबिट अनुरोध को स्वीकार करें और सेटअप करने के लिए UPI पिन दर्ज करें।",
credit_simulator:"क्रेडिट सिम्युलेटर तक पहुंच",
  tailored_suggestionl: "अनुरूप सुझाव",
  Yearly:"वार्षिक",
  monthly: "मासिक",
  effectivly: "Effectively",
  cancel_anytime: "Cancel anytime. Subscription auto-renews.",
  agree_with:
    "शामिल होकर आप हमारी गोपनीयता नीति और उपयोग की शर्तों से सहमत हैं",
    personalised_credit:"व्यक्तिगत क्रेडिट रिपोर्ट",
  credit_assist_get: "क्रेडिट असिस्ट सब्सक्रिप्शन के साथ, आपको मिलेगा",
  get_higher_limit:"60 दिनों के भीतर उच्च ऋण सीमा प्राप्त करें",
  free_access:'क्रेडिट विशेषज्ञ तक निःशुल्क पहुंच',
  subscription:"सदस्यता लें",
  yearly: "वार्षिक",
  subscription: "अंशदान",
  subscription_plan: "सदस्यता योजना",
  language: "भाषाएँ",
  hindi:"हिंदी",
  subscription: "सदस्यता",
  download_report: "रिपोर्ट डाउनलोड करें",
  logout: "लॉग आउट",
  credit_report_summary: "क्रेडिट रिपोर्ट सारांश",
  Loan_Status: "लोन स्थिति",
  Active: "सक्रिय",
  Closed: "निष्क्रिय",
  Total_Used: "कुल उपयोग",
  Total_Enquiries: "कुल पूछताछें",
  Enquires: "पूछताछें",
  Total_Acitve_Account: "कुल सक्रिय खाते",
  Closed_Account: "बंद किए गए खाते",
  Last_30_days: "पिछले 30 दिन में",
  Last_12_Months: "पिछले 6 महीने में",
  Last_12_Months_Enquiries: "पिछले 6 महीने में पूछताछें",
  Total_Accounts: "कुल खाते",
  List_of_Accounts: "खातों की सूची",
  Total_Late_payments: "देर से किया गए भुगतान",
  payble_amount: "देय राशि",
  Overdue_Payments: "लंबे समय से बकाया राशि",
  Age_of_Accounts: "खातों की आयु",
  Overdue_Details: "देर से भुगतान का विवरण",
  On_Time_Payment: "समय पर भुगतान",
  Accounts: "खाते",
  Active_Account: "सक्रिय खाते",
  Credit_Factor: "क्रेडिट फैक्टर",
  Total_Enquires: "कुल पूछताछें",

  A_Score_above_700_will_help_you:
    "700 के ऊपर का स्कोर आपको ऋण और क्रेडिट कार्ड पर सर्वोत्तम ऑफर प्राप्त करने में मदद करेगा।",
  Good_Credit_Score: "अच्छा क्रेडिट स्कोर",
  Credit_Score_Range: "क्रेडिट स्कोर रेंज",
  Okay: "ठीक है",
  Cibil_Derogatory: "सिबिल अनुपयुक्त",
  Rejected: "अस्वीकृत",
  Rejection_Reason: "लोन अस्वीकृति के कारण",
  A_derogatory_mark_in_credit_reports:
    "सांख्यिक मूल्य और चित्रण आपकी पूर्व क्रेडिट रिपोर्ट के साथ की गई तुलना से प्राप्त किए गए हैं।",
  Your_Credit_Remain_unchanged: "आपका क्रेडिट अपरिवर्तित रहा है",
  A_derogatory_mark_in_credit_reports:
    "“ ऋण रिपोर्ट में एक अपमानजनक चिह्न एक नकारात्मक आइटम है, जैसे कि विलंबित भुगतान या अवलंबन। यह आपके क्रेडिट स्कोर को कमजोर कर सकता है और क्रेडिट कार्ड्स और ऋण के लिए पात्र होने की संभावनाओं पर प्रभाव डाल सकता है । ”",

  You_can_refresh_your_score_after_30_days:
    "आप अपना स्कोर 30 दिनों के बाद रिफ्रेश कर सकते हैं।",
  Get_Latest_Report: "नवीनतम रिपोर्ट प्राप्त करें",
  Compare_with_Previous_Reports: "पिछली रिपोर्ट के साथ तुलना करें",
  Timely_Payment: "समय से भुगतान किया गया",
  Due_on: "भुगतान तिथि",
  Loan_Not_Applicable: "ऋण उपलब्ध नहीं है",
  Issue_Date: "जारी तिथि",
  Limit_Used: "उपयोग की गई सीमा",
  Outstanding: "बकाया",
  Tenure: "अवधि",
  Date: "तिथि",
  Credit_Card: "क्रेडिट कार्ड",
  The_numerical_values_and:
    "सांख्यिक मूल्य और चित्रण आपकी पूर्व क्रेडिट रिपोर्ट के साथ की गई तुलना से प्राप्त किए गए हैं।",
  Active_Accounts: "सक्रिय खाते",
  compare_report: "रिपोर्ट के साथ तुलना करें",
  credit_insigts: "क्रेडिट सूचना",
  Your_Cibil_Score: "आपका सिबिल स्कोर है",
  low_cibil_score: "कम सिबिल स्कोर",
  analysis_of_credit_report: "क्रेडिट रिपोर्ट का विश्लेषण",
  credit_issue_resolution: "क्रेडिट समस्या समाधान",
  latest_credit_reports: "12 नवीनतम क्रेडिट रिपोर्ट",
  subscription_based_plan: "सदस्यता आधारित योजना",
  Feature: "प्रमुख विशेषता",
  Basic: "बेसिक",
  Premium: "प्रीमियम",
  Premium_plan: "प्रीमियम प्लान",
  Basic_plan: "बेसिक प्लान",
  select_your_plan: "अपना प्लान चुने",
  Continue_To_Pay: "भुगतान करे",
  Access_to_Personal_Credit_Expert: "व्यक्तिगत क्रेडिट विशेषज्ञ तक पहुंच",
  Credit_Score_Improvement: "क्रेडिट स्कोर में सुधार",
  Credit_Issue_Resolution: "क्रेडिट समस्या समाधान",
  Latest_Credit_Reports: "3 नवीनतम क्रेडिट रिपोर्ट",
  Need_More_Assistance: "क्या आपको और मदद चाहिए",
  Get_in_touch_with_our1: "अब हमारे क्रेडिट विशेषज्ञ से",
  Get_in_touch_with_our2: "संपर्क करें",
  credit_expert: "क्रेडिट एक्सपर्ट ",
  is_assigned_to_you: "आपको नियुक्त किया गया है। ",
  Points_to_be_covered: "कवर किये जाने वाले बिंदु",
  Cibil_report_Explanation: "CIBIL रिपोर्ट का विवरण ",
  Guidance_to_improve_CIBIL_Score: "CIBIL स्कोर सुधारने हेतु मार्गदर्शन",
  Resolving_Credit_Issues: "क्रेडिट संबंधी समस्याओं का समाधान",
  He_will_be_in_touch_within_next_24_hours:
    "वह आपके साथ अगले 24 घंटों के भीतर संपर्क करेंगे।",
  For_any_query_write_us_at: "किसी भी प्रश्न के लिए हमें यहां लिखें:",
    Suggestions_to_improve_Credit_score: "क्रेडिट स्कोर सुधारने के सुझाव",
    Make_Loan: " ऋण स्वीकृतियों को ",     
    Approvals: " वास्तविकता ",     
    a_reality: " बनाएं ",
    your: "हमारे ",
    Personalized_Guidance: "वैयक्तिकृत मार्गदर्शन",
    Elevate_your_credit_score_with_our: "से अपना क्रेडिट स्कोर बढ़ाएं।",
    your_credit_score: "आपका क्रेडिट स्कोर",
    Lets_turn_this: "आइए इस ",
    loan_rejection: " ऋण अस्वीकृति ",
    into_an: " को एक ",
    opportunity: " अवसर में बदलें!",
    Elevate_your_credit_score_with: "हमारे वैयक्तिकृत मार्गदर्शन से",
    our_Personalized_Guidance: "अपना क्रेडिट स्कोर बढ़ाएं।",
    Monthly_Plan_Active:"महीने के",
    Term_And_Conditions:"नियम एवं शर्तें",
    proceed:"आगे बढ़ें",
    you_need_to_buy_a_plan:"आपको एक प्लान खरीदना होगा",
    to_unlock_suggestion:"सुझाव अनलॉक करने के लिए",
    Improving_your_credit_with_personalized_advice_can_make_you_eligible_to_get_higher_loan_limit_in_60_days:"व्यक्तिगत सलाह के साथ अपने क्रेडिट में सुधार करने से आप 60 दिनों में उच्च ऋण सीमा प्राप्त करने के पात्र बन सकते हैं।",
    Subscription_Cancelled_Succesfully:"सदस्यता सफलतापूर्वक रद्द कर दी गई",
    upi_method:"UPI का उपयोग करके भुगतान करें",
    Unlock_your_credit_potential_with:"के साथ अपनी क्रेडिट क्षमता को अनलॉक करें",
    Credit_Assist:"क्रेडिट सहायता",
    credit_monitoring_with_instant_alert: "तत्काल अलर्ट के साथ 24/7 क्रेडिट मॉनिटरिंग",
    Monthly_update_of_your_full_credit_reports_and_scores: "आपकी संपूर्ण क्रेडिट रिपोर्ट और स्कोर का मासिक अपडेट।",
    Get_alert_to_spot_issues_before_they_demage_your_score: "इससे पहले कि वे आपके स्कोर को ख़राब करें, समस्याओं का पता लगाने के लिए सतर्क हो जाएँ",
    Subscribe_now_and_unlock_your_credit_potential:"अभी सदस्यता लें और अपनी क्रेडिट क्षमता अनलॉक करें!",
    Billed_Yearly: "वार्षिक शुल्क",
    Billed_monthly: "मासिक शुल्क",
    By_joining_you_agree_to_our:"शामिल होकर आप हमारी",
    term_of_use:"उपयोग की शर्तों से सहमत हैं",
} 
