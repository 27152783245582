import mixpanel from "mixpanel-browser";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Loader } from "../Common/Loader";
import ToastMsg from "../Common/ToastMsg";
import { MyContext } from "../MyContext";
import successImage from "../assets/SubscriptionCancelledSuccess.gif";
import back from "../assets/back.png";
import { getSubs, postCancelSubscription } from "../services/ApiServices";
mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
  debug: true,
});
const ManageSubscription = () => {
  const [otherState, setOtherState] = useState(false)
const {languageData} = useContext(MyContext)
  const [reasonState, setReasonState] = useState(false);
  const [reasonInput, setReasonInput] = useState("I no longer want to receive a credit report.");
  const [dataSubs, setDataSubs] = useState({});
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false)
  const [loader, setLoader] = useState(false)

  const fnCancelSubs = () => {
      mixpanel.track("Interacted_Cancel_Reason", {"Clicked on":"Cancel Subscription",status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
    setLoader(true)
    postCancelSubscription({
      subscription_id: localStorage.getItem("subId"),
      reason: reasonInput,
    })
      .then((res) => {
        setTimeout(()=>{
          setLoader(false)
          setOpen(false)
              setSuccessPopup(true)
             //navigate("/")
        }, 1000)
       
      })
      .catch((err) => {
          setLoader(false)
          ToastMsg("subscription cannot be cancelled please try after some time", "error")
      });
  };
  const closeModal = () => setOpen(false);
  const handleFeedback = () => {
      mixpanel.track("Interacted_Manage_Subscription", {"Clicked on":"Cancel",status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
      mixpanel.track("Viewed_Cancel_Reason", {status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })

    setOpen((o) => !o);
  };
  useEffect(() => {
    getSubs()
      .then((res) => {
        localStorage.setItem('subId', res.message.subscription_id)
        
        setDataSubs(res?.message);
        console.log(res, "ress");
      })
      .catch((err) => {
        console.log("err");
      });

      
  }, []);
      mixpanel.track("Viewed_Manage_Subscription", {status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })

      const renewBtnFn = () =>{
          mixpanel.track("Interacted_Manage_Subscription", {"Clicked on":"Renew Subscription",status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
          navigate('/select-subscription')
      }

      // if(open){
      // }
  //     const data =  {
  //     "id": 1,
  //     "created_at": "2024-02-28T11:03:27.763029",
  //     "modified_at": "2024-02-28T11:04:09.979994",
  //     "merchant_id": "PAYMEUAT",
  //     "merchant_user_id": "5556754",
  //     "merchant_subscription_id": "909840755712700d35",
  //     "subscription_id": "OM2402281103276932974717",
  //     "auth_work_type": "VARIABLE",
  //     "amount_type": "VARIABLE",
  //     "frequency": "ON_DEMAND",
  //     "total_amount": "10000",
  //     "recurring_count": 3,
  //     "mobile_number": "9910230374",
  //     "state": "CREATED",
  //     "code": "SUCCESS",
  //     "state_start_date": null,
  //     "state_end_date": null,
  //     "valid_upto": "2024-03-01T05:33:27.693000",
  //     "is_supported_app": true,
  //     "is_supported_user": true,
  //     "vpa": 1
  // }
  return (
    <>
    {loader && <Loader/> }
      <section className="manage-sc">
        <div className="container container-sm someCenter">
          <h1 className="mb-25 subs-padd">{!languageData  ? <>Manage Subscription</>:languageData?.Manage_Subscription}</h1>
          <div className="plan-sc mb-30">
            <div className="head text-center">{!languageData  ? <>Your Subscription Plan</>:languageData?.Your_Subscription_Plan}</div>
            <div className="content">
              <div className="flex align-center space-between">
                <p> {!languageData  ? <>Subscription</>:languageData?.Subscription}</p>
                <p>{dataSubs?.subscription_type}</p>
              </div>
              <div className="flex align-center space-between">
                <p>{!languageData  ? <>Price</>:languageData?.Price}</p>
                <p>₹{dataSubs?.total_amount}</p>
              </div>
              <div className="flex align-center space-between">
                <p>{!languageData  ? <>Status</>:languageData?.Status}
                    </p>
                <p className={dataSubs?.status === 'COMPLETED'? "green":"" }>{dataSubs.status === 'COMPLETED' ? "Active" : "Expired"}</p>
              </div>
              { dataSubs?.status === 'COMPLETED' &&
                <div className="flex align-center space-between">
                <p> {!languageData  ? <>Next Billing Date</>:languageData?.Next_Billing_Date}
                    </p>
                <p>{dataSubs?.next_transaction_date}</p>
              </div>}
            </div>
          </div>
          <div className="cancle-sc">
            {dataSubs?.status === 'COMPLETED' &&
 <p> {!languageData  ? <>Your subscription will not be auto renewed and we will stop
              recurring payment for your account, When you click on</>:languageData?.Your_subscription_will_not_be_auto} &nbsp;
            
                <span onClick={() => handleFeedback()}>
                {!languageData  ? <>Cancel</>:languageData?.cancel}
                </span>
            
                {!languageData  ? <></>:languageData?.click_on}
            </p>
            }
           
            <div className="button-wrapper text-center mt-20">
              <button disabled={dataSubs?.status === 'COMPLETED'} onClick={()=>{renewBtnFn()}} className="btn">  {!languageData  ? <>&nbsp;Renew Subscription &nbsp;</>:languageData?.Renew_Subscription}</button>
            </div>
          </div>
        </div>
        <img
          src={back}
          alt="cross"
          width={55}
          height={55}
          className="close"
          onClick={() => navigate(-1)}
        />
        <Popup
          open={open}
          closeOnDocumentClick
          onClose={closeModal}
          className="popup-main"
        >
          <div className="unsubscribe-popup">
            <p className="head mb-30">
                {!languageData  ? <> Select Reason for cancelling Subscription</>:languageData?.Select_Reason_for_cancelling_Subscription}
             
            </p>
            <div className="questions">
              <div className="feedback flex align-center">
                <input
                  onClick={(e) => {
                    setReasonInput(e.target.value);
                    setOtherState(false)
                  }}
                  checked={reasonInput === 'I no longer want to receive a credit report.'}
                  type="radio"
                  id="receive"
                  name="fav_language"
                  value="I no longer want to receive a credit report."
                />{" "}
                <span> {!languageData  ? <> I no longer want to receive a credit report</>:languageData?.I_no_longer_want_to_receive_a_credit_report}

                    
                    </span>
              </div>
              <div className="feedback flex align-center">
                <input
                  onClick={(e) => {
                    setReasonInput(e.target.value);
                    setOtherState(false)
                  }}
                  type="radio"
                  id="report"
                  name="fav_language"
                  value="This report is inaccurate."
                />{" "}
                <span>{!languageData  ? <>  This report is inaccurate</>:languageData?.This_report_is_inaccurate}</span>
              </div>
              <div className="feedback flex align-center">
                <input
                  onClick={(e) => {
                    setReasonInput(e.target.value);
                    setOtherState(false)
                  }}
                  type="radio"
                  id="suggestion"
                  name="fav_language"
                  value="The suggestions doesn't help."
                />{" "}
                <span>{!languageData  ? <> The suggestions doesn't help</>:languageData?.The_suggestions_doesn_t_help}
                   </span>
              </div>
              <div className="feedback flex align-center">
                <input
                  onClick={(e) => {
                    setReasonInput(e.target.value);
                    setOtherState(false)
                  }}
                  type="radio"
                  id="price"
                  name="fav_language"
                  value="The price is too high."
                />{" "}
                <span>{!languageData  ? <> The price is too high</>:languageData?.The_price_is_too_high}</span>
              </div>
              <div className="feedback flex align-center">
                <input
                onClick={(e)=>{setOtherState(true)}}
                  type="radio"
                  id="other"
                  name="fav_language"
                  value="Other"
                />{" "}
                <span>{!languageData  ? <>Other</>:languageData?.Other}</span>
              </div>
                {
                  otherState &&  <textarea
                placeholder={!languageData  ? "Type your Reason Here":languageData?.Type_your_Reason_Here}
                type="input"
                className="otherInput"
                onChange={(e) => {
                  setReasonInput(e.target.value);
                }}
              />
                }
             
            </div>
            <div className="button-wrapper text-center">
              <button onClick={fnCancelSubs} className="btn btn-white mt-50 ">{!languageData  ? <>Cancel Subscription</>:languageData?.Cancel_Subscription}
                
              </button>
            </div>
          </div>
        </Popup>

         <Popup open={successPopup} onClose={()=>{navigate('/')}}  className='thankyou'>
            <div className="classFlexDiv">
                 <div className='feedback-popup text-center'>
                   <img src={successImage} height={109} width={137}/>
                </div>
                <div className="subscriptionCancelS">
                   {!languageData  ? <>Subscription Cancelled Successfully</>: languageData?.Subscription_Cancelled_Succesfully}
                </div>
                <button  onClick={()=>{navigate('/')}} className="btn btn-white mt-50">  {!languageData  ? <>&nbsp;okay &nbsp;</>: languageData?.Okay}</button>

                </div>
            </Popup>
      </section>
    </>
  );
};

export default ManageSubscription;
