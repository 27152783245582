import React, { useState, useEffect, useContext } from "react";
import success from "../assets/check.gif";
import failed from "../assets/warning.png";
import {useNavigate} from 'react-router-dom';
import DownloadCibil from "../Common/DownloadCibil";
import mixpanel from 'mixpanel-browser';
import { abTesting, appsFlyer, getCibilString } from "../services/ApiServices";
import { MyContext } from "../MyContext";
import { Loader } from '../Common/Loader';
import ToastMsg from "../Common/ToastMsg";
// import ToastMsg from "../Common/ToastMsg";

mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
  debug: true,
});

const Payment = () => {
  const payment_status = new URLSearchParams(window.location.search).get(
    "status"
  );
  const {languageData} = useContext(MyContext)
  // const [status, setStatus] = useState("");
  const [cibilString, setCibilString] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const navigateToUpiPayment = () => {
    mixpanel.track("Interacted_Payment_Sucess", {
      status:true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType"), "Clicked on":"View Suggestion"
    })
    let data ={
      "event_name": "Interacted_Payment_Sucess",
      "event_body": {}
    }
    appsFlyer(
      data
    )
    navigate(
      '/dashboard',
    )
  };
  const handleGetCibilString = () => {
    getCibilString()
      .then((res) => {
        setCibilString(res)    
        if (payment_status === "success"){
         window.nv("event", "Viewed_Payment_Succesful", { "Status": true, "User_id": res?.user_id, "Balance": res?.balance, "Cibil_score": Number(res?.cibil_score), "name": res?.name, "email": res?.email  }, "100", 1);
        }    
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          // console.log(err,"error")
        }
      });
  };
  useEffect(() => {
    handleAbTesting();
    if (payment_status === "success"){
      mixpanel.track("Viewed_Payment_Succesful", {"Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType")})
      let data ={
        "event_name": "Viewed_Payment_Succesful",
        "event_body": {}
      }
      appsFlyer(
        data
      )
    }
  }, []);

  const handleAbTesting = () => {
    setLoader(true);
    abTesting()
      .then((res) => {
        handleGetCibilString()
      if(res?.status===200){
        localStorage.setItem("newUserPayment",res?.data?.new_user_payment_status)
        setLoader(false);
      }    
      })
      .catch((err) => {
        setLoader(false);
        if (err.response?.status === 412) {
          console.log(err,"error")
        }else{
          let error = err?.response?.data?.error || "something went wrong";
          ToastMsg(error, "error");
        }
      });
  };

  // useEffect(()=>{
  //   handleUserSession()
  // },[])

  // const queryParameters = new URLSearchParams(window.location.search).get("transaction_id")
  
  // const handleUserSession = () => {
  //   let data = {
  //     page : window.location.href.split("?")[0]
  //   };
  //   storeSession(data)
  //     .then((res) => {
  //       console.log(res?.data, "res?.datares?.data");
  //     })
  //     .catch((err) => {
  //       if (err.response?.status === 401) {
  //         // console.log(err,"error")
  //       }
  //       let error = err?.response?.data?.detail || "something went wrong";
  //       ToastMsg(error, "error");
  //     });
  // }
  return (
    <>
      {loader ? <Loader /> : null}
      <section className="payment_status">
        <div className="container container-sm text-center">
          {payment_status === "success" ? (
            <>
            <div className="payment_inner mt-20">
              <img src={success} alt="success" width={231} height={231} />
              <h1>{languageData?.Payments_Successful|| "Payment Successful"}</h1>
              {/* <p>
               {languageData?.Your_detailed_credit_report|| "Your detailed credit report"}
                <br />
               {languageData?.has_been_emailed_to_you ||"has been emailed to you!"}
              </p> */}
              {localStorage.getItem("isNew") === "true" && localStorage.getItem("newUserPayment") === "true" ? null:
              <DownloadCibil />}
              <div className="button-wrapper text-center">
                {localStorage.getItem("isNew") === "true" && localStorage.getItem("newUserPayment") === "true" ? 
                  <a className="btn btn-white mb-30" href="/user-details">
                    {languageData?.View_Suggestions|| "Proceed"}
                  </a>
                  :
                  <button className="btn btn-white mb-30" onClick={navigateToUpiPayment}>
                  {languageData?.View_Suggestions|| "View Suggestions"}
                  </button>
                }
                
              </div>
            </div>  
            </>
          ) : (
            <>
            <div className="payment_inner mt-20">
              <img src={failed} alt="failed" width={161} height={161} />
              <h5>
                {languageData?.Your_payment_has_been_failed||"Your payment has been failed!"}
                {/* <br /> ₹{cibilString?.balance} */}
              </h5>
              {/* <p>transaction_id: {state?.transactionId}<br/></p> */}
              {/* <p>transaction_id: {queryParameters}<br/></p> */}
              <div className="button-wrapper text-center">
                <button className="btn btn-white mb-30" onClick={navigateToUpiPayment}>{languageData?.Retry|| "Retry"}</button>
              </div>
            </div>  
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Payment;
