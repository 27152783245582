import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import './App.css';
import { MyContext } from "./MyContext";
import AgeOfAccounts from "./Pages/AgeOfAccounts";
import AutoPay from "./Pages/AutoPay";
import CibilInformationUnavailable from "./Pages/CibilInformationUnavailable";
import CompareReport from "./Pages/CompareReport";
import CreditCardUtilization from "./Pages/CreditCardUtilization";
import Dashboard from "./Pages/Dashboard";
import EmailOtpVerify from "./Pages/EmailOtpVerify";
import EmailVerify from "./Pages/EmailVerify";
import Feedback from "./Pages/Feedback";
import Login from "./Pages/Login";
import ManageSubscription from "./Pages/ManageSubscription";
import OtpVerify from "./Pages/OtpVerify";
import OverduePayments from "./Pages/OverduePayments";
import Payment from "./Pages/Payment";
import Profile from "./Pages/Profile";
import SelectPlan from "./Pages/SelectPlan";
import SelectSubscription from "./Pages/SelectSubscription";
import Subscription from "./Pages/Subscription";
import SuccessAutoPay from "./Pages/SuccessAutoPay";
import Term from "./Pages/Term";
import TotalAccounts from "./Pages/TotalAccounts";
import TotalEnquiries from "./Pages/TotalEnquiries";
import TotalLoanAccounts from "./Pages/TotalLoanAccounts";
import UserDetails from "./Pages/UserDetails";
import "./Styles/style.scss";
import { languageJson } from "./utils/language";



function App() {
  const accessToken = new URLSearchParams(window.location.search).get('token') 
  const platfrom = new URLSearchParams(window.location.search).get("platform") 
  const userType = new URLSearchParams(window.location.search).get("user_type")
  const openFrom = new URLSearchParams(window.location.search).get("open_from")
   const os = new URLSearchParams(window.location.search).get("os")
  const phonepe_enable = new URLSearchParams(window.location.search).get("phonepe_enable");
  const phonepe_version_code = new URLSearchParams(window.location.search).get("phonepe_version_code")
  // console.log(userType,platfrom,openFrom, "userType");
  if(accessToken){
    localStorage.setItem('os', os)
    localStorage.setItem('phonepe_version_code', phonepe_version_code)
    localStorage.setItem("accessToken", accessToken)
    localStorage.setItem("platform", platfrom) 
    localStorage.setItem("userType", userType)
    localStorage.setItem("openFrom", openFrom === "Blocked" ? "Rejected" : "Dashboard")
    localStorage.setItem("phonpe_enable", phonepe_enable)
  }
    
  let flagValue = JSON.parse(localStorage.getItem("flag"))
  const[languageData,setlanguageData]=useState(flagValue||false)
  const[language,setLanguage]=useState(flagValue||false)
  const [isApiHit, setIsApiHit] = useState(false)


  useEffect(()=>{
    if(localStorage.getItem("currLang") === 'null'){
      setLanguage(false)
      setlanguageData(false)
      localStorage.setItem("currLang", false)
    }
    setlanguageData(localStorage.getItem("currLang") === 'true' ? languageJson:false)
    console.log(localStorage.getItem("currLang")=== 'false'? false:true)
    //switchLanguage(localStorage.getItem("currLang"))
  },[])
   
  const switchLanguage = (value)=>{
    if(value){
      localStorage.setItem("currLang", value)
      setlanguageData(languageJson)
    }
   else{      
    localStorage.setItem("currLang", value)
     setlanguageData(false)
   }
  }

  useEffect(()=>{
    if(accessToken === ""){
      window.location.href="/"
    }
  },[])

  console.log(languageData, "langData");
  
  return (
    <>
      <ToastContainer />
      <MyContext.Provider value={{languageData,language,setLanguage,switchLanguage, isApiHit, setIsApiHit}} >
      <BrowserRouter>
        <Routes>
          <Route path="/dashboard" element={<Dashboard/>} />
          <Route path="/autopay" element={<AutoPay/>} />
          <Route path="/" element={<Login/>} />
          <Route path="/otp-verify" element={<OtpVerify/>} />
          <Route path="/user-details" element={<UserDetails/>} />
          <Route path="/payment-status" element={<Payment/>} />
          <Route path="/feedback" element={<Feedback/>} />
          <Route path="/term" element={<Term/>} />
          <Route path="/select-plan" element={<SelectPlan/>} />
          <Route path="/total_accounts" element={<TotalAccounts/>} />
          <Route path="/total_loan_accounts" element={<TotalLoanAccounts/>} />
          <Route path="/total_enquiries" element={<TotalEnquiries/>} />
          <Route path="/overdue_payments" element={<OverduePayments/>} />
          <Route path="/age_of_accounts" element={<AgeOfAccounts/>} />
          <Route path="/credit_card_utilization" element={<CreditCardUtilization/>} />
          <Route path="/compare_report" element={<CompareReport/>} />
          <Route path="/select-subscription" element={<SelectSubscription/>} />
          <Route path="/subscription" element={<Subscription/>} />
          <Route path="/success-autopay" element={<SuccessAutoPay/>} />
          <Route path="/cibil-information-unavailable" element={<CibilInformationUnavailable/>} />
          <Route path="/email-verify" element={<EmailVerify/>} />
          <Route path="/email-otp" element={<EmailOtpVerify/>} />
          <Route path="/profile" element={<Profile/>} />
          <Route path="/manage_subscription" element={<ManageSubscription/>} />
        </Routes>
      </BrowserRouter>
      </MyContext.Provider>
    </>
  );
}

export default App;
