import mixpanel from 'mixpanel-browser';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Assistant from '../Common/Assistant';
import CreditFactor from '../Common/CreditFactor';
import CreditScore from '../Common/CreditScore';
import CustomerFeedback from '../Common/CustomerFeedback';
import DownloadCibil from '../Common/DownloadCibil';
import Expert from '../Common/Expert';
import Header from '../Common/Header';
import LatestReport from '../Common/LatestReport';
import LessScore from '../Common/LessScore';
import { Loader } from '../Common/Loader';
import PremiumScore from '../Common/PremiumScore';
import ToastMsg from "../Common/ToastMsg";
import { MyContext } from '../MyContext';
import background from "../assets/background.png";
import stop from "../assets/stop.png";
import info from "../assets/info.png";
import lock from "../assets/lock.png";
import tick from "../assets/mdi_tick.png";
import { abTesting, appsFlyer, getCibilString, getPaymentAmount, getStatus, getSuggestion, saveDetails, upiPay } from '../services/ApiServices';
import Latest from '../Common/Latest';
import NewUser from '../Common/NewUser';
import { eventOrg } from '../services/InfluxEvents'
import parse from 'html-react-parser';


mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
  debug: true,
});

const Dashboard = () => {
  // const accessToken = new URLSearchParams(window.location.search).get('token') || localStorage.getItem("accessToken");
  const {languageData,switchLanguage,language} = useContext(MyContext)
  const [cibilString, setCibilString] = useState("");
  const[amount,setAmount]=useState()
  const[revise,setRev]=useState()
  const [suggestion, setSuggestions] = useState([]);
  const [abtype, setAbType] = useState();
  const [abtypesub, setAbTypeSub] = useState();
  const [abUi, setAbUi] = useState();
  const [abUiType, setAbUiType] = useState();
  const [loader, setLoader] = useState(false);
  const [upiId, setUpiId] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [disabledbtn, setDisabledBtn] = useState(true);
  const [values, setValues] = useState(true);
  const [platform, setPlatfrom] = useState(new URLSearchParams(window.location.search).get("platform") || localStorage.getItem("platform"));
  const [open_from, setOpenFrom] = useState(new URLSearchParams(window.location.search).get("open_from") || localStorage.getItem("openFrom") === "Rejected" ? "Blocked" : "Dashboard");
  const [user_type, setUserType] = useState(new URLSearchParams(window.location.search).get("user_type") || localStorage.getItem("userType"));
  const [stateUpi, setStateUpi] = useState(true);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [transId, setTransactionId] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [openTabIndex, setOpenTabIndex] = useState(0);
  const upiRegex = /^[\w.-]+@[\w.-]+$/;
  const [upiValid, setUpiValid] = useState(true);
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const navigate = useNavigate();
  const navigateTofail = (props) => {
    navigate(
      '/payment-status',
      {
        state: {
          transactionId: props?.transaction_id
        }
      }
    )
  };

  useEffect(() => {
    if (upiId.match(upiRegex)) {
      setUpiValid(false)
    }
    else {
      setUpiValid(true)
    }
  }, [upiId])


  useEffect(() => {
    if (transId) {
      const interval = setInterval(() => {
        getPaymentStatus(transId);
      }, 2000);
      return () => clearInterval(interval);
    }
    // console.log(transId, "getPaymentStatusgetPaymentStatus");
    if (open === true) {
      mixpanel.track("Viewed_Know_More", { status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
      eventOrg("Viewed", "Viewed_Know_More", "TRUE")
      window.nv("event", "Viewed_Know_More", { "Status": true, "User_id": cibilString?.user_id, "Balance": cibilString?.balance, "Cibil_score": Number(cibilString?.cibil_score), "name": cibilString?.name, "email": cibilString?.email }, "100", 1);
      let data = {
        "event_name": "Viewed_Know_More",
        "event_body": {}
      }
      appsFlyer(
        data
      )
    }
    if (cibilString?.payment_status && cibilString?.thumb_impression === false) {
      mixpanel.track("Viewed_Customer_Feedback", { "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
      eventOrg("Viewed", "Viewed_Customer_Feedback", "TRUE")
      window.nv("event", "Viewed_Customer_Feedback", { "Status": true, "User_id": cibilString?.user_id, "Balance": cibilString?.balance, "Cibil_score": Number(cibilString?.cibil_score), "name": cibilString?.name, "email": cibilString?.email }, "100", 1);
      let data = {
        "event_name": "Viewed_Customer_Feedback",
        "event_body": {}
      }
      appsFlyer(
        data
      )
    }
  }, [timeLeft, transId, open, cibilString]);

console.log("cibilString",cibilString);

  const repay = () => {
    if (open === true) {
      mixpanel.track("Interacted_Know_More", { "Clicked on ": `Proceed To Pay ${cibilString?.balance || amount}`, status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
      eventOrg("Interacted", "Interacted_Know_More", "TRUE")
      let data = {
        "event_name": "Interacted_Know_More",
        "event_body": {}
      }
      appsFlyer(
        data
      )
    }
    else {
    mixpanel.track("Interacted_CA_Page", { "Clicked on ": `Pay ${cibilString?.balance || amount}`, status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
    eventOrg("Interacted", "Interacted_CA_Page", "TRUE")
      let data = {
        "event_name": "Interacted_CA_Page",
        "event_body": {}
      }
      appsFlyer(
        data
      )
    }
    submitPayment();
  };
  const submitPayment = (value) => {
    setLoader(true)
    let eurl = "";
    let data ;
    if(value){
      data = {
        is_revised_payment:true,
        balance: value,
        payment_source: localStorage.getItem("platform")
      };
    }
   else{
    data = {
      balance: amount,
      payment_source: localStorage.getItem("platform")
    };
   }
    if (isButtonDisabled) {
      return;
    }
    setIsOpen(true);
    setDisabled(false);
  
    upiPay(data)
      .then((res) => {
        // console.log(res?.data?.data)
        if (res?.status === 200) {
          setLoader(false)
          setDisabled(true);
          // console.log(res?.data)
          eurl = res?.data?.data;
          setTransactionId(res?.data?.transaction_id);
          getPaymentStatus(res?.data?.transaction_id);
          // console.log(res, eurl, "eurlll");
          setTimeout(() => {
            window.location.href = eurl;
          }, 100)
          setTimeLeft(500);
        }
      })
      .catch((err) => {
        setLoader(false)
        setDisabled(true);
        setIsOpen(false);
        setIsButtonDisabled(true)
        setTimeout(() => {
          setIsButtonDisabled(false)
        }, 2500)
        if (err?.response?.status === 401) {
          window.location.href = "/";
          localStorage.clear();
        }
      });
  };

  const handleTabClick = (index) => {
    setOpenTabIndex(openTabIndex === index ? null : index);
  };

  const getPaymentStatus = (transaction) => {
    setLoader(true);
    let data = {
      transaction_id: transaction ? transaction : transId,
    };
    getStatus(data)
      .then((res) => {
        if (res?.status === 200) {
          setLoader(false)
          if (res?.data?.status === "SUCCESS") {
            navigate("/payment-status?status=success")
          }
          if (res?.data?.status === "FAILURE") {
            navigateTofail(res.data)
          }
        }
      })
      .catch((err) => {
        setLoader(false)
        if (err.response?.status === 401) {
          window.location.href = "/";
          localStorage.clear();
        }
      });
  }
  
  const handleGetAmount = () => {
    getPaymentAmount()
    .then((res)=>{
        // console.log("amountttt",res?.data?.main_pay)
        setValues(res?.data)
        setAmount(res?.data?.main_pay)
        setRev(res?.data?.low_pay)
    }).catch((err)=>{
      if (err?.response?.status === 401) {
        window.location.href = "/";
        localStorage.clear();
      }
    })
  }
  const handleViewMore = () => {
    console.log("initiate");
    setOpen(open => !open)
    console.log("move");
    mixpanel.track("Interacted_CA_Page", { "Clicked on": "Know more", "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
    eventOrg("Interacted", "Interacted_CA_Page", "TRUE")
    let data = {
      "event_name": "Interacted_CA_Page",
      "event_body": {}
    }
    appsFlyer(
      data
    )
  };
  const handleterm = () => {
    mixpanel.track("Interacted_CA_Page", { "Clicked on": "T&C", "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
    eventOrg("Interacted", "Interacted_CA_Page", "TRUE")
    let data = {
      "event_name": "Interacted_CA_Page",
      "event_body": {}
    }
    appsFlyer(
      data
    )
  };
  const handlepopterm = () => {
    mixpanel.track("Interacted_Know_More", { "Clicked on": "T&C", "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
    eventOrg("Interacted", "Interacted_Know_More", "TRUE")
    let data = {
      "event_name": "Interacted_Know_More",
      "event_body": {}
    }
    appsFlyer(
      data
    )
  };
  const homecheck = () => {
    mixpanel.track("Interacted_CA_Page", { "Clicked on": "T&C_Checkbox", "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
    eventOrg("Interacted", "Interacted_CA_Page", "TRUE")
    let data = {
      "event_name": "Interacted_CA_Page",
      "event_body": {}
    }
    appsFlyer(
      data
    )
  };
  const popcheck = () => {
    mixpanel.track("Interacted_Know_More", { "Clicked on": "T&C_Checkbox", "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
    eventOrg("Interacted", "Interacted_Know_More", "TRUE")
    let data = {
      "event_name": "Interacted_Know_More",
      "event_body": {}
    }
    appsFlyer(
      data
    )
  };
  // localStorage.setItem("accessToken", accessToken)

  useEffect(() => {
    let data = {
      "event_name": "Viewed_CA_Page",
      "event_body": {}
    }
    appsFlyer(
      data
    )
    // switchLanguage(localStorage.getItem('currLang'))
    handleAbTesting();
    // handleGetSuggestion(language);
  }, [language]);

  const handleGetCibilString = () => {
    setLoader(true);
    getCibilString()
      .then((res) => {
        localStorage.setItem("cibilString", res)
        console.log(res, "resss")
        localStorage.setItem("payment_status", res.payment_status)
        setLoader(false);
        setCibilString(res)
        localStorage.setItem("panCard", res?.pan_card)
        localStorage.setItem("name", res?.name)
        mixpanel.identify(res?.user_id);
        mixpanel.people.set({ $name: res?.user_id })
        mixpanel.people.set("Credit Price", res?.balance)
      window.nv("event", "Viewed_CA_Page", { "Status": true, "User_id": res?.user_id, "Balance": res?.balance, "Cibil_score": Number(res?.cibil_score), "name": res?.name, "email": res?.email }, "100", 1);
      })
      .catch((err) => {
        setLoader(false);
        if (err.response?.status === 401) {
          // console.log(err,"error")
        }
        let error = err?.response?.data?.error || "something went wrong";
        ToastMsg(error, "error");
      });
  };

  const handleGetSuggestion = (language) => {
    //switchLanguage(language !=='Hindi'? false:true)
    let params = localStorage.getItem('currLang') === 'true'?"Hindi":"English"
    setLoader(true);
    getSuggestion(params)
      .then((res) => {
      if(res?.status===200){
        setSuggestions(res?.data?.suggestions)
        handleGetAmount();
        handleGetCibilString();
      }    
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          // console.log(err,"error")
        }
        let error = err?.response?.data?.error || "something went wrong";
        ToastMsg(error, "error");
      });
  };
  
  const handleAbTesting = () => {
    setLoader(true);
    abTesting()
      .then((res) => {
        mixpanel.track("Viewed_CA_Page", { "Platform": localStorage.getItem("platform"),"User Id": res?.data?.user_id, "Cibil Found":res?.data?.Cibil_found, "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
        eventOrg("Viewed", "Viewed_CA_Page", "TRUE")
      if(res?.status===200){
        localStorage.setItem("newUser", res?.data?.Cibil_found)
        localStorage.setItem("isNew", res?.data?.is_new)
        handleSaveDetails();
        setAbType(res?.data?.api_response?.data.includes("credit_expert"));
        setAbTypeSub(res?.data?.api_response?.data.includes("subcription_Plan"));
        setAbUi(res?.data?.api_response?.data.includes("new_680"));
        setAbUiType(res?.data?.is_less_than_680);
        if (res?.data?.new_user_payment_status === true && res?.data?.Cibil_found === false) {
          navigate('/user-details')  
        }
        else if(res?.data?.journey_status === "TRY_NOW"){
          navigate('/dashboard') 
        }
        else if(res?.data?.journey_status === "VIEW"){
          navigate('/dashboard')  
        }
        else if(res?.data?.journey_status === "PAY_NOW"){
          if(res?.data?.api_response?.data.includes("subcription_Plan")){
            navigate('/select-subscription')  
          }
          else{
            navigate('/dashboard')  
          }
        }
        else{
          navigate('/dashboard')  
        }
      }    
      })
      .catch((err) => {
        // if (err.response?.status === 400) {
        if (err.response?.status===412) {
          localStorage.setItem("newUser", err.response?.data?.Cibil_found)
          localStorage.setItem("isNew", err.response?.data?.is_new)
          handleSaveDetails();
          setAbType(err.response?.data?.api_response?.data.includes("credit_expert"));
          setAbTypeSub(err.response?.data?.api_response?.data.includes("subcription_Plan"));
          setAbUi(err.response?.data?.api_response?.data.includes("new_680"));
          setAbUiType(err.response?.data?.is_less_than_680);
          if (err.response?.data?.new_user_payment_status === true && err.response?.data?.Cibil_found === false) {
            navigate('/user-details')  
          }
          else if(err.response?.data?.journey_status === "TRY_NOW"){
            navigate('/dashboard') 
          }
          else if(err.response?.data?.journey_status === "VIEW"){
            navigate('/dashboard')  
          }
          else if(err.response?.data?.journey_status === "PAY_NOW"){
            if(err.response?.data?.api_response?.data.includes("subcription_Plan")){
              navigate('/select-subscription')  
            }
            else{
              navigate('/dashboard')  
            }
          }
          else{
            navigate('/dashboard')  
          }
        } 
        else{
          let error = err?.response?.data?.error || "something went wrong";
          ToastMsg(error, "error");
          window.location.href = "/";
          localStorage.clear();
        }
      });
  };
  const handleSaveDetails = () => {
    setLoader(true);
    let req = {
      platform : platform,
      user_type : user_type,
      open_from : open_from
    }
    saveDetails(req)
      .then((res) => {
      if(res?.status===200){
        setLoader(false);
        console.log(res?.data);
        if(localStorage.getItem("newUser") === "true"){
          handleGetSuggestion(language);
        }
        else{
          handleGetAmount();
          console.log("cibilStatus-false");
        }
        // localStorage.setItem("isSaveDetails" , true)
      }    
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          // console.log(err,"error")
        }
        // let error = err?.response?.data?.error || "something went wrong";
        // ToastMsg(error, "error");
      });
  };

  let dummysugg = [
    {
      suggestion: "Reduce credit card utilization from 40% to 30% for 0 out of 7",
    },
    {
      suggestion: "Reduce credit card utilization from 40% to 30% for 0 out of 7",
    },
    {
      suggestion: "Reduce credit card utilization from 40% to 30% for 0 out of 7",
    }
  ];

  const handleSubscriptionEvent = () =>{
    mixpanel.track("Interacted_CA_Page", { "Clicked on": "Proceed To Pay Subscription", "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
  }

  // const handleUserSession = () => {
  //   let data = {
  //     page : window.location.href.split("?")[0]
  //   };
  //   storeSession(data)
  //     .then((res) => {
  //       handleGetSuggestion(language);
  //     })
  //     .catch((err) => {
  //       if (err.response?.status === 401) {
           
  //       }
    
  //       let error = err?.response?.data?.detail || "something went wrong";
  //       ToastMsg(error, "error");
  //     });
  // }



  
  return (
    <>
      {loader ? <Loader /> : null}
      <section className='dashboard'>
        <div className='container container-sm'>
          <Header subscription={abtypesub} cibilString={cibilString}/>
          {(cibilString?.payment_status) ? null :
          <h2 className='text-center elevate mb-80'>{languageData.Elevate_your_credit_score_with || "Elevate your credit score with our"} <span style={{color:"#fc6603", fontWeight:"700"}}>{languageData.our_Personalized_Guidance || "Personalized Guidance"}</span><span></span></h2>}
          {abUi === true && abUiType === true ?
            null 
            :
            <p className={cibilString?.cibil_score < 680 ? "credit-score-line text-center mb-30" : "credit-score-line text-center mb-30 none"}>{languageData?.We_are_unable_to_offer_loan_to_you_due_to_low_Credit_score ||"We are unable to offer loan to you due to Low Credit score!"}</p>
          }
          <div className='dashboard-inner'>
          {(localStorage.getItem("newUser") === "true") ?
          <>
            {(cibilString?.payment_status) ?
            <PremiumScore score={cibilString?.cibil_score} last_update={cibilString?.last_update} sentence={cibilString?.cibil_sentence}/> 
            : 
            <>
              {abUi === true && abUiType === true ?
                <LessScore score={cibilString?.cibil_score} last_update={cibilString?.last_update} sentence={cibilString?.cibil_sentence}/>
                :
                <CreditScore score={cibilString?.cibil_score} sentence={cibilString?.cibil_sentence} subscription={abtypesub}/>
              }
            </>
          }
          </>
          :
          <NewUser/>
          }
           {/* {(abtype === true && cibilString?.is_latest_report)||(abtypesub === true && cibilString?.is_latest_report)?  
            <>
              <div className='text-center'>
                <h3>{languageData?.credit_insigts||"Credit Insights"}</h3>
                <LatestReport type="line"/>
              </div>
            </>
            :
            null
           } */}
            <div className='loan-info'>
            {/* {abUi === true && abUiType === true && cibilString?.payment_status === false ?
                null
              :
              <>{abtypesub === true ?
                <h2 className='mb-40 text-center get-instant-loan'>{languageData?.how_to_get_higher_loan_limit || "How to get higher loan limit"}?</h2>
                :
                <h2 className='mb-40 text-center get-instant-loan'>{languageData?.how_to_get_instant_loan || "How to get Instant Loan"}?</h2>
              }</>
            } */}
              <div className={cibilString?.payment_status ? 'info-inner' : 'info-inner active'}>
                {cibilString?.payment_status === false || (localStorage.getItem("newUser") === "false") ?
                  <div className='modal-content'>
                    <div className="header">
                      {abUi === true && abUiType === true ?
                        <>{abtypesub === true ? 
                          <p>{languageData?.Starting_from_just || "Starting from just ₹12"}</p>  
                          :
                          <p>{languageData?.For_just || "For just"} ₹{amount}</p> 
                        }</>
                        :
                        <>{abtypesub === true ? 
                          <p>{languageData?.Starting_from_just || "Starting from just ₹12"}</p>  
                          :
                          <p>{languageData?.For_just || "For just"} <span>₹</span>{amount} <del style={{fontSize:"18px"}}>₹{values?.main_pay_actual}</del> <span>{values?.main_pay_discount} Off</span></p>  
                        }</>
                      }
                    </div>
                    {abtypesub === true ? 
                      <>
                        <div className="content">
                        <p><img src={tick} width={24} height={24} alt='tick' />{languageData?.Received_tailored_suggestions || "Receive tailored suggestions"}</p>
                        <p><img src={tick} width={24} height={24} alt='tick' />{languageData?.Personalized_Credit_report || "Personalized Credit report"}</p>
                        <p><img src={tick} width={24} height={24} alt='tick' />{languageData?.Get_Higher_Loan_limit_within_60_Days || "Get Higher Loan limit within 60 Days"}*</p>
                      </div>
                      <p className='note'>*{languageData?.Improving_your_credit_with_personalized_advice_can_make_you_eligible_to_get_higher_loan_limit_in_60_days || "Improving your credit with personalized advice can make you eligible to get higher loan limit in 60 days."}</p>
                      </>
                    :
                    <>
                      <div className="content">
                        {' '}
                        {abUi === true && abUiType === true ?
                          <p><img src={tick} width={24} height={24} alt='tick' />{languageData?.Suggestions_to_improve_Credit_score || "Suggestions to improve Credit score"}</p>
                          :
                          <p><img src={tick} width={24} height={24} alt='tick' />{languageData?.Received_tailored_suggestions || "Receive Tailored Suggestions"}</p>
                        }
                        <p><img src={tick} width={24} height={24} alt='tick' />{languageData?.Personalized_Guidance || "Personalized Guidance"}</p>
                        <p><img src={tick} width={24} height={24} alt='tick' />{languageData?.Get_loan_within_60_days || "Get Loan Within 60 Days"}*</p>
                      </div>
                      <p className='note'>*{languageData?.Improving_your_credit_with_personalized_advice_can_make_you_loan_eligible_in_60_days || "Improving your credit with personalized advice can make you loan-eligible in 60 days."}</p>
                    </>
                    }
                    {
                      !languageData?.By_clicking_this_I_agree_to_the_Term_Conditions
                        ?
                        (<label className="main-check">By clicking this, I agree to the <a href='/term' onClick={handleterm} >Terms and conditions</a>
                          <input type="checkbox" name="agree" id="mycheckbox" checked={disabled} onChange={(e) => setDisabled(e.target.checked)} onClick={homecheck} />
                          <span className="geekmark"></span>
                        </label>) :

                        (<label className="main-check">इसे क्लिक करके, मैं<a href='/term' onClick={handleterm} > नियम और शर्तों </a>  से सहमत हूँ।
                          <input type="checkbox" name="agree" id="mycheckbox" checked={disabled} onChange={(e) => setDisabled(e.target.checked)} onClick={homecheck} />
                          <span className="geekmark"></span>
                        </label>)
                    }
                    <div className="actions">
                      {abtype === true ? 
                        <>
                          {abtypesub === true ?
                          <a className='btn' href='/select-subscription' disabled={!disabled} onClick={handleSubscriptionEvent}>  
                              {languageData?.procees_to_pay || "Proceed to Pay"}
                          </a>
                          :
                          <a className='btn' href='/select-plan' disabled={!disabled}>  
                              {languageData?.procees_to_pay || "Proceed to Pay"}
                          </a>
                          }
                        </>
                        :
                        <>
                        {abtypesub === true ?
                        <a className='btn' href='/select-subscription' disabled={!disabled} onClick={handleSubscriptionEvent}>  
                            {languageData?.procees_to_pay || "Proceed to Pay"}
                        </a>
                        :
                        <button className='btn' onClick={repay} disabled={!disabled}>
                          {languageData === false ? 
                            `Pay ₹${amount}`
                            :
                            `₹ ${amount} ${languageData?.Pay_49}`
                          }
                        </button>
                        }
                      </>
                      }
                    </div>
                    <div className='button-wrapper text-center mt-20'>
                      <a className="know-more" onClick={handleViewMore} data-testid="view-more"> {languageData?.Know_More|| "Know More"} </a>
                    </div>
                  </div>
                  :
                  <>
                    {suggestion?.length > 1 ?
                      <>
                        <h3 className='tailored'>{languageData?.tailored_suggestion || "Suggestions to Improve"}</h3>
                        <div className='accordion'>
                          <div className="tabs">
                            {suggestion.map((item, index) => (
                              <div className="tab" key={index}>
                                <input
                                  type="checkbox"
                                  name="accordion"
                                  id={`cb${index}`}
                                  checked={openTabIndex === index}
                                  onChange={() => handleTabClick(index)}
                                />
                                <label htmlFor={`cb${index}`} className="tab__label">
                                  {item.header ? parse(item.header) : 'Suggestion'}
                                </label>
                                {openTabIndex === index && (
                                  <div className="tab__content">
                                    <p>{item.suggestion ? parse(item.suggestion) : 'No suggestion provided.'}</p>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                      : null
                    }
                  </>
                }
                {(suggestion?.length !== 0 && suggestion?.length === 1) || (localStorage.getItem("newUser") === "false") ?
                  <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                    <div className="modal">
                      <button className="close" onClick={closeModal}>
                        &times;
                      </button>
                      <h3 className='mb-15'>{languageData?.how_to_get_instant_loan|| "How to get Instant Loan "}? </h3>
                      <ul>
                        {
                          suggestion?.map((item, suggestion) => {
                            return (
                              <li key={suggestion}>
                                {item.suggestion ? parse(item.suggestion) : 'No suggestion provided.'}
                              </li>
                            )
                          })
                        }
                        {suggestion?.length === 1 || (localStorage.getItem("newUser") === "false")?
                          dummysugg?.map((item, dummy) => {
                            return (
                              <li key={dummy} className='blur'>
                                {item?.suggestion}
                              </li>
                            )
                          }) : null
                        }
                        {suggestion?.length === 1 || (localStorage.getItem("newUser") === "false") ?
                          <div className='overlap-sc text-center'>
                            <img src={lock} alt='info' width={52} height={52} />
                            {abtypesub === true ?
                              <p style={{ fontWeight: "600", paddingTop: "10px", lineHeight:"1.2" }}>{languageData?.you_need_to_buy_a_plan||"You need to buy a plan"}<br />{languageData?.to_unlock_suggestion||"to unlock suggestions"}</p>
                              :
                              <p style={{ fontWeight: "600", paddingTop: "10px", lineHeight:"1.2" }}>{languageData?.You_need_to_pay_for_suggestions_1||"You need to Pay ₹59"}<br />{languageData?.You_need_to_pay_for_suggestions_2||"to unlock suggestions"}</p>
                            }
                          </div> : null
                        }
                      </ul>
                     {languageData?.By_clicking_this_I_agree_to_the_Term_Conditions ? (<label className="main-check">इसे क्लिक करके, मैं<a href='/term' onClick={handlepopterm} > नियम और शर्तों </a>  से सहमत हूँ।
                     <input type="checkbox" name="agree" id="mycheckbox" checked={disabledbtn} onChange={(e) => setDisabledBtn(e.target.checked)} onClick={popcheck} />
                     <span className="geekmark"></span>
                        </label>):
                         <label className="main-check">By clicking this, I agree to the <a href='/term' onClick={handlepopterm}>Terms and conditions</a>
                        <input type="checkbox" name="agree" id="mycheckbox" checked={disabledbtn} onChange={(e) => setDisabledBtn(e.target.checked)} onClick={popcheck} />
                        <span className="geekmark"></span>
                      </label>}
                      <div className="actions text-center">
                        {abtype === true ? 
                          <>
                          {abtypesub === true ?
                          <a className='btn' href='/select-subscription' disabled={!disabled} onClick={handleSubscriptionEvent}>  
                              {languageData?.procees_to_pay || "Proceed to Pay"}
                          </a>
                          :
                          <a className='btn' href='/select-plan' disabled={!disabled}>  
                              {languageData?.procees_to_pay || "Proceed to Pay"}
                          </a>}
                        </>
                          :
                          <>
                        {abtypesub === true ?
                        <a className='btn' href='/select-subscription' disabled={!disabled} onClick={handleSubscriptionEvent}>  
                            {languageData?.procees_to_pay || "Proceed to Pay"}
                        </a>
                        :
                        <button className='btn' onClick={repay} disabled={!disabled}>
                          {languageData === false ? 
                            `Pay ₹${amount}`
                            :
                            `₹ ${amount} ${languageData?.Pay_49}`
                          }
                        </button>
                        }
                      </>
                        }
                      </div>
                    </div>
                  </Popup>
                  :
                  null
                }
              </div>
                {(abtype === true && cibilString?.payment_status) ? 
                  <>
                    {(cibilString?.paid_amount === amount) ?  
                      <Assistant ass_amount={revise} submitPayment={submitPayment} /> 
                      :  
                      <Expert agent_name={cibilString?.agent_name} />
                    }
                  </>
                :
                  null
                }

                {/* {(abnumber === 2 && cibilString?.payment_status) 
                  <>
                  {cibilString?.is_revised_payment===false?
                    <Latest submitPayment={submitPayment} report={cibilString?.is_latest_report} />
                    :
                    null}
                    <CreditFactor/>
                  </>  
                :
                null} */}
                {cibilString?.payment_status && cibilString?.thumb_impression === false ?
                  <CustomerFeedback/> : null
                }
            </div>
          </div>
          {cibilString?.payment_status ? <CreditFactor/>: null}
          {/* {cibilString?.payment_status && cibilString?.thumb_impression === false ? <CustomerFeedback/> : null} */}
          {cibilString?.payment_status ? 
            <>
              <div className='query text-center mt-20 mb-30'>
                   <p>{languageData?.For_any_query_write_us_at || "For any query write us at:"}<br/><a href="mailto:creditassistsupport@paymeindia.in">creditassistsupport@paymeindia.in</a></p>
              </div>
            </>
          : null}
        </div>
        {/* <div className='dashboard-background'>
          <img src={background} alt='background' />
        </div> */}
      </section>
    </>
  )
}

export default Dashboard