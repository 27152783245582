import axios from "axios";
import ApiEndPoint from "./ApiEndPoint";

const accessToken = new URLSearchParams(window.location.search).get('token') || localStorage.getItem("accessToken");

// if (accessToken) {
//   axios.defaults.headers.common["Authorization"] = `Basic ${process.env.REACT_APP_AUTHORIZATION}`;    
//   axios.defaults.headers.common["Authentication"] = accessToken;
// }

// const headersApplicationJson = {
//     "Content-Type": "application/json",
// };

export const postCancelSubscription = (data) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION}`,
        "Authentication": accessToken
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(ApiEndPoint.POST_CANCEL,data, config)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);

        });
    });
};


export const getMandateStatus = () => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION}`,
        "Authentication": accessToken
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(ApiEndPoint.GET_MANDATE, config)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);

        });
    });
};

export const profilePicture = () => {
  // let url = `${API_ENDPOINT}/api/presigned_url_user_image/`;

  let config = {
    headers: {
      Authorization: "Token " + accessToken,
      "Content-Type": "application/json",
    },
  };

  return new Promise((resolve, reject) => {
      axios
        .get(ApiEndPoint.GET_PROFILE_PICTURE, config)
        .then((res) => {
          return resolve(res);
        })

        .catch((err) => {
          return reject(err);
        });
    });
};


export const getCibilString = () => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION}`,
        "Authentication": accessToken
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(ApiEndPoint.GET_CIBI_STRING, config)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);

        });
    });
};
export const getSubs = () => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION}`,
        "Authentication": accessToken
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(ApiEndPoint.GET_SUBS_PLAN, config)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);

        });
    });
};
export const phonpeSubscription = (data) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION}`,
        "Authentication": accessToken
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(ApiEndPoint.POST_PHONPE, data,config)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);

        });
    });
};

export const validateVpa = (data) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION}`,
        "Authentication": accessToken
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(ApiEndPoint.POST_VALIDATE,data,config)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);

        });
    });
};

export const upiPay = (data) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION}`,
      "Authentication": accessToken
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .post(ApiEndPoint.CREATE_PAYMENT, data, config)
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => {
        return reject(err)
      });
  });
};
export const suspendSubscription = (data) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION}`,
      "Authentication": accessToken
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .post(ApiEndPoint.POST_UPDATE_SUBSCTIPTION, data, config)
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => {
        return reject(err)
      });
  });
};

export const Feedback = (data) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION}`,
      "Authentication": accessToken
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .post(ApiEndPoint.CREATE_FEEDBACK, data, config)
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => {
        return reject(err)
      });
  });
};

export const getSuggestion = (language) => {
    let url =`${ApiEndPoint.SUGGESTIONS}?language_type=${language}`
    let config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION}`,
        "Authentication": accessToken
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(url, config)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

export const getStatus = (data) => {
  let url = `${ApiEndPoint.PAYMENT_STATUS}?transaction_id=${data?.transaction_id}`
  let config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION}`,
      "Authentication": accessToken
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .get(url, config)
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => {
        return reject(err)
      });
  });
};

export const appsFlyer = (data) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION}`,
      "Authentication": accessToken
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .post(ApiEndPoint.APPS_FLYER, data, config)
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => {
        return reject(err)
      });
  });
};

export const getPaymentAmount = () => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION}`,
      "Authentication": accessToken
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .get(ApiEndPoint.GET_PAYMENT_VALUE, config)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);

      });
  });
};


export const storeSession = (data) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${accessToken}`,
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .post(ApiEndPoint.STORE_USER_SESSION, data, config)
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => {
        return reject(err)
      });
  });
};
export const getProfileDetail = () => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION}`,
        "Authentication": accessToken
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(ApiEndPoint.GET_PROFILE, config)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);

        });
    });
};
export const getCreditFactor = () => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION}`,
      "Authentication": accessToken
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .get(ApiEndPoint.CREDIT_FACTOR, config)
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => {
        return reject(err)
      });
  });
};

export const getEnhancedReport = (factor_type) => {
  let url = `${ApiEndPoint.ENHANCED_REPORT}?factor_type=${factor_type}`
  let config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION}`,
      "Authentication": accessToken
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .get(url, config)
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => {
        return reject(err)
      });
  });
};

export const graphData = () => {
  let url = `${ApiEndPoint.GRAPH_DATA}`
  let config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION}`,
      "Authentication": accessToken
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .get(url, config)
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => {
        return reject(err)
      });
  });
};


export const abTesting = () => {
  let url = `${ApiEndPoint.AB_TESTING}`
  let config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION}`,
      "Authentication": accessToken
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .get(url, config)
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => {
        return reject(err)
      });
  });
};

export const saveDetails = (data) => {
  let url = `${ApiEndPoint.SAVE_DETAILS}?open_from=${data.open_from}&user_type=${data.user_type}&platform=${data.platform}`
  let config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION}`,
      "Authentication": accessToken
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .get(url, config)
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => {
        return reject(err)
      });
  });
};

export const phoneVerify = (params) => {
  let url = `${ApiEndPoint.LOGIN}`
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const otpVerify = (params) => {
  let url = `${ApiEndPoint.OTP}`
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const registerUser = (params) => {
  let url = `${ApiEndPoint.REGISTER}`;
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, {
        headers : {
          "Content-Type": "application/json",
        }
      })
      .then((res) => {
        localStorage.setItem("accessToken", res?.data?.data?.token);
        localStorage.setItem("access_refresh", res?.data?.data?.refresh);

        return resolve(res);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const emailVerify = (params) => {
  let url = `${ApiEndPoint.EMAIL_VERIFY}`;
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const emailOtpVerify = (params) => {
  let url = `${ApiEndPoint.EMAIL_OTP_VERIFY}`;
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        localStorage.setItem("accessToken", res?.data?.data?.token);
        localStorage.setItem("access_refresh", res?.data?.data?.refresh);
        return resolve(res);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const getCibilReportForm = (data) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION}`,
      "Authentication": accessToken
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .post(ApiEndPoint.CIBIL_REPORT_FORM, data, config)
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => {
        return reject(err)
      });
  });
};

export const getCibilReportEquifax = () => {
  let url = `${ApiEndPoint.CIBIL_REPORT_EQUIFAX}?from_web=true`
  let config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION}`,
      "Authentication": accessToken
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .get(url, config)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);

      });
  });
};

export const Equifac_report = (data) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      // "Authorization": "Basic ZGV2LWVxdWlmYXg6ZXF1aWZheA==",
      "Authorization": `Basic ${process.env.REACT_APP_AUTHORIZATION}`,
      "Authentication": accessToken
    },
    
  };
  return new Promise((resolve, reject) => {
    axios
      .post(ApiEndPoint.EQUIFAX_REPORT, data, config)
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => {
        return reject(err)
      });
  });
};