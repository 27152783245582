import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import Nodata from "../assets/nodata.png";
import hdfc from "../assets/HDFCC.png";
import sbi from "../assets/SBI.png";
import bob from "../assets/BOB.png";
import axis from "../assets/Axis.png";
import icici from "../assets/ICICI.png";
import kotak from "../assets/KOTAK.png";
import pnb from "../assets/PNB.png";
import other from "../assets/Other.png";
import car from "../assets/car.png";
import home from "../assets/homei.png";
import credit_card from "../assets/credit_card.png";
import backtohome from "../assets/Backtohome.png";
import personal_loan from "../assets/personal-loan.png";
import { getCibilString, getEnhancedReport } from "../services/ApiServices";
import ToastMsg from "../Common/ToastMsg";
import { MyContext } from "../MyContext";
import moment from "moment";
import mixpanel from 'mixpanel-browser';
import { Loader } from "../Common/Loader";
import Header from "../Common/Header";
import PremiumScore from "../Common/PremiumScore";
import goback from "../assets/go_back.png";
mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
    debug: true,
});

const AgeOfAccounts = () => {
  const [cibilString, setCibilString] = useState("");
  const {languageData} = useContext(MyContext)
  const [headerData, setHeaderData] = useState();
  const [accList, setAcclist] = useState();
  const[loader,setLoader]=useState(false)
  useEffect(() => {
    handleGetCibilString();
    handleTotalAccounts();
    mixpanel.track("Viewed_Age_Of_Account", { "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
  }, []);
  const handleTotalAccounts = () => {
    let data = window.location.pathname.split("/")[1];
    setLoader(true)
    getEnhancedReport(data)
      .then((res) => {
        setLoader(false)
        if (res?.status === 200) {
          console.log(res?.data?.data);
          setAcclist(res?.data?.data?.list_of_accounts);
          setHeaderData(res?.data?.data?.header_data);
        }
      })
      .catch((err) => {
        setLoader(false)
        if (err.response?.status === 401) {
          window.location.href="/"
        }
        let error = err?.response?.data?.error || "something went wrong";
        ToastMsg(error, "error");
      });
  };
  const handleGetCibilString = () => {
    getCibilString()
      .then((res) => {
        setLoader(false);
        setCibilString(res)
      })
      .catch((err) => {
        setLoader(false);
        if (err.response?.status === 401) {
          // console.log(err,"error")
        }
        let error = err?.response?.data?.error || "something went wrong";
        ToastMsg(error, "error");
      });
  };
  const navigate = useNavigate();
  return (
    <>
    {loader?<Loader/>:null}
      <div className='dashboard common-dash'>
        <div className='container container-sm'>
          <Header/>
        </div>
      </div>
      <div className="container">
          <div className="heading flex space-between align-center mb-50">
            <h1>{languageData?.Age_of_Accounts|| "Age of Accounts"}</h1>
            <img src={backtohome} alt="backtohome" width={40} height={40} onClick={() => navigate(-1)}/>
          </div>
          <div className="row">
            <div className="upper-main">
              <PremiumScore score={cibilString?.cibil_score} last_update={cibilString?.last_update} sentence={cibilString?.cibil_sentence}/>
              <div className="upper-common-box mb-40">
                <div className="upper-inner">
                  <p className="impact">
                  {headerData?.status === "Excellent"
                      ? (languageData?.High||"High")
                      : headerData?.status === "Fair"
                      ? (languageData?.High||"Medium")
                      :(languageData?.High||"Low")}{" "}
                    {languageData?.Impact|| "Impact"}
                  </p>
                  <p className="last-update">
                  {languageData?.Last_Updated||"Last updated"}: 
                  {moment(headerData?.last_updated).format("DD-MM-YYYY")}
                  </p>
                  <span
                    className={
                      headerData?.status === "Excellent"
                        ? "excellent tag"
                        : headerData?.status === "Fair"
                        ? "fair tag"
                        : "poor tag"
                    }
                  >
                    {headerData?.status === "Excellent"
                      ? (languageData?.excellent || "Excellent")
                      : headerData?.status === "Fair"
                        ? (languageData?.fair || "Fair")
                        : (languageData?.poor || "Poor")}{" "}
                  </span>
                </div>
                <div className="lower-inner">
                  <div className="flex">
                    <div className="col">
                  {languageData?.Active_Account|| "Active Account"}:<span>{headerData?.active_account}</span>
                    </div>
                    <div className="col">
                    {languageData?.Age_of_Accounts|| "Age of Account"}:<span>{headerData?.age_of_acc}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
            <div className="colmm">
              {accList?.length > 0  ? (
                <>
                <h3>{languageData?.Accounts|| "Accounts"}</h3>
                <div className="parent-card">
                {accList?.map((item, accounts) => {
                  return (
                    <div className="list-card list-card-add mt-20" key={accounts}>
                      {item?.accounts}
                      <div className="flex">
                        <div className="col">
                        {item?.member_name === "HDFC BANK" ? (
                                <img src={hdfc} alt="Loan Type" width={34} height={34} />
                              ) : item?.member_name === "SBI" || item?.member_name === "State Bank Of India" ? (
                                <img src={sbi} alt="Loan Type" width={30} height={30} />
                              ) : item?.member_name === "AXIS BANK" || item?.member_name === "Axis Bank Ltd." ? (
                                <img src={axis} alt="Loan Type" width={30} height={30} />
                              ) : item?.member_name === "KOTAK BANK" ? (
                                <img src={kotak} alt="Loan Type" width={30} height={30} />
                              ) : item?.member_name === "ICICI BANK" || item?.member_name === "ICICI BANK LIMITED" ? (
                                <img src={icici} alt="Loan Type" width={30} height={30} />
                              ) : item?.member_name === "PUNJAB NATIONAL BANK" ? (
                                <img src={pnb} alt="Loan Type" width={30} height={30} />
                              ) : item?.member_name === "BANK OF BARODA" ? (
                                <img src={bob} alt="Loan Type" width={30} height={30} />
                              ) : (
                                <img src={other} alt="Loan Type" width={30} height={30} />
                              )}
                        </div>
                        <div className="col">
                          <h4>{item?.member_name}</h4>
                          <p> ****{item?.account_number}</p>
                        </div>
                        <div className="col">
                        {item?.loan_type === "credit_card" ? 
                        <>
                          {item?.status === false ?
                              <>
                                  <p style={{textAlign:"right"}}>{languageData?.Status||"Status"}: <span style={{color:"rgba(14, 170, 0, 1)"}}>{languageData?.Active||"Active"}</span></p>
                                  <p>{languageData?.Issue_Date||"Issue Date"}: {moment(item?.issue_date).format("DD-MM-YYYY") || 0} </p>
                              </>
                              :
                              <>
                                  <p style={{textAlign:"right"}}>{languageData?.Status||"Status"}: <span style={{color:"rgba(21, 39, 69, 0.4)"}}>{languageData?.Closed||"Closed"}</span></p>
                                  <p>{languageData?.Issue_Date||"Issue Date"}: {moment(item?.issue_date).format("DD-MM-YYYY") || 0} </p>
                              </>
                          }
                          </>
                          :
                          <>
                            {item?.loan_type === "credit_card" || item?.loan_type === "Credit Card" ? (
                              <img
                                src={credit_card}
                                alt="Loan Type"
                                width={30}
                                height={30}
                              />
                            ) : item?.loan_type === "personal_loan" || item?.loan_type === "Persoanl Loan" ? (
                              <img
                                src={personal_loan}
                                alt="Loan Type"
                                width={30}
                                height={30}
                              />
                            ) : item?.loan_type === "p2p_personal_loan" || item?.loan_type === "Persoanl Loan" ? (
                              <img
                                src={personal_loan}
                                alt="Loan Type"
                                width={30}
                                height={30}
                              />
                            ) : item?.loan_type ===
                              "(blps-agr)_business_loan_–_priority_sector_–_agriculture" || item?.loan_type === "Persoanl Loan" ? (
                              <img
                                src={personal_loan}
                                alt="Loan Type"
                                width={30}
                                height={30}
                              />
                            ) : item?.loan_type === "short_term_personal_loan" || item?.loan_type === "Persoanl Loan" ? (
                              <img
                                src={personal_loan}
                                alt="Loan Type"
                                width={30}
                                height={30}
                              />
                            ) : item?.loan_type === "auto_loan_(personal)" || item?.loan_type === "Auto Loan"  ? (
                              <img src={car} alt="Loan Type" width={30} height={30} />
                            ) : item?.loan_type === "housing_loan" || item?.loan_type === "Housing Loan" ? (
                              <img src={home} alt="Loan Type" width={30} height={30} />
                            ) :(
                              <img src={personal_loan} alt="Loan Type" width={30} height={30} />
                            )}
                          </>
                        }
                        </div>
                      </div>
                      {item?.loan_type === "credit_card" ? 
                          <div className="used-sc flex align-center space-between">
                          <div className="colm">
                            <p className="pb-10">{languageData?.Total_Credit_Limit|| "Total Credit Limit"}</p>
                            <h4>₹{new Intl.NumberFormat('en-IN').format(item?.total_limit)}</h4>
                          </div>
                          <div className="colm">
                            <p className="pb-10">{languageData?.Utilization|| "Utilization"}</p>
                            <h4>{Math.trunc(item?.utilization)}%</h4>
                          </div>
                          <div className="colm">
                            <p className="pb-10">{languageData?.Limit_Used||"Limit Used"}</p>
                            <h4>₹{new Intl.NumberFormat('en-IN').format(item?.outstanding_amount)}</h4>
                          </div>
                        </div>
                        :
                        <div className="flex align-center space-between inr" style={{background:"rgba(252, 102, 3, 0.2)!important"}}>
                          <div className="colm">
                            <p className="pb-10">{languageData?.Loan_Amount||"Loan Amount"}</p>
                            <h4>₹{new Intl.NumberFormat('en-IN').format(item?.loan_amount)}</h4>
                          </div>
                          <div className="colm">
                            <p className="pb-10">{languageData?.Outstanding||"Outstanding"}</p>
                            <h4>₹{new Intl.NumberFormat('en-IN').format(Math.trunc(item?.outstanding_amount))}</h4>
                          </div>
                          <div className="colm">
                            <p className="pb-10">{languageData?.Tenure||"Tenure"}</p>
                            <h4>{moment(item?.tenure).format("DD-MM-YYYY") || 0}</h4>
                          </div>
                        </div>
                      }
                    </div>
                  );
                })}
                </div>
              </>
              ) : (
                <div className="text-center">
                  <img
                    src={Nodata}
                    alt="nodata"
                    width={212}
                    height={277}
                    className="mt-10"
                  />
                </div>
              )}
            </div>
          </div>    
      </div>
      <div className="goback text-center mt-75">
        <img src={goback} alt="goback" width={40} height={40} onClick={() => navigate(-1)}/>
      </div>
    </>
  );
};

export default AgeOfAccounts;
