import React from 'react';
import logo from "../assets/logo-m.png";

const Term = () => {
  return (
    <>
        <section className='term'>
            <div className='container container-sm'>
                <div className='logo mt-20 mb-20 flex space-between align-center' >
                    <a href='/'><img src={logo} alt='logo' width={135} height={40}/></a>
                    <a href='/'>Back to Dashboard </a>
                </div>
                <div className='main'>
                    <h1>Credit Assist<br/><span>Terms and Conditions</span></h1>
                    <p>This Credit Assist Services (hereinafter referred to as the <b>“Service”</b>) stipulates a scheme offered by Huey Tech Private Limited, a company incorporated under the provisions of the Companies Act, 2013 registered office at 3rd Floor, Block D, Plot No. 5 Logix Infotech Park, Sector 59, Noida, UP 201309 India (hereinafter referred to as <b>“We”, “Us”, “Our”, “Company” or “PayMe”</b>), to its end user (hereinafter referred to as <b>“You”, “Your”, “Customer”</b>) in relation to the use of the website “<a href='https://www.paymeindia.in/'>www.paymeindia.in</a>” and/or the mobile application “PayMe App” available at Android/ iOS platforms (collectively referred to as the <b>“Online Platform”</b>).</p>
                    <p>PayMe and Customer are hereinafter collectively referred as the “Parties” and individually as “Party”.</p>
                    <h4>1. Eligibility: </h4>
                    <p>Customers registered with us are above the age of 18 years and are eligible to avail in this service.</p>
                    <h4>2. Services:</h4>
                    <ul>
                        <li>PayMe will publish bureau health and suggestions to Customers through our app or website.</li>
                        <li>CIR is a record of the credit payment history compiled with information received from Credit Institutions. The purpose of this CIR is to help Credit Institutions make informed lending decisions - quickly and objectively and enable faster processing of credit applications to help provide quicker access to credit at better terms.</li>
                        <li>PayMe will analyze the credit report and then will provide the personalized suggestions to the Customer based on their credit history.</li>
                        <li>PayMe may apply for the CIR and credit score either by themselves directly or through any of their partner tie ups who are members of CIC.</li>
                        <li>Customers can avail themselves of the services provided by PayMe by paying a nominal amount. The Customer can get his Credit Bureau Report as well as suggestions on how to improve his Credit Score, so that he can avail better Credit.  </li>
                        <li>The services will be available on both the platform i.e., web as well as app.</li>
                        <li>This is a one-time service and not a recurring one, PayMe will fetch the bureau report and provide the customer with the suggestions and bureau report once the payment is done by the customer.</li>
                        <li>The amount paid by the Customer will be non-refundable.</li>
                        <li>In case the customer is not satisfied by the published bureau report, the customer shall approach the concerned CIC body for any rectification. PayMe will not be held responsible for any discrepancies in the report.</li>
                    </ul>
                    <h4>3. Availing of the Credit Assist Services:</h4>
                    <ul>
                        <li>Customer shall go on the PayMe App or Website and signup or login with their registered mobile number.</li>
                        <li>An OTP will be sent to the mobile number for verification. Once they enter the OTP, an OTP shall be sent to their registered email address for verification.</li>
                        <li>Once email is verified, the Customer shall enter their PAN card number for fetching of his KYC details.</li>
                        <li>In case the PAN verification fails due to low credit score, he is redirected to the Credit dashboard.</li>
                        <li>Users can see their credit score on credit dashboard and can make the minimal payments for the rule-based suggestion that will help to improve their credit and make them eligible to take loan.</li>
                        <li>On the credit dashboard, the customer's credit score and its impact shall be displayed.</li>
                        <li>Credit score improvement suggestions shall be shown, including account details, credit card information, overdue payments, card utilization, inquiries, and age of the account.</li>
                        <li>The Customer shall receive only one customized suggestion, and the rest will be hidden.</li>
                        <li>When the Customer clicks on the “View more” button, a pop-up will display a list of services provided after payment.  </li>
                        <li>After a successful payment of requested amount, the remaining hidden suggestions will appear.  </li>
                        <li>Clicking on the info icon will provide the Customer with summarized details about the suggestion.   </li>
                        <li>The Customer will receive the credit report and suggestion document via email (in web view).  </li>
                        <li>The Customer will also have the option to download the credit report in PDF format.  </li>
                    </ul>
                    <h4>4. Rules:</h4>
                    <ul>
                        <li>Customers' participation in this service constitutes their understanding and agreement to the complete terms and conditions associated with this service.</li>
                        <li>PayMe does not undertake to modify, correct or improve the Customer’s credit bureau reports. </li>
                        <li>The Credit Assist Services are offered to the Users on an “as is” basis and without warranty or condition. The Services may not be uninterrupted or error-free. Users waive all claims against PayMe.</li>
                        <li>PayMe reserves the right to change, modify or prematurely remove the service at its own discretion.  </li>
                        <li>PayMe reserves the right to increase/decrease the fee charged from the Customer for the Services.  </li>
                        <li>Further, PayMe also reserves the right to disqualify any Customer, who does not meet the service requirements or in case of reasons including but not limited to any misuse of the services or fraud or suspicious transaction/activity or under any legal requirement or applicable rules and regulations, from receiving any reports or cancel any services already provided.   </li>
                        <li>In case of a dispute, the final decision to offer / modify / revoke the services lies with the Company. </li>
                        <li>Nothing contained herein shall be construed as a binding obligation on PayMe to continue the Services or to substitute the Services by a similar or a new Service.   </li>
                        <li>The Customer shall not hold PayMe responsible for any actions, claims, demands, liabilities, losses, damages, costs, charges, or expenses, which a Customer incurs, in the normal course of availing the PayMe Services.  </li>
                        <li>PayMe also reserves the right to discontinue the Services without assigning any reasons or without any prior intimation whatsoever. Nothing contained herein shall amount to a commitment or representation by us to continue the Services.  </li>
                        <li>Further, PayMe reserves the right, at its sole discretion, to change the mode of the accrual of one or any of the services.    </li>
                        <li>These Services Terms and Conditions shall be in addition to and not in substitution/derogation of any or all other terms and conditions.  </li>
                        <li>No two services can be clubbed. In case there is more than one service available simultaneously, then only one service can be availed by the Customer at one time.   </li>
                        <li>Participation in any of the Services is completely voluntary. Users may choose to not avail in any of the Services.    </li>
                        <li>PayMe is not responsible for any tax implications on the User from their availing any Services. Users should obtain independent tax advice before availing any services.    </li>
                    </ul>
                    <h4>5. Dispute Resolution:</h4>
                    <p>Any dispute between the Parties arising out of or in relation to the Rewards (the "Dispute"), shall be resolved by final and binding arbitration held in Delhi in accordance with the provision of Indian Arbitration and Conciliation Act, 1996, as amended. The language used in the arbitral proceedings shall be English. This Agreement shall be governed by and interpreted in accordance with the laws of India and the Courts at New Delhi shall have exclusive jurisdiction. </p>
                </div>
                <div className='logo mt-20 mb-20 flex space-between align-center' >
                    <a href='/'><img src={logo} alt='logo' width={65} height={31}/></a>
                    <a href='/'>Back to Dashboard</a>
                </div>
            </div>
        </section>
    </>
  )
}

export default Term