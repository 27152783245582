import React, {useContext} from 'react';
import credit from "../assets/credit.png";
import arrow from "../assets/arrow.png";
import creditFrame from "../assets/credit-frame.png";
import { MyContext } from '../MyContext';

const CreditScore = ({score, sentence, subscription}) => {
    const {languageData} = useContext(MyContext)
  return (
    <>
        <div className='credit-sc mb-60'>
              {subscription === true ?
                <h3 className='mb-30 text-center'>{languageData?.credit_report_summary || "Credit Report Summary"}</h3>
                :
                <h3 className='mb-30 text-center'>{languageData?.your_cibil_score || "Your Cibil Score"}</h3>
              }
              <div className='credit-sc-inner'>
                <img src={credit} alt='credit' width={514} height={280} />
                <img src={arrow} alt='credit' width={25} height={25}
                    className={sentence==="excellent"?"excellent":sentence==="good"?"good":sentence==="fair"?"fair":sentence==="very poor"?"poor":"blocked"}
                />
                <div className='frame'>
                  <img src={creditFrame} alt='creditFrame' width={158} height={76} className='credit-frame' />
                </div>
                <div className='score-box'>
                  <p className='score'>{score}</p>
                  <p className='score-type' >{sentence === "excellent" ? `${languageData?.excellent||'Excellent'}` : sentence === "good" ? `${languageData?.good||"Good"}` : sentence === "fair" ? `${languageData?.fair||"Fair"}` : sentence === "very poor" ? `${languageData?.poor||"poor"}` : "blocked"}</p>
                </div>
              </div>
            </div>
    </>
  )
}

export default CreditScore