import React from 'react'
import failed from "../assets/warning.png"

const CibilInformationUnavailable = () => {
  return (
    <>
        <section className="payment_status ">
            <div className="container container-sm text-center">
                <div className='text-left'>
                    <h1>CIBIL Information Unavailable</h1>
                </div>
                <div className="payment_inner mt-20">
                    <img src={failed} alt="failed" width={161} height={161} />
                    <h3>Oops!</h3><br/><br/>
                    <p className='pb-40'>
                        Your credit report is unavailable at the moment
                        <br/><br/><br/><br/>
                        Your amount will be refunded in next 3-4 working days.
                        <br/><br/><br/><br/>
                        For any other query contact us at:
                        <br/><br/>
                        <a href='mailto:creditassist@paymeindia.in'>creditassist@paymeindia.in</a>
                    </p>
                    <div className="button-wrapper text-center pt-0">
                        <a href='https://www.paymeindia.in/credit-assist/' className="btn btn-white mb-30" data-testid="sugbtn">
                            Back to Home
                        </a>
                    </div>
                </div> 
            </div>
      </section>
    </>
  )
}

export default CibilInformationUnavailable