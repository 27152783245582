
import React, { useContext } from 'react';
import bulb from "../assets/bulb.png";
import compare from "../assets/compare.png";
import right from "../assets/right-i.png";
import { MyContext } from '../MyContext';
import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
    debug: true,
});

const Latest = ({ report,submitPayment,revised }) => {
    const {languageData} = useContext(MyContext)
    const handleCompareReport = () => {
        mixpanel.track("Interacted_Compared_Report", { "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
    };
    const handleLatestReport = () => {
        mixpanel.track("Interacted_Latest_Report", { "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
    }
    return (
        <>
            <div className='latest'>
                <div className='button-wrapper text-center'>
                    <button onClick={()=>{
                      submitPayment(true)
                      handleLatestReport()
                    }} className='btn' disabled={!report}>{languageData?.Get_Latest_Report||"Get Latest Report"}</button>
                </div>
                <div className='flex align-center space-center mt-30 mb-50'>
                    <img src={bulb} width={18} height={18} alt='bulb' className='mr-10' />
                    <p>{languageData?.You_can_refresh_your_score_after_30_days|| "You can refresh your score after 30 days."}</p>
                </div>
            </div>
            {revised === true ? 
                <a href='/compare_report' onClick={handleCompareReport}>
                    <div className='compare flex align-center space-between'>
                        <div className='left'>
                            <img src={compare} alt='compare' width={55} height={55}/>
                        </div>
                        <div className='center'>
                            <p>{languageData?.Compare_with_Previous_Reports||"Compare with Previous Reports"}</p>
                        </div>
                        <div className='right'>
                            <img src={right} alt='right' width={45} height={45} />
                        </div>
                    </div>
                </a>
                :
                null
            }
        </>
    )
}

export default Latest