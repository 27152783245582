import React, { useContext, useEffect, useState } from 'react';
import { graphData } from '../services/ApiServices';
import ToastMsg from './ToastMsg';
import { Loader } from './Loader';
import moment from 'moment';
import bulb from "../assets/bulb.png";
import { MyContext } from '../MyContext';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  BarElement,
  Legend,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const LatestReport = ({ type }) => {
  const { languageData, language } = useContext(MyContext)
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(false)
  const [months, setMonths] = useState([])
  const [scores, setScores] = useState([])
  useEffect(() => {
    handleGetGraphData()
  }, [])
  const handleGetGraphData = () => {
    setLoader(true)
    graphData().then((res) => {
      setLoader(false)
      const originalArray = res?.data?.data;
      if(originalArray.length>0){
      const newArray = originalArray.map(obj => {
        const key = Object.keys(obj)[0];
        const value = obj[key];
        return { month: moment().month(Number(key) - 1).format("MMMM").slice(0, 3), score: value };
      });
      const months_t = originalArray.map(obj => {
        const key = Object.keys(obj)[0];
        const value = obj[key];
        return moment().month(Number(key) - 1).format("MMMM").slice(0, 3);
      });
      setMonths(months_t)
      const score_t = originalArray.map(obj => {
        const key = Object.keys(obj)[0];
        const value = obj[key];
        return value;
      });
      setScores(score_t)
      console.log(newArray,"newarray")
    }
    }).catch((err) => {
      if (err?.response?.status === 401) {
        window.location.href = "/"
      }
      let error = err?.response?.data?.error || "something went wrong";
      ToastMsg(error, "error");
      setLoader(false)
    })
  }




  const options = {

    responsive: true,
    plugins: {
      legend: {

        display: false,
      },

    },


    y: {
      min: 350,
      max: 900,
      ticks: {
        stepSize: 50
      },


    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Credit Scores'
        }
      },
     
    }


  };


  const labels =months;

  const Line_data = {
    labels,
    datasets: [
      {
        label: '',

        data: scores,
        backgroundColor: type === "bar" ? ["#152745", "#FC6603"] : "#FC6603",
        barThickness: 23,
        borderWidth: 1.5,
        borderColor: type === "bar" ? ["#152745", "#FC6603"] : "#152745",

        pointBackgroundColor: "#FC6603",
        pointBorderColor: "#ffffff",
        pointStyle: 'circle',
        pointRadius: [6],


      },

    ],
  };



  return (
    <>
      {loader ? <Loader /> : null}

      <div className='graph'>
        {type === "bar" ?
          <Bar options={options} data={Line_data} /> :
          <Line options={options} data={Line_data} />
        }



        {type === "bar" ?
          <>
            <p className='remain'>
             {languageData?.Your_Credit_Remain_unchanged||" Your credit remains unchanged"}
            </p>
            <div className='flex align-center space-center  mt-30 mb-50 f-14 backk'>
              <img src={bulb} width={18} height={18} alt='bulb' className='mr-10' />
              <div className='compare-text'>{languageData?.The_numerical_values_and || "The numerical values and illustrations are derived from a comparison made with your earlier credit report."}</div>
            </div>
          </>
          : null}


      </div>

    </>
  )
}

export default LatestReport