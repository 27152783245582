import React from 'react'
import meter from "../assets/fair-image.png"

const NewUser = () => {
  return (
    <>
      <div className='text-center new-userr'>
        <img src={meter} alt='meter' width={695} height={445} />
      </div>
    </>
  )
}

export default NewUser