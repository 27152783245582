import React, {useContext, useEffect, useState} from 'react';
import goto from "../assets/goto.png";
import cf1 from "../assets/cf-1.png";
import tla from "../assets/tla.png";
import te from "../assets/te.png";
import op from "../assets/op.png";
import aoa from "../assets/aoa.png";
import ccu from "../assets/ccu.png";
import cf1_d from "../assets/cf-1-d.png";
import tla_d from "../assets/tla-d.png";
import te_d from "../assets/te-d.png";
import op_d from "../assets/op-d.png";
import aoa_d from "../assets/aoa-d.png";
import ccu_d from "../assets/ccu-d.png";
import ToastMsg from "../Common/ToastMsg";
import { useNavigate } from "react-router-dom";
import { getCreditFactor } from '../services/ApiServices';
import mixpanel from 'mixpanel-browser';
import { MyContext } from '../MyContext';
mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
    debug: true,
});



const CreditFactor = () => {
  const {languageData} = useContext(MyContext)
    const [factors, setFactors] = useState();
    const navigate = useNavigate();
    useEffect(()=>{
        handleCreditFactor()
    },[])
    const handleCreditFactors = (factors) => {
      // console.log(factors,"$$");
      if(factors?.name === "Total Accounts"){
        mixpanel.track("Interacted_CA_Page", { "Clicked on ": "Interacted_Total_Account", status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
        navigate("/total_accounts");
      }
      else if(factors?.name === "Total Loan Accounts"){
        mixpanel.track("Interacted_CA_Page", { "Clicked on ": "Interacted_Total_Loan_Account", status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
        navigate("/total_loan_accounts");
      }
      else if(factors?.name === "Total Enquiries"){
        mixpanel.track("Interacted_CA_Page", { "Clicked on ": "Interacted_Total_Enquries", status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
        navigate("/total_enquiries");
      }
      else if(factors?.name === "Overdue Payments"){
        mixpanel.track("Interacted_CA_Page", { "Clicked on ": "Interacted_Overdue_Payments", status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
        navigate("/overdue_payments");
      }
      else if(factors?.name === "Age of Accounts"){
        mixpanel.track("Interacted_CA_Page", { "Clicked on ": "Interacted_Age_of_Account", status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
        navigate("/age_of_accounts");
      }
      else{
        mixpanel.track("Interacted_CA_Page", { "Clicked on ": "Interacted_Credit_Utilization", status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
        navigate("/credit_card_utilization");
      }
    };
    const handleCreditFactor = () => {
        getCreditFactor()
          .then((res) => {   
          setFactors(res?.data?.data)
          // console.log(factors?.name, "@@@");
          })
          .catch((err) => {
            if (err.response?.status === 401) {
            }
            let error = err?.response?.data?.error || "something went wrong";
            ToastMsg(error, "error");
        });
    };

  return (
    <>
        <div className='credit-factor mb-20'>
            <h3 className='mb-20 text-center'>{languageData?.Credit_Factor||"Credit Factors"}</h3>
            <div className='flex space-between align-start'>
                {factors?.map((item, index) => {
                    return (
                      <div className="column" key={index}>
                        <div className="column-inner">
                          {item?.factors}
                          <a onClick={() => handleCreditFactors(item)}>
                            <div
                              className={
                                item?.status === "Excellent"
                                  ? "excellent left"
                                  : item?.status === "Fair"
                                  ? "fair left"
                                  : "poor left"
                              }
                            >
                              {item?.status === "Excellent"
                                ? languageData?.excellent || item?.status
                                : item?.status === "Fair"
                                ? languageData?.fair || item?.status
                                : item?.status === "Poor"
                                ? languageData?.poor || item?.status
                                : item?.status}
                            </div>
                            <div className="right">
                              <div className="top-d">
                                {item?.name === "Total Accounts" ? (
                                  <img
                                    src={cf1_d}
                                    alt="cf1"
                                    width={80}
                                    height={80}
                                  />
                                ) : item?.name === "Total Loan Accounts" ? (
                                  <img
                                    src={tla_d}
                                    alt="cf1"
                                    width={80}
                                    height={80}
                                  />
                                ) : item?.name === "Total Enquiries" ? (
                                  <img
                                    src={te_d}
                                    alt="cf1"
                                    width={80}
                                    height={80}
                                  />
                                ) : item?.name === "Credit Card Utilization" ? (
                                  <img
                                    src={ccu_d}
                                    alt="cf1"
                                    width={80}
                                    height={80}
                                  />
                                ) : item?.name === "Overdue Payments" ? (
                                  <img
                                    src={op_d}
                                    alt="cf1"
                                    width={80}
                                    height={80}
                                  />
                                ) : item?.name === "Age of Accounts" ? (
                                  <img
                                    src={aoa_d}
                                    alt="cf1"
                                    width={80}
                                    height={80}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="top">
                                {item?.name === "Total Accounts" ? (
                                  <img
                                    src={cf1}
                                    alt="cf1"
                                    width={36}
                                    height={36}
                                  />
                                ) : item?.name === "Total Loan Accounts" ? (
                                  <img
                                    src={tla}
                                    alt="cf1"
                                    width={36}
                                    height={36}
                                  />
                                ) : item?.name === "Total Enquiries" ? (
                                  <img
                                    src={te}
                                    alt="cf1"
                                    width={36}
                                    height={36}
                                  />
                                ) : item?.name === "Credit Card Utilization" ? (
                                  <img
                                    src={ccu}
                                    alt="cf1"
                                    width={36}
                                    height={36}
                                  />
                                ) : item?.name === "Overdue Payments" ? (
                                  <img
                                    src={op}
                                    alt="cf1"
                                    width={36}
                                    height={36}
                                  />
                                ) : item?.name === "Age of Accounts" ? (
                                  <img
                                    src={aoa}
                                    alt="cf1"
                                    width={36}
                                    height={36}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className='inner-right'>
                                <h4> {
                                    item?.name === "Total Accounts"?(languageData?.Total_Accounts||item?.name)
                                    :item?.name ==="Total Loan Accounts"?(languageData?.Total_Loan_Accounts||item?.name)
                                    :item?.name ==="Total Enquiries"?(languageData?.Total_Enquiries||item?.name)
                                    :item?.name ==="Credit Card Utilization"?(languageData?.Credit_Card_Utilizations||item?.name)
                                    :item?.name ==="Overdue Payments"?(languageData?.Overdue_Payments||item?.name)
                                    :item?.name ==="Age of Accounts"?(languageData?.Age_of_Accounts||item?.name)
                                    :"anchor"
                                  }</h4> 
                                <span>&#x2022; {item?.status==="Excellent"?(languageData?.High||"High"):item?.status==="Fair"?(languageData?.Medium||"Medium"):(languageData?.Low||"Low")} {languageData?.Impact||"Impact"}</span><br/>
                                <span className={item?.status==="Excellent"?"excellent active":item?.status==="Fair"?"fair active":item?.status==="Poor"?"poor active":"active"}>
                                  &#x2022; {item?.active+" "} 
                                  {
                                    item?.name === "Total Accounts"?( languageData?.Accounts||"Accounts")
                                    :item?.name ==="Total Loan Accounts"?(languageData?.Active_Accounts||"Active Accounts")
                                    :item?.name ==="Total Enquiries"?(languageData?.Enquires||"Enquiries")
                                    :item?.name ==="Credit Card Utilization"?(languageData?.Credit_Used||"Credit Limit used")
                                    :item?.name ==="Overdue Payments"?(languageData?.Timely_Payment||"Overdue Payments")
                                    :item?.name ==="Age of Accounts"?""
                                    :""
                                  }
                                </span>
                                <img src={goto} alt='goto' width={32} height={32} className='right-arrow'/>
                              </div>
                            </div>
                          </a>
                        </div>
                        <p className='mt-10 mb-15 head-p' onClick={() => handleCreditFactors(item)}>
                          {" "}
                          {item?.name === "Total Accounts"
                            ? languageData?.Total_Accounts || item?.name
                            : item?.name === "Total Loan Accounts"
                            ? languageData?.Total_Loan_Accounts || item?.name
                            : item?.name === "Total Enquiries"
                            ? languageData?.Total_Enquiries || item?.name
                            : item?.name === "Credit Card Utilization"
                            ? languageData?.Credit_Card_Utilizations ||
                              item?.name
                            : item?.name === "Overdue Payments"
                            ? languageData?.Overdue_Payments || item?.name
                            : item?.name === "Age of Accounts"
                            ? languageData?.Age_of_Accounts || item?.name
                            : "anchor"}
                        </p>
                      </div>
                    );
                })}
            </div>
        </div>
    </>
  )
}

export default CreditFactor