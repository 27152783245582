import mixpanel from "mixpanel-browser";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Common/Loader";
import RightWorks from "../Common/RightWorks";
import ToastMsg from "../Common/ToastMsg";
import { MyContext } from "../MyContext";
import mateImage from "../assets/material-i.png";
import phonepe from "../assets/phonpeImage.png";
import tick from "../assets/teenyicons.png";
import bhimOld from "../assets/upiID.png";
import {
  phonpeSubscription,
  suspendSubscription,
  validateVpa,
} from "../services/ApiServices";

const constantUpi = [
  {
    text: "@ybl",
  },
  {
    text: "@okhdfcbank",
  },
  {
    text: "@okaxis",
  },
  {
    text: "@okicici",
  },
];

mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
  debug: true,
});
console.log(window.navigator.platform, "platform");

export default function Subscription() {
  const [otherState, setOtherState] = useState(false);
  const { languageData, language, isApiHit, setIsApiHit } =
    useContext(MyContext);
  const [selectedUpi, setSelectedUpi] = useState(
    (localStorage.getItem("phonpe_enable") === "true" ||
      window.innerWidth <= 991) &&
      localStorage.getItem("phonpe_enable") !== "false"
      ? "phonepe"
      : "other"
  );
  const [verified, setVerified] = useState("not-v");
  const [responseData, setResponseData] = useState({});
  const [errors, setErrors] = useState(true);
  //const [dataSubs ,setDataSubs] = useState([dataSubs])
  const [inputValue, setInputValue] = useState("");
  const [loader, setLoader] = useState(false);
  const [clickedUpi, setClickedUpi] = useState("");
  const [someValue, setSomeValue] = useState("");
  const [validateData, setValidateData] = useState("");
  const dataSubs = [
    {
      title: !languageData
        ? "Enter your UPI ID to setup autopay."
        : languageData?.Enter_your_UPI_ID_to_setup_autopay,
      describe: !languageData
        ? "Enter your UPI ID to setup autopay."
        : languageData?.Enter_your_UPI_ID_to_setup_autopay,
    },
    {
      title: !languageData
        ? "Approve the auto debit request sent by your UPI handle and enter UPI PIN to setup."
        : languageData?.Approve_the_auto_debit_request_sent_by_your_UPI_handle_and_enter_UPI_PIN_to_setup,
      describe: !languageData
        ? "Approve the auto debit request sent by your UPI handle and enter UPI PIN to setup."
        : languageData?.Approve_the_auto_debit_request_sent_by_your_UPI_handle_and_enter_UPI_PIN_to_setup,
    },
  ];
  const [isBack, setIsBack] = useState(false);
  const handleEvent = () => {
    setIsBack(true);
    //alert("scevcer")
    //setIsApiHit(true)
  };

  console.log("isback", isBack, isApiHit);
  useEffect(() => {
    window.addEventListener("popstate", handleEvent);
    //console.log("running")
    return () => window.removeEventListener("popstate", handleEvent);
  }, []);
  const [finishStatus, setfinishStatus] = useState(false);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Do you want to go back ?")) {
        setfinishStatus(true);
        // your logic
        suspendSubscription()
          .then((res) => {
            window.location.href = "/select-subscription";
          })
          .catch((err) => {
            console.log(err, "error");
          });
      } else {
        //window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };
  console.log(finishStatus, "finish");

  useEffect(() => {
      console.log(window.innerWidth, "innerWidth");

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);
  const fnUpi = (someValue) => {
    setInputValue((prev) => prev + `${someValue}`);
    fnUpiCheck(inputValue + `${someValue}`);
  };
  const fnCall = (e) => {
    //console.log(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)+$/.test(e), "R")
    setVerified("not-v");
    if (e.length < 50) {
      if (e.includes(" ")) {
        const someValue = e.replace(/@([a-zA-Z0-9._-]*)[^a-zA-Z]*$/g, "@$1");
        setInputValue(someValue.trim(-1));
      } else {
        const someValue = e.replace(/@([a-zA-Z0-9._-]*)[^a-zA-Z]*$/g, "@$1");
        //fnUpiCheck(someValue)
        setInputValue(someValue);
      }
    }

    //setInputValue(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(e)? e:e.trim(-1))
    // if(e.includes('@')){
    // fnUpiCheck(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)+$/.test(e)? e:e.trim(-1))
    // }
  };
  const suspend = () => {};
  const navigate = useNavigate();
  const fnUpiCheck = (e) => {
    validateVpa({ vpa: e })
      .then((res) => {
        console.log(res.status, "response");
        localStorage.setItem("vpa", inputValue);
        setValidateData(res.message);
        if (res.status === "success") {
          setVerified("v");
          setErrors(false);
        }
        console.log("res");
      })
      .catch((err) => {
        setVerified("not-v");
        setErrors(true);
      });
  };
  const validate = () => {
    mixpanel.track("Interacted_Subscription_Method", {
      "Clicked on": "Proceed",
      status: true,
      Platform: localStorage.getItem("platform"),
      Source: localStorage.getItem("openFrom"),
      userType: localStorage.getItem("userType"),
    });

    //if (errors === false) {
    setLoader(true);
    let dataPhone = {
      is_intent: true,
      subscription_type: localStorage.getItem("subscription"),
      device_os:
        localStorage.getItem("os") === "A"
          ? "android"
          : localStorage.getItem("os") === "I"
          ? "ios"
          : localStorage.getItem("os") === "null"
          ? String(window.navigator.platform).charAt(0) === 'i'
          ? "ios":"android":"android",
      version_code: localStorage.getItem("phonepe_version_code") === 'null' ? 1 : localStorage.getItem("phonepe_version_code"),
    };
    let dataUpi = {
      vpa: inputValue,
      subscription_type: localStorage.getItem("subscription"),
    };
    phonpeSubscription(selectedUpi === "other" ? dataUpi : dataPhone)
      .then((res) => {
        setLoader(false);
        localStorage.setItem("selected", selectedUpi)
        //console.log(res.message.url, "response1")
        //navigate(res.message.url)
        //setDataSubs(res?.data)
        //setFunctionState(res.data.code === "SUCCESS" ? "" :"retry")
        if (res.status === "success") {
          if (selectedUpi === "other") {
            navigate("/Autopay");
          } else {
            window.location.href = res.message.url;
            navigate("/autopay");
          }
          //suspend()
          //navigate("/Autopay");
        }
      })
      .catch((res) => {
        setLoader(false);
        ToastMsg(res?.response.data.error.message, "data");
      });
    //}
  };
  const [value, setValue] = useState("");
  mixpanel.track("View_Subscription_Method", {
    status: true,
    Platform: localStorage.getItem("platform"),
    Source: localStorage.getItem("openFrom") ,
    userType: localStorage.getItem("userType"),
  });

  useEffect(() => {
    console.log(localStorage.getItem('os') === "null", "os")
    const delayDebounceFn = setTimeout(() => {
      // Make API request with the current value
      //fnCall(inputValue);
      if (inputValue.includes("@")) {
        const someValue = inputValue.replace(
          /@([a-zA-Z0-9._-]*)[^a-zA-Z]*$/g,
          "@$1"
        );
        // upi check debounce
        setSomeValue(someValue);
        //fnUpiCheck(inputValue)
      }
      console.log(value);
    }, 0);
    return () => clearTimeout(delayDebounceFn);
  }, [inputValue]);

  useEffect(() => {
    if (localStorage.getItem("vpa")) {
      setInputValue(localStorage.getItem("vpa"));
    }
  }, []);

  const handleChange = (event) => {
    setVerified("");
    fnCall(event.target.value);
    //setInputValue(event.target.value);
  };

  return (
    <div>
      {loader ? (
        <>
          <Loader />
        </>
      ) : (
        <></>
      )}

      <div className="container someCenter">
        <div className="heading flex space-between align-center mb-10 subs-padd">
          <h1>
            {!languageData ? (
              <>Subscription Plan</>
            ) : (
              languageData?.subscription_plan
            )}
          </h1>
        </div>
        <div className="textI mt-20">
          <img width={20} alt="mate" src={mateImage} height={20} />
          <p className="pI">
            {!languageData ? <>Amount of</> : <></>}
            {!languageData ? (
              <>
                &nbsp;
                <span className="rupeSpan">
                  ₹
                  {localStorage.getItem("subscription") === "MONTHLY"
                    ? "29"
                    : "149"}
                </span>
                &nbsp;
              </>
            ) : (
              <></>
            )}

            {!languageData ? (
              <>
                will be debited
                {localStorage.getItem("subscription") === "MONTHLY"
                  ? " monthly "
                  : " annually "}
                for your Credit Assist subscription plan.
              </>
            ) : (
              <></>
            )}
            {/* Amount of<span className="rupeSpan">₹199</span> will be debited
            annually for your Credit Assist subscription plan. */}
            {!languageData ? <></> : <>{languageData.plan_part}</>}
          </p>
        </div>
        <p className="subscribe mt-20 mb-20">
          {!languageData ? (
            <>How it Works?</>
          ) : (
            `${languageData?.how_it_works}?`
          )}
        </p>
        <RightWorks appData={dataSubs} typeRight={false} descSize={"16"} />
        {/* <div>
            
          <img src={notChange} width={360} height={201} />
        </div> */}
        <div className="methodDiv mt-10">
          <p>
            {!languageData ? (
              <>Select preferred method for autopay</>
            ) : (
              languageData?.upi_method
            )}
            {/* Select perferred method for autopay */}
          </p>
          <div className="divOut">
            {(localStorage.getItem("phonpe_enable") === "true" ||
              window.innerWidth <= 991) &&
            localStorage.getItem("phonpe_enable") !== "false" ? (
              <div
                onClick={() => {
                  mixpanel.track("Interacted_Subscription_Method", {
                    "Clicked on": "PhonePe ",
                    status: true,
                    Platform: localStorage.getItem("platform"),
                    Source: localStorage.getItem("openFrom"),
                    userType: localStorage.getItem("userType"),
                  });
                  setSelectedUpi("phonepe");
                }}
                className="phonepeSpan"
              >
                {}
                <input
                  checked={selectedUpi === "phonepe"}
                  type="radio"
                  className="inputRadio"
                />
                <div className="phonepeText">PhonePe</div>
                <div>
                  <img width={22} height={22} src={phonepe} alt="imgnotfound" />
                </div>
              </div>
            ) : (
              <div className="mt-10"></div>
            )}
            {/* <div
              onClick={() => {
                mixpanel.track("Interacted_Subscription_Method", {"Clicked on":"Bhim UPI",status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType")})
                setSelectedUpi("other");
              }}
              className="select-radio"
            >
              <input
                checked={selectedUpi === "bhim"}
                type="radio"
                className="inputRadio"
              />
              <div>
                <img width={103} height={24} src={bhim} alt="imgnotfound" />
              </div>
            </div> */}
            <div
              onClick={() => {
                setSelectedUpi("other");
              }}
              className="select-radio"
            >
              <div className="insideDiv">
                <div className="phonepeText">
                  <input
                    onClick={(e) => {
                      mixpanel.track("Interacted_Subscription_Method", {
                        "Clicked on": "Enter UPI ",
                        status: true,
                        Platform: localStorage.getItem("platform"),
                        Source: localStorage.getItem("openFrom"),
                        userType: localStorage.getItem("userType"),
                      });
                    }}
                    checked={selectedUpi === "other"}
                    type="radio"
                    className="inputRadio"
                  />
                  Enter UPI ID
                </div>
                {selectedUpi === "other" && (
                  <>
                    <div className="upiOtherText">
                      Avoid using UPI ID linked with <p>Paytm Payments Bank</p>{" "}
                    </div>
                    <>
                      <div className="">
                        <img
                          className="bhimUpi"
                          height={36}
                          width={36}
                          src={bhimOld}
                          alt="bhim"
                        />
                        <input
                          placeholder="Ramesh1234@icici"
                          onChange={(e) => {
                            handleChange(e);
                            //fnCall(e.target.value);
                            //'setInputValue(e);
                          }}
                          value={inputValue}
                          height={60}
                          className={
                            selectedUpi === ""
                              ? "upiInput"
                              : "upiInput borderSolid"
                          }
                        />
                        {!(verified === "v" && inputValue.includes("@")) && (
                          <span
                            onClick={() => {
                              fnUpiCheck(someValue);
                            }}
                            className="phonepeVerify"
                          >
                            Verify
                          </span>
                        )}
                      </div>
                      <div className="bankDIv">
                        {constantUpi.map((some, index) => {
                          return (
                            <p
                              key={index}
                              onClick={() => {
                                fnUpi(some?.text);
                                setClickedUpi(some?.text);
                              }}
                              className={
                                clickedUpi !== some.text
                                  ? "someBnk"
                                  : "someBnkClick"
                              }
                            >
                              {some.text}
                            </p>
                          );
                        })}
                      </div>
                    </>
                  </>
                )}

                {selectedUpi === "other" && verified && (
                  <div
                    className="upperCover"
                    style={{
                      position: "relative",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {verified === "v" && inputValue.includes("@") && (
                      <>
                        {" "}
                        <p className="pCover">
                          <span className="greenText">{validateData.name}</span>
                        </p>
                        <p className="pCover">
                          <img
                            style={{ height: "15px", width: "15px" }}
                            src={tick}
                            alt=""
                          />{" "}
                          <span className="greenText">Verified</span>
                        </p>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={"button-wrapper text-center mt-10"}>
          <button
            onClick={() => {
              validate();
            }}
            //disabled={(!inputValue.includes('@') || errors || verified === 'not-v') && selectedUpi === 'other' }
            disabled={
              (!inputValue.includes("@") || errors || verified === "not-v") &&
              selectedUpi === "other"
                ? true
                : false
            }
            className={
              (!inputValue.includes("@") || errors || verified === "not-v") &&
              selectedUpi === "other"
                ? "btn-disabled mb-30"
                : "btn mb-30"
            }
          >
            {!languageData ? <>Proceed</> : languageData.proceed}
          </button>
        </div>
      </div>
    </div>
  );
}
