import React, { useContext } from 'react';
import assistant from "../assets/assistant.png";
import { MyContext } from '../MyContext';

const Assistant = ({ass_amount, submitPayment}) => {
  const {languageData} = useContext(MyContext)
  return (
    <>
        <div className='assistant flex align-center space-between'>
            <div className='assistant-lft'>
                <img src={assistant} alt='assistant' width={115} height={115} />
            </div>
            <div className='assistant-rgt'>
                <h3>{languageData?.Need_More_Assistance||"Need More Assistance"}?</h3>
                <span>{languageData?.Get_in_touch_with_our1|| "Get in touch with our"}<br/>{languageData?.Get_in_touch_with_our2|| "Credit Expert Now"}!</span>
                <button className='pay-more' onClick={()=>{submitPayment(ass_amount)}}>{languageData?.Pay_499?`${languageData?.Pay_499}`: `Pay ₹${ass_amount}`}</button>
            </div>
        </div>
    </>
  )
}

export default Assistant