import React, {useContext, useEffect} from 'react';
import LatestReport from '../Common/LatestReport';
import backtohome from "../assets/Backtohome.png";
import mixpanel from 'mixpanel-browser';
import { MyContext } from '../MyContext';
mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
    debug: true,
});


const CompareReport = () => {
  
  const {languageData,language} = useContext(MyContext)
  useEffect(() => {
    mixpanel.track("Viewed_Compared_Report", { "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
  }, []);
  return (
    <>
        <div className='container'>
          <div className="heading flex space-between align-center mb-50">
            <h1>{languageData?.compare_report|| "Compare Reports"}</h1>
            <a href="/">
              <img src={backtohome} alt="backtohome" width={40} height={40} />
            </a>
          </div>
            <LatestReport type="bar"/>
        </div>
    </>
  )
}

export default CompareReport