import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../Common/Loader';
import DatePicker from "react-datepicker"
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css"
import Header from '../Common/Header';
import detail from "../assets/detail-image.png"
import calander from "../assets/calander-icon.png"
import { getCibilReportEquifax, getCibilReportForm } from '../services/ApiServices';
import ToastMsg from "../Common/ToastMsg";
import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
  debug: true,
});

const UserDetails = () => {
  const [loader, setLoader] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate()
  const [fname, setFname] = useState("")
  const [lname, setLname] = useState("")
  const [panNo, setPanNo] = useState("")
  const [dateOfBirth, setDateOfBirth] = useState(new Date())
  const [gender, setGender] = useState("")
  const [pincode, setPincode] = useState("")
  const [userState, setUserState] = useState("")
  const [city, setCity] = useState("")
  const [address, setAddress] = useState("")
  const [error, setError] = useState();
  useEffect(() => {
    mixpanel.track("Viewed_Basic_Details", {status:true})
  }, []);
  const handleCibilReportForm = () => {
    const data = {
        first_name : fname,
        last_name : lname, 
        pan_card_number : panNo,
        date_of_birth : moment(dateOfBirth).format("YYYY-MM-DD"), 
        gender : gender, 
        pincode : pincode,
        state : userState, 
        city : city, 
        address : address
    };
    mixpanel.track("Interacted_Basic_Details", {status:true, "Name": fname, "Pan": panNo, "DOB": moment(dateOfBirth).format("YYYY-MM-DD"), "State": userState, "Gender":gender, "City": city})
    getCibilReportForm(data)
    .then((res)=>{
        console.log("newUser",res)
        if (res?.status === 200) {
            handleCibilReportEquifax()
        }
    }).catch((err)=>{
          setLoader(false)
      if (err?.response?.status === 401) {
        window.location.href = "/";
        localStorage.clear();
      }
      let error = err?.response?.data?.error || "something went wrong";
      ToastMsg(error, "error");
    })
  }
  const handleCibilReportEquifax = () => {
    getCibilReportEquifax()
    .then((res)=>{
        console.log("cibilReportHit",res)
        window.location.href = "/dashboard";
    }).catch((err)=>{
        setLoader(false)
        window.location.href = "/cibil-information-unavailable";
      if (err?.response?.status === 401) {
        window.location.href = "/cibil-information-unavailable";
        localStorage.clear();
      }
    })
  }
  return (
    <>
        {loader ? <Loader /> : null}
        <div className='dashboard user-detail'>
            <div className='container container-sm'>
                <Header/>
                <h1 className='mb-40'>Basic Details (as per PAN Card)</h1>
                <div className='user-detail-form flex align-start space-between'>
                    <div className='col'>
                        <div className="input-wrapper">
                            <input
                                className="input"
                                type="text"
                                placeholder=" "
                                value={fname}
                                name="f_name"
                                onChange={(e)=>{
                                    setFname(e.target.value);
                                    setError({...error,name:""})
                                }}
                                onBlur={(e)=>{
                                    if(!e.target.value.match(/^([a-zA-Z]+[a-zA-Z\s]+)$/)){
                                        setError({...error, name:"Enter Valid First Name"})
                                    }
                                }}
                            />
                            <label className="label">First Name</label>
                            <p className='error'>
                                {error?.name}
                            </p>
                        </div>
                        <div className="input-wrapper">
                            <input
                                className="input"
                                type="text"
                                placeholder=" "
                                value={lname}
                                name="l_name"
                                onChange={(e)=>{
                                    setLname(e.target.value);
                                    setError({...error,lname:""})
                                }}
                                onBlur={(e)=>{
                                    if(!e.target.value.match(/^([a-zA-Z]+[a-zA-Z\s]+)$/)){
                                        setError({...error,lname:"Enter Valid Last Name"})
                                    }
                                }}
                            />
                            <label className="label">Last Name</label>
                            <p className='error'>
                                {error?.lname}
                            </p>
                        </div>
                        <div className="input-wrapper">
                            <input
                                className="input"
                                type="text"
                                placeholder=" "
                                value={panNo}
                                name="pan_no"
                                onChange={(e)=>{
                                    setPanNo(e.target.value.toUpperCase());
                                    setError({...error,pan:""})
                                }}
                                onBlur={(e)=>{
                                    if(!e.target.value.match(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/)){
                                        setError({...error, pan:"Enter Valid PAN Card Number"})
                                    }
                                }}
                                style={{textTransform:"uppercase"}}
                            />
                            <label className="label">Enter PAN Card Number</label>
                            <p className='error'>
                                {error?.pan}
                            </p>
                        </div>
                        <div className="input-wrapper input-date">
                            <DatePicker value={dateOfBirth} placeholder=" " selected={dateOfBirth} onChange={(value) =>{setDateOfBirth(value)}} date={dateOfBirth} showMonthDropdown showYearDropdown peekNextMonth dropdownMode='select' maxDate={new Date('2015-01-01')} minDate={new Date('1900-01-01')}/>
                            <label className="label" style={{top: "-5px",fontSize: "10px",padding: "0"}}>Date of Birth</label>
                            <img src={calander} alt='calander' width={23} height={23} />
                        </div>
                        <div className="input-wrapper">
                        <select name="marital" id="marital" value={gender} onChange={(e)=>setGender(e.target.value)}>
                            <option value="">Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select>
                            <label className="label" style={{top: "-5px",fontSize: "10px",padding: "0"}}>Gender</label>
                        </div>
                        <div className="input-wrapper">
                            <input
                                className="input"
                                type="text"
                                placeholder=" "
                                value={pincode}
                                name="pincode"
                                onChange={(e)=>{
                                    setPincode(e.target.value.slice(0, 6));
                                    setError({...error,pin:""})
                                }}
                                onBlur={(e)=>{
                                    if(!e.target.value.match(/^[0-9]+$/)){
                                        setError({...error, pin:"Enter Valid Pincode"})
                                    }
                                }}
                            />
                            <label className="label">Pincode</label>
                            <p className='error'>
                                {error?.pin}
                            </p>
                        </div>
                        <div className="input-wrapper">
                            <input
                                className="input"
                                type="text"
                                placeholder=" "
                                value={userState}
                                name="state"
                                onChange={(e)=>{
                                    setUserState(e.target.value);
                                    setError({...error,userstate:""})
                                }}
                                onBlur={(e)=>{
                                    if(!e.target.value.match(/^([a-zA-Z]+[a-zA-Z\s]+)$/)){
                                        setError({...error, userstate:"Enter Valid State Name"})
                                    }
                                }}
                            />
                            <label className="label">State</label>
                            <p className='error'>
                                {error?.userstate}
                            </p>
                        </div>
                        <div className="input-wrapper">
                            <input
                                className="input"
                                type="text"
                                placeholder=" "
                                value={city}
                                name="city"
                                onChange={(e)=>{
                                    setCity(e.target.value);
                                    setError({...error,city:""})
                                }}
                                onBlur={(e)=>{
                                    if(!e.target.value.match(/^([a-zA-Z]+[a-zA-Z\s]+)$/)){
                                        setError({...error, city:"Enter Valid City Name"})
                                    }
                                }}
                            />
                            <label className="label">City</label>
                            <p className='error'>
                                {error?.city}
                            </p>
                        </div>
                        <div className="input-wrapper">
                            <input
                                className="input"
                                type="text"
                                placeholder=" "
                                value={address}
                                name="address"
                                onChange={(e)=>{
                                    setAddress(e.target.value);
                                    setError({...error,add:""})
                                }}
                                onBlur={(e)=>{
                                    if(!e.target.value){
                                        setError({...error, add:"Enter Valid Address Name"})
                                    }
                                }}
                            />
                            <label className="label">Address</label>
                            <p className='error'>
                                {error?.add}
                            </p>
                        </div>
                        <label className="main-check">By entering your PAN Number, you give consent to the lender/lending partners on the PayMe app to retrieve your records for CKYC, access your CIBIL profile and conduct credit checks to assign your credit limit.
                            <input type="checkbox" name="agree" id="mycheckbox" checked={isChecked} onChange={() => setIsChecked(!isChecked)}/>
                            <span className="geekmark"></span>
                        </label>
                        <div className='button-wrapper text-center w-100'>
                            <button 
                            className='btn' 
                            onClick={handleCibilReportForm} 
                            disabled={!(
                                fname.length>2 
                                && fname.match(/^[a-z ,.'-]+$/i)
                                && lname.length>2 
                                && lname.match(/^[a-z ,.'-]+$/i)
                                && panNo 
                                && panNo.match(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/)
                                && dateOfBirth 
                                && gender 
                                && pincode 
                                && pincode.match(/^[0-9]+$/)
                                && userState.length>1 
                                && userState.match(/^[a-z ,.'-]+$/i)
                                && city.length>2
                                && city.match(/^[a-z ,.'-]+$/i)
                                && address.length>3
                                && address
                                && isChecked
                                )}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                    <div className='col'>
                        <img src={detail} alt='detailimage' width={503} height={474} />
                    </div>
                </div>
            </div>
        </div>

    </>
  )
}

export default UserDetails
