import React, {useContext, useState, useEffect} from 'react';
import { MyContext } from '../MyContext';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import poor_img from "../assets/poor-image.png";
import fair_img from "../assets/fair-image.png";
import good_img from "../assets/good-image.png";
import excellent_img from "../assets/excellent-image.png";
import yellow_bulb from "../assets/yellow-bulb.png";
import scoreImg from "../assets/score-pop.png";
import scoreFrame from "../assets/score-frame.png";
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
    debug: true,
});

const LessScore = ({score,last_update, sentence}) => {
    const {languageData} = useContext(MyContext)
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    const [openFrom, setOpenFrom] = useState();
    const handleFeedback = () => {
        setOpen(o => !o)
        mixpanel.track("Interacted_Cibil_Info", { "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
    }  
    useEffect(() => {
        const OpenFrom = new URLSearchParams(window.location.search).get(
          "open_from"
        );
        setOpenFrom(OpenFrom);
      }, [openFrom]);
  return (
    <>
        <div className='premium-sc less_score'>
            <p className='less-top-text flex align-center text-center mb-20'>{languageData?.Lets_turn_this || "Let's turn this"} <span>{languageData?.loan_rejection || "loan rejection"}</span> {languageData?.into_an || "into an"} <span>{languageData?.opportunity || "opportunity!"} <img src={yellow_bulb} alt='yellow-bulb' width={15} height={15}/></span></p>
            <h3 className='mb-10 text-center'>{languageData?.your_credit_score || "Your Credit Score"}</h3>
            <div className='flex align-center space-between image-sec'>
                <div className='score_sc'>  
                    <div className='button-wrapper flex align-center'>
                        <h1 className='scorre'>{score}</h1>
                        <img src={scoreImg} alt='score' width={19} height={19} onClick={() => handleFeedback()}/>
                    </div>
                    <p className='last_update last_update_score'>{languageData?.Last_Updated||"Last update "} {" : "}{last_update? moment(last_update).format("DD-MM-YYYY"): "DD-MM-YYYY"}</p>
                </div>
                <div className='score_image'>
                    {sentence==="excellent" ? ( <img src={excellent_img} alt="Loan Type" width={146} height={74}/> ) : 
                    sentence==="good" ? ( <img src={good_img} alt="Loan Type" width={146} height={74} /> ) :
                    sentence==="fair" ? ( <img src={fair_img} alt="Loan Type" width={146} height={74} /> ) :
                    (<img src={poor_img} alt="Loan Type" width={146} height={74} />)
                    }
                </div>
            </div>
                {languageData === false ? 
                    <p className='less-top-text less-top-text-2 text-center mt-40'>Elevate your credit score with our <br/><span>Personalized Guidance.</span><span></span></p>
                    :
                    <p className='less-top-text less-top-text-2 text-center mt-40'>{languageData?.your }<span>{languageData?.Personalized_Guidance}</span><span></span>{languageData?.Elevate_your_credit_score_with_our}</p>
                }
            <p className='less-top-text less-top-text-3 text-center'>{languageData?.Make_Loan || "Make Loan"} <span></span><span>{languageData?.Approvals || "Approvals "}</span>{languageData?.a_reality || "a reality"}!</p>
        </div>
        <Popup open={open} closeOnDocumentClick onClose={closeModal} className='feedback-popup-main'>
            <div className='feedback-popup'>
                <h3>{languageData?.Good_Credit_Score||"Good Credit Score"}</h3>
                <p className='mb-20 mt-10'>{ languageData?.A_Score_above_700_will_help_you||"A score above 700 will help you get the best offers on loans and credit cards."}</p>
                <p className='mb-20'>{languageData?.Credit_Score_Range|| "Credit Score Range"}</p>
                <img src={scoreFrame} alt='scoreFrame' width={333} height={78} className='mb-50'/>
                <div className='button-wrapper text-center'>
                    <button className='btn btn-white' onClick={closeModal} style={{maxWidth:"160px"}}>{languageData?.Okay||"Okay"}</button>
                </div>
            </div>
        </Popup>
    </>
  )
}

export default LessScore