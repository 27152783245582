import mixpanel from "mixpanel-browser";
import React, { useContext, useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useNavigate } from "react-router-dom";
import RightWorks from "../Common/RightWorks";
import { MyContext } from "../MyContext";
import imageSand from "../assets/sandTimer.png";
import failPay from "../assets/warning.png";
import { getMandateStatus, suspendSubscription } from "../services/ApiServices";

mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
  debug: true,
});
const Timer = (props) => {
  const {time, setCurrentTime=()=>{}} = props
  const [timeRemaining, setTimeRemaining] = useState(5 * 60 - 1);
  useEffect(() => {
    if (timeRemaining > 0) {
      setTimeout(() => {
        setCurrentTime(timeRemaining-1)
        setTimeRemaining(timeRemaining - 1);
       
      }, 1000);
    } else if (timeRemaining === 0) {
      //setTimeRemaining(5 * 60 - 1);
    }

    //setTimeRemaining(timeRemaining-1)
  }, [timeRemaining]);
  return (
    <>
      <p className="timeWatch">
        {Math.floor(timeRemaining / 60).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
        :
        {Math.round(timeRemaining % 60).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
      </p>
    </>
  );
};

function AutoPay() {
  const [currentTime, setCurrentTime] = useState(5*60-1)
  const { languageData, setIsApiHit, isApiHit } = useContext(MyContext);
  const [timerStateValue, setTimerStateValue] = useState(0);
  const [apiCalling, setApiCalling] = useState(true);
  const navigate = useNavigate();
  const [dataSubs, setDataSubs] = useState({});
  const [functionState, setFunctionState] = useState("timer");
 const [isBack, setIsBack] = useState(false);
  const handleEvent = () => {
    setIsBack(true);
    //alert("scevcer")
    //setIsApiHit(true)
  };
  

  console.log("isback", isBack, isApiHit)
  useEffect(() => {
    window.addEventListener("popstate", handleEvent);
    //console.log("running")
    return () => window.removeEventListener("popstate", handleEvent);
  },[]);

  const [finishStatus, setfinishStatus] = useState(false);

 const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
         if (window.confirm("Do you want to go back ?")) {
             setfinishStatus(true)
             // your logic
             suspendSubscription().then((res)=>{
              navigate("/select-subscription")
             }).catch((err)=>{})
             
             //window.location.href="/"
         } else {
             //window.history.pushState(null, null, window.location.pathname);
             setfinishStatus(false)
         }
     }
 }
 console.log(finishStatus, "finish")


  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
     window.addEventListener('popstate', onBackButtonEvent);
     return () => {
       window.removeEventListener('popstate', onBackButtonEvent);  
    };
   }, []);

  useEffect(() => {

    //fnApi()
    if(functionState === 'timer'){
        // mixpanel.track("Viewed_Autopay_Pending", {status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })

    }
    else if(functionState === 'retry'){
      //mixpanel.track("Viewed_Autopay_Mandate_Failed", {"Amount":localStorage.getItem('subscription') === "MONTHLY"?"29":"199",status: true, "Platform": localStorage.getItem("platform"),  "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType")})

    }

    console.log(timerStateValue, "time")
    if (timerStateValue >= 0 ) {
      setTimeout(() => {
        if (apiCalling) {
          fnApi();
        }
        setTimerStateValue((prev) => prev + 6);
      }, 6000);
    }
    
    else{
      //fnApi();
      ///setTimerStateValue()
      //setFunctionState('time-fail')
    }
  }, [timerStateValue]);

  // useEffect(()=>{
  //   getSubs().then((res)=>{
  //     if(functionState === 'timer'){
  //       mixpanel.track("Viewed_Autopay_Pending", {status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })

  //   }
  //   else if(functionState === 'retry'){
  //       mixpanel.track("Viewed_Autopay_Mandate_Failed", {"Amount":res?.message.subscription_type === "MONTHLY"?"29":"199", "Plan":res.message.subscription_type,status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
  //   }
  //   }).catch(()=>{})
  // },[])

  
  const appData = [
    {
      title: !languageData
        ? "Open your UPI app"
        : languageData?.Open_your_UPI_app,
      describe: !languageData
        ? "Autopay request should appear"
        : languageData?.Autopay_request_should_appear,
    },
    {
      title: !languageData
        ? "Approve the Autopay from Payme India"
        : languageData?.Approve_the_Autopay_from_Payme_India,
      describe: !languageData
        ? "If you don’t see it, complete from Autopay-Pending section"
        : languageData?.If_you_don_t_see_it_complete_from_Autopay_Pending_section,
    },
    {
      title: !languageData
        ? "Come back to Payme App"
        : languageData?.Come_back_to_Payme_App,
      describe: !languageData
        ? "Return to this page to complete process."
        : languageData?.Return_to_this_page_to_complete_process,
    },
  ];
  const fnApi = () => {
    getMandateStatus()
      .then((res) => {
        console.log("res", res?.message.user.state, res);
        if (res?.message.state === "PAYTM") {
          setApiCalling(false);
          setFunctionState("paytm-retry");
        } 
         else if (res?.message.pay_response_code === "REQUEST_TIME_OUT" || res?.message.pay_response_code === 'INTENT_EXPIRED') {
          setFunctionState("time-fail");
        }
        else if (res?.message.state === "FAILED" && res?.message.pay_response_code !== "REQUEST_TIME_OUT" && res?.message.pay_response_code !== 'INTENT_EXPIRED') {
          setApiCalling(false);
          setFunctionState("retry");
          mixpanel.track("Viewed_Autopay_Mandate_Failed", {"Amount":localStorage.getItem('subscription') === "MONTHLY"?"29":"199",status: true, "Platform": localStorage.getItem("platform"),  "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType")})
        } 
       
        else if (res?.message.state === "COMPLETED") {
          navigate("/success-autopay");
        } else {
        }
      })
      .catch((err) => {});
  };
  const fnFunctionFail = ()=>{
            mixpanel.track("Interacted_Autopay_Mandate_Failed", {"Clicked on":"Retry",status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
             navigate("/subscription");
  }

  //     {  "id": 1,
  //     "submit_auth": true,
  //     "created_at": "2024-02-28T11:04:31.988432",
  //     "modified_at": "2024-02-28T11:04:31.988467",
  //     "auth_request_id": "TX90984718150eda8776",
  //     "type": "UPI_COLLECT",
  //     "deduction_amount": 1000,
  //     "code": "SUCCESS",
  //     "is_mandate_actioned": false,
  //     "user": 1,
  //     "vpa": 1
  // }
  return (
    <div>
      <div className="container someCenter">
        <div className="heading flex space-between align-center mb-54">
          <h1 className="subs-padd">
            {!languageData ? (
              <>Complete Autopay Mandate</>
            ) : (
              languageData?.Complete_Autopay_Mandate
            )}
          </h1>
        </div>
        <div className="">
          <RightWorks appData={appData} typeRight={true} />
          <p className="line someLine"></p>
          {localStorage.getItem("selected") === "other" ? (
            <div className="upiIdT">UPI ID: {localStorage.getItem("vpa")}</div>
          ) : (
            <div className="upiIdT">Ensure the phonepay app is installed or proceed with enter UPI</div>
          )}
          <div
            className={
              functionState === "retry" ? "timerCircle pt-40" : "timerCircle"
            }
          >
            {functionState === "timer" && (
              <>
                <CountdownCircleTimer
                  size={87}
                  strokeWidth={3}
                  isPlaying
                  duration={5 * 60 - 1}
                  colors="#fc6603"
                >
                  {Timer}
                </CountdownCircleTimer>
                <p className="textCountdown">
                  {!languageData ? (
                    <>
                      Do not cancel, refresh or go back until process is
                      completed.
                    </>
                  ) : (
                    languageData?.Do_not_cancel_refresh_or_go_back_until_process_is_completed
                  )}
                </p>
              </>
            )}
            {functionState === "sand" && (
              <>
                <img src={imageSand} height={100} width={100} />
                <p className="textCountdown">
                  Please Wait, We are confirming your payment status.
                </p>
              </>
            )}
            {functionState === "time-fail" && (
              <>
                <img src={failPay} alt="failed" width={60} height={60} />
                <h5 className="failText">
                  {/* {"Paytm Payments Bank is not supported!"} */}
                  {/* <br /> ₹{cibilString?.balance} */}
                </h5>
                <p className="paytmText">
                  {!languageData ? (
                    <>
                      Try an alternate UPI as it's taking longer than usual to
                      process. Rest assured, we'll refund if the payment goes
                      through..
                    </>
                  ) : (
                    languageData?.Please_try_again_with_different_UPI_ID
                  )}
                </p>

                <div className="button-wrapper text-center mt-40">
                  <button
                    onClick={() => {
                      navigate("/subscription");
                    }}
                    className="btn btnfail mb-30 mt-20"
                  >
                    {!languageData ? <>Retry</> : languageData?.Retry}
                  </button>
                </div>
              </>
            )}
            {functionState === "paytm-retry" && (
              <>
                <img src={failPay} alt="failed" width={60} height={60} />
                <h5 className="failText">
                  {"Paytm Payments Bank is not supported!"}
                  {/* <br /> ₹{cibilString?.balance} */}
                </h5>
                <p className="paytmText">
                  {!languageData ? (
                    <>Please try again with different UPI ID.</>
                  ) : (
                    languageData?.Please_try_again_with_different_UPI_ID
                  )}
                </p>

                <div className="button-wrapper text-center mt-40">
                  <button
                    onClick={() => {
                      navigate("/subscription");
                    }}
                    className="btn btnfail mb-30 mt-20"
                  >
                    {!languageData ? <>Retry</> : languageData?.Retry}
                  </button>
                </div>
              </>
            )}
            {functionState === "retry" && (
              <>
                <img src={failPay} alt="failed" width={60} height={60} />
                <h5 className="failText">
                  {!languageData ? (
                    <>{"Autopay Mandate Failed"}</>
                  ) : (
                    languageData?.Autopay_Mandate_Failed
                  )}
                  {/* <br /> ₹{cibilString?.balance} */}
                </h5>
                <div className="button-wrapper text-center mt-40">
                  <button
                    onClick={() => {
                      fnFunctionFail();
                    }}
                    className="btn btnfail mb-30 mt-20"
                  >
                    {!languageData ? <>Retry</> : languageData?.Retry}
                  </button>
                </div>
              </>
            )}
          </div>

          {/* <img src={autoPay} /> */}
        </div>
      </div>
    </div>
  );
}

export default AutoPay;
