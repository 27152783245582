import React, { useContext } from "react";
import { Spinner } from "react-bootstrap";
import { MyContext } from "../MyContext";
import loaderImage from "../assets/new-loader.gif"


//Whole page loader
export const Loader = (props) => {

  const {languageData} = useContext(MyContext)
  return (
    <div className="loader_wrapper textclr">
      {" "}
      {/* <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div> */}
      <div className="lds-roller">
        <img src={loaderImage} alt="loaderImage" width={250} height={230} />
      </div>
      <div className="loader-text">{!languageData?"Loading...":"कृपया प्रतीक्षा करें..."}</div>
    </div>
  );
};

//Button spinner
export const BtnSpinner = () => {
  return (
    <>
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
      <span>Loading...</span>
    </>
  );
};