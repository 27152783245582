import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import Nodata from "../assets/nodata.png";
import hdfc from "../assets/HDFCC.png";
import sbi from "../assets/SBI.png";
import bob from "../assets/BOB.png";
import axis from "../assets/Axis.png";
import icici from "../assets/ICICI.png";
import kotak from "../assets/KOTAK.png";
import pnb from "../assets/PNB.png";
import other from "../assets/Other.png";
import backtohome from "../assets/Backtohome.png";
import { getCibilString, getEnhancedReport } from "../services/ApiServices";
import ToastMsg from "../Common/ToastMsg";
import { MyContext } from "../MyContext";
import moment from "moment";
import mixpanel from 'mixpanel-browser';
import { Loader } from "../Common/Loader";
import Header from "../Common/Header";
import PremiumScore from "../Common/PremiumScore";
import goback from "../assets/go_back.png";
mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
    debug: true,
});

const CreditCardUtilization = () => {
  const [cibilString, setCibilString] = useState("");
  const[loader,setLoader]=useState(false)
  const {languageData} = useContext(MyContext)
  const [headerData, setHeaderData] = useState();
  const [accList, setAcclist] = useState();
  useEffect(() => {
    handleGetCibilString();
    handleTotalAccounts();
    mixpanel.track("Viewed_Credit_Utilization", { "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
  }, []);
  
  const handleTotalAccounts = () => {
    let data = window.location.pathname.split("/")[1];
    setLoader(true)
    getEnhancedReport(data)
      .then((res) => {
        setLoader(false)
        if (res?.status === 200) {
          console.log(res?.data?.data);
          setAcclist(res?.data?.data?.list_of_accounts);
          setHeaderData(res?.data?.data?.header_data);
        }
      })
      .catch((err) => {
        setLoader(false)
        let error = err?.response?.data?.error || "something went wrong";
        ToastMsg(error, "error");
      });
  };
  const handleGetCibilString = () => {
    getCibilString()
      .then((res) => {
        setLoader(false);
        setCibilString(res)
      })
      .catch((err) => {
        setLoader(false);
        if (err.response?.status === 401) {
          // console.log(err,"error")
        }
        let error = err?.response?.data?.error || "something went wrong";
        ToastMsg(error, "error");
      });
  };
  const navigate = useNavigate();
  return (
    <>
    {loader?<Loader/>:null}
      <div className='dashboard common-dash'>
        <div className='container container-sm'>
          <Header/>
        </div>
      </div>
      <div className="container">
        <div className="heading flex space-between align-center mb-50">
            <h1>{languageData?.Credit_Card_Utilizations||"Credit Card Utilization"}</h1>
            <img src={backtohome} alt="backtohome" width={40} height={40} onClick={() => navigate(-1)}/>
        </div>
        <div className="row">
            <div className="upper-main">
              <PremiumScore score={cibilString?.cibil_score} last_update={cibilString?.last_update} sentence={cibilString?.cibil_sentence}/>
              <div className="upper-common-box mb-40">
                <div className="upper-inner">
                  <p className="impact">
                    {headerData?.status === "Excellent"
                      ? (languageData?.High||"High")
                      : headerData?.status === "Fair"
                      ? (languageData?.High||"Medium")
                      :(languageData?.High||"Low")}{" "}
                    {languageData?.Impact|| "Impact"}
                  </p>
                  <p className="last-update">
                    {languageData?.Last_Updated||"Last updated"}: {moment(headerData?.last_updated).format("DD-MM-YYYY")}{" "}
                  </p>
                  <span
                    className={
                      headerData?.status === "Excellent"
                        ? "excellent tag"
                        : headerData?.status === "Fair"
                        ? "fair tag"
                        : "poor tag"
                    }
                  >
                    {headerData?.status === "Excellent"
                      ? (languageData?.excellent || "Excellent")
                      : headerData?.status === "Fair"
                        ? (languageData?.fair || "Fair")
                        : (languageData?.poor || "Poor")}{" "}
                  </span>
                </div>
                <div className='lower-inner'>
                    <div className="flex">
                      <div className="col">
                      {languageData?.Total_Credit_Limit|| "Total Credit Limit"}:<span>₹{new Intl.NumberFormat('en-IN').format(headerData?.total_credit_limit)}</span>
                      </div>
                      <div className="col">
                        {languageData?.Total_Overdue||"Total Overdue"}:<span>₹{new Intl.NumberFormat('en-IN').format(headerData?.total_over_due)}</span>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="col">
                      {languageData?.Total_Credit_Used||"Total Credit Used"}:<span>₹{new Intl.NumberFormat('en-IN').format(headerData?.total_credit_used)}</span>
                      </div>
                      <div className="col">
                      {languageData?.Utilization|| "Utilization"}:<span>{Math.trunc(headerData?.utilization)}%</span>
                      </div>
                    </div>
                  </div>
              </div>
            </div> 
            <div className="colmm"> 
              {accList?.length > 0  ? (
                <>
                  <h3>{languageData?.Credit_Card||"Credit Cards"}</h3>
                  <div className="parent-card">
                    {accList?.map((item, accounts) => {
                      return (
                        <div className="list-card list-card-add mt-20" key={accounts}>
                          {item?.accounts}
                          <div className="flex">
                            <div className="col">
                              {item?.member_name === "HDFC BANK" ? (
                                <img src={hdfc} alt="Loan Type" width={34} height={34} />
                              ) : item?.member_name === "SBI" || item?.member_name === "State Bank Of India" ? (
                                <img src={sbi} alt="Loan Type" width={30} height={30} />
                              ) : item?.member_name === "AXIS BANK" || item?.member_name === "Axis Bank Ltd." ? (
                                <img src={axis} alt="Loan Type" width={30} height={30} />
                              ) : item?.member_name === "KOTAK BANK" ? (
                                <img src={kotak} alt="Loan Type" width={30} height={30} />
                              ) : item?.member_name === "ICICI BANK" || item?.member_name === "ICICI BANK LIMITED" ? (
                                <img src={icici} alt="Loan Type" width={30} height={30} />
                              ) : item?.member_name === "PUNJAB NATIONAL BANK" ? (
                                <img src={pnb} alt="Loan Type" width={30} height={30} />
                              ) : item?.member_name === "BANK OF BARODA" ? (
                                <img src={bob} alt="Loan Type" width={30} height={30} />
                              ) : (
                                <img src={other} alt="Loan Type" width={30} height={30} />
                              )}
                            </div>
                            <div className="col">
                              <h4>{item?.member_name}</h4>
                              <p> ****{item?.account_number}</p>
                            </div>
                            <div className="col">
                            {item?.status === false ?
                              <p>{languageData?.Status|| "Status"}: <span style={{color:"rgba(14, 170, 0, 1)"}}>{languageData?.Active||"Active"}</span></p>
                              :
                              <p>{languageData?.Status|| "Status"}: <span style={{color:"rgba(21, 39, 69, 0.4)"}}>{languageData?.Closed|| "Closed"}</span></p>
                            }  
                            </div>
                          </div>
                          <div className="used-sc flex align-center space-between">
                            <div className="colm">
                              <p className="pb-10"> {languageData?.Total_Credit_Limit|| "Total Credit Limit"}</p>
                              <h4>₹{new Intl.NumberFormat('en-IN').format(item?.total_limit)}</h4>
                            </div>
                            <div className="colm">
                              <p className="pb-10">{languageData?.Utilization||"Utilization"}</p>
                              <h4>{Math.trunc(item?.utilization)}%</h4>
                            </div>
                            <div className="colm">
                              <p className="pb-10">{languageData?.Total_Used|| "Total Used"}</p>
                              <h4>₹{new Intl.NumberFormat('en-IN').format(item?.total_used)}</h4>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>  
                </>
              ) : (
                <div className="text-center">
                  <img
                    src={Nodata}
                    alt="nodata"
                    width={212}
                    height={277}
                    className="mt-10"
                  />
                </div>
              )}
            </div>
        </div>  
      </div>
      <div className="goback text-center mt-75">
        <img src={goback} alt="goback" width={40} height={40} onClick={() => navigate(-1)}/>
      </div>
    </>
  );
};

export default CreditCardUtilization;
