import mixpanel from 'mixpanel-browser';
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DownloadCibil from "../Common/DownloadCibil";
import { MyContext } from "../MyContext";
import tick from "../assets/tickGreen.png";
import { getSubs } from "../services/ApiServices";


mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
  debug: true,
});

function SuccessAutoPay() {
  const navigate = useNavigate()
  const {languageData} = useContext(MyContext)
  const [userD, setUserD] = useState({})
  const [subscriptionType, setSubscriptionType] = useState("")
  //const {} = props

  useEffect(()=>{
    getSubs().then((res)=>{
      console.log(res.message.subscription_type, "res da")
      setSubscriptionType(res.message.subscription_type)
        mixpanel.track("Viewed_Autopay_Success", {"Amount":res?.message.subscription_type === "MONTHLY"?"29":"199", "Plan":res.message.subscription_type, status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })

      setUserD(res)
    }).catch(()=>{})
  },[])

  const btnSuggestion = () =>{
    mixpanel.track("Interacted_Autopay_Success", {"Clicked on":"View Suggestion", status: true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })

    navigate('/')
  }
  const format = (datePa="")=>{
    let currDate = datePa.split("-").reverse().join("/");
      return currDate
  }
  const dataApp = [
  {
    title: !languageData?"Payment Amount":languageData?.Payment_Amount,
    price: `₹ ${userD?.message?.total_amount}`,
  },
  {
    title: !languageData?"Frequency":languageData?.Frequency,
    price: ` ${userD?.message?.subscription_type}`,
  },
  {
    title: !languageData?"Next Payment Date":languageData?.Next_Payment_Date,
    price: ` ${format(userD?.message?.next_transaction_date)}`,
  },
];
  return (
    <div className="container someCenter">
      <div className="successAutopay">
        <img src={tick} width={180} height={161} />
        <p className="greenClass mt-15">{!languageData?<> {subscriptionType==='MONTHLY'? "Monthly":"Annual" } Plan Active</>:<>{subscriptionType==='MONTHLY'? languageData?.Monthly_Plan_Active:languageData?.Annual_Plan_Active }</>}</p>
        <p className="pSuccess"> {!languageData?<>Autopay Mandate Completed Successfully</>:languageData?.Autopay_Mandate_Completed_Successfully}</p>

        <div className="paymentSuccess">
          {dataApp.map((some, index) => {
            return (
              <div
                key={index}
                className={index !== 0 ? "successFlex mt-10" : "successFlex"}
              >
                <p className="successTitle">{some.title}</p>
                <p className="successValue">{some.price}</p>
              </div>
            );
          })}
        </div>
        <DownloadCibil/>
        {/* <div className="flexReport">
          <p>Download Cibil Report</p>
          <img height={22} width={22} src={download} />
        </div> */}
        <div className="button-wrapper text-center mt-60">
          <button onClick={()=>{btnSuggestion()}} className="btn btnfail mb-30 mt-20">
            {!languageData?<>View Suggestions</>:languageData?.View_Suggestions}
          
          </button>
        </div>
      </div>
    </div>
  );
}

export default SuccessAutoPay;
