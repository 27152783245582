import React from 'react'
import { Loader } from '../Common/Loader';
import ToastMsg from "../Common/ToastMsg";
import { phoneVerify } from '../services/ApiServices';
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Header from '../Common/Header';
import mixpanel from 'mixpanel-browser';
import { eventOrg } from '../services/InfluxEvents'
mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
  debug: true,
});


const Login = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [loader, setLoader] = useState(false)
  const[disabled,setDisabled]=useState(true)
  const navigate = useNavigate();
  useEffect(() => {
    mixpanel.track("Viewed_Enter_Mobile_Number", {status:true})
    eventOrg("Viewed", "Viewed_Enter_Mobile_Number", "TRUE")
  }, []);
  const handleInput = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let arr = ["6", "7", "8", "9"];
    if (value === "" || /^[0-9\b]+$/.test(value)) {
      setDisabled(true)
      if (arr.includes(value[0]) || value === "" || value[1]) {
        if (value[0] == "0") {
          setMobileNumber(value.slice(1, 11));
          if (value.length > 10) {
           
            setDisabled(false)
          }
        } else {
          setMobileNumber(value.slice(0, 10));
          if (value.length >= 10) {
          
            setDisabled(false)
          }
        }
      }
    }
  };

  const handlePhoneNumberVerify = (e) => {
    e.preventDefault();
    mixpanel.track("Interacted_Enter_Mobile_Number", {status:true})
    eventOrg("INTERACTED", "Interacted_Enter_Mobile_Number", "TRUE")
    let phone_number = mobileNumber;
    setLoader(true)

    phoneVerify({ phone_number })
      .then((res) => {
 
        ToastMsg(res?.message, "success");
        localStorage.setItem("is_new_user", res?.phone_number_verified);
        localStorage.setItem("phone_number", phone_number)
        navigate(`/otp-verify?phone_number=${phone_number}`);
      })
      .catch((err) => {
        setLoader(false)
        if (err.response?.status === 401) {
          window.location.href = "/";
          localStorage.clear();
        }
        let error = err?.response?.data?.error || "something went wrong";
        ToastMsg(error, "error");
      });
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      navigate("/dashboard");
    }
  }, [navigate]);
  return (
    <>
      {loader ? <Loader /> : null}
      <div className='dashboard new-user'>
        <div className='container container-sm'>
          <Header/>
        </div>
      </div>
      <div className="main-sc">
        <div className="container_wrapper phoneverify text-center">
          <h3 id="phone">
            Welcome to World of <span>Financial Happiness</span>
          </h3>
          <form>
            <div className="input-wrapper">
              <input
                className="input"
                type="text"
                placeholder=" "
                name="phone_number"
                value={mobileNumber}
                onChange={handleInput}
                data-testid="inputtest"
              />
              <label className="label">Enter your mobile number</label>
            </div>
            <button
              data-testid="input-continue"
              className="btn"
              disabled={disabled}
              onClick={handlePhoneNumberVerify}
            >
              Get OTP
            </button>
          </form>
        </div>
      </div>
    </>
  )
}

export default Login