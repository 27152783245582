import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import Nodata from "../assets/nodata.png";
import hdfc from "../assets/HDFCC.png";
import sbi from "../assets/SBI.png";
import bob from "../assets/BOB.png";
import axis from "../assets/Axis.png";
import icici from "../assets/ICICI.png";
import kotak from "../assets/KOTAK.png";
import pnb from "../assets/PNB.png";
import other from "../assets/Other.png";
import car from "../assets/car.png";
import home from "../assets/homei.png";
import credit_card from "../assets/credit_card.png";
import goback from "../assets/go_back.png";
import backtohome from "../assets/Backtohome.png";
import personal_loan from "../assets/personal-loan.png";
import { getCibilString, getEnhancedReport } from "../services/ApiServices";
import ToastMsg from "../Common/ToastMsg";
import { MyContext } from "../MyContext";
import { Loader } from '../Common/Loader';
import moment from "moment";
import mixpanel from 'mixpanel-browser';
import PremiumScore from "../Common/PremiumScore";
import Header from "../Common/Header";
mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
    debug: true,
});

const TotalLoanAccounts = () => {
  const [cibilString, setCibilString] = useState("");
  const { languageData } = useContext(MyContext)
  const [type, setType] = useState("all");
  const [headerData, setHeaderData] = useState();
  const [accList, setAcclist] = useState();
  const [allData, setAllData] = useState([])
  const[loader,setLoader]=useState(false)

  useEffect(() => {
    handleGetCibilString();
    handleTotalAccounts();
    mixpanel.track("Viewed_Total_Loan_Account", { "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType") })
  }, []);
  const handleTotalAccounts = () => {
    setLoader(true)
    let data = window.location.pathname.split("/")[1];
    getEnhancedReport(data)
      .then((res) => {
        setLoader(false)
        if (res?.status === 200) {
          console.log(res?.data?.data);
          setAllData(res?.data?.data?.list_of_accounts)
          setAcclist(res?.data?.data?.list_of_accounts);
          setHeaderData(res?.data?.data?.header_data);
        }
      })
      .catch((err) => {
        setLoader(false)
        let error = err?.response?.data?.error || "something went wrong";
        ToastMsg(error, "error");
      });
  };
  const handleGetCibilString = () => {
    getCibilString()
      .then((res) => {
        setLoader(false);
        setCibilString(res)
      })
      .catch((err) => {
        setLoader(false);
        if (err.response?.status === 401) {
          // console.log(err,"error")
        }
        let error = err?.response?.data?.error || "something went wrong";
        ToastMsg(error, "error");
      });
  };
  const handleFilter = (value,value2) => {
    setType(value)
    if (value === "all") {
      setAcclist(allData)
    }
    else {
      const temp = allData?.filter((item) => (item.loan_type === value||item.loan_type===value2));
      setAcclist(temp)
    }

  }
  const navigate = useNavigate();
  return (
    <>
     {loader ? <Loader /> : null}
      <div className='dashboard common-dash'>
        <div className='container container-sm'>
          <Header/>
        </div>
      </div>
      <div className="container">
        <div className="heading flex space-between align-center mb-50">
            <h1>{languageData?.Total_Loan_Accounts||"Total Loan Accounts"}</h1>
            <img src={backtohome} alt="backtohome" width={40} height={40} onClick={() => navigate(-1)}/>
        </div>
        <div className="row">
            <div className="upper-main">
              <PremiumScore score={cibilString?.cibil_score} last_update={cibilString?.last_update} sentence={cibilString?.cibil_sentence}/>
              <div className="upper-common-box mb-40">
                <div className="upper-inner">
                  <p className="impact">
                    {headerData?.status === "Excellent"
                      ? (languageData?.High || "High")
                      : headerData?.status === "Fair"
                        ? (languageData?.High || "Medium")
                        : (languageData?.High || "Low")}{" "}
                    {languageData?.Impact || "Impact"}
                  </p>
                  <p className="last-update">
                    {languageData?.Last_Updated || "Last updated"}: {moment(headerData?.last_updated).format("DD-MM-YYYY")}{" "}
                  </p>
                  <span
                    className={
                      headerData?.status === "Excellent"
                        ? "excellent tag"
                        : headerData?.status === "Fair"
                          ? "fair tag"
                          : "poor tag"
                    }
                  >
                    {headerData?.status === "Excellent"
                      ? (languageData?.excellent || "Excellent")
                      : headerData?.status === "Fair"
                        ? (languageData?.fair || "Fair")
                        : (languageData?.poor || "Poor")}{" "}

                  </span>
                </div>
                <div className="lower-inner">
                  <div className="flex">
                    <div className="col">
                      {languageData?.Total_Acitve_Account || "Total Active Account"}:<span>{headerData?.total_account}</span>
                    </div>
                    <div className="col">
                      {languageData?.Closed_Account || "Closed Account"}:<span>{headerData?.total_close_account}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="colmm">
              <div className="filter-list">
                    <div className="filter-inner">
                      <a href="#" onClick={() => {
                        handleFilter("all")
                      }}
                        className={type === "all" ? "active" : ""}>
                        All
                      </a>
                      <a href="#" className={type === "credit_card" || type === "Credit Card" ? "active" : ""} onClick={() => {
                        handleFilter("credit_card", "Credit Card")
                      }}>Credit Card</a>
                      <a href="#" className={type === "personal_loan" || type === "Personal Loan" ? "active" : ""} onClick={() => {
                        handleFilter("personal_loan","short_term_personal_loan", "Personal Loan")
                      }}>Personal Loan</a>
                      <a href="#" className={type === "auto_loan_(personal)" || type === "Auto Loan" ? "active" : ""} onClick={() => {
                        handleFilter("auto_loan_(personal)", "Auto Loan")
                      }}>Auto Loan</a>
                      <a href="#" className={type === "housing_loan" || type === "Housing Loan" ? "active" : ""} onClick={() => {
                        handleFilter("housing_loan", "Housing Loan")
                      }}>Home Loan</a>
                      <a href="#" className={type === "other" ? "active" : ""} onClick={() => {
                        handleFilter("other")
                      }}>Other</a>
                    </div>
                  </div>
              {accList?.length > 0  ? (
                <>
                  {accList?.length===0?<div className="list-card list-card-add mt-20">
                  <div className="flex">
                  <p>{languageData?.Loan_Not_Applicable||"Loan Not Available"}</p>
                  </div> 
                  </div> :null}
                  <div className="parent-card">
                    { accList?.map((item, accounts) => {
                      return (
                        <div className="list-card list-card-add mt-20" key={accounts}>
                          {item?.accounts}
                          <div className="flex">
                            <div className="col">
                            {item?.member_name === "HDFC BANK" ? (
                                <img src={hdfc} alt="Loan Type" width={34} height={34} />
                              ) : item?.member_name === "SBI" || item?.member_name === "State Bank Of India" ? (
                                <img src={sbi} alt="Loan Type" width={30} height={30} />
                              ) : item?.member_name === "AXIS BANK" || item?.member_name === "Axis Bank Ltd." ? (
                                <img src={axis} alt="Loan Type" width={30} height={30} />
                              ) : item?.member_name === "KOTAK BANK" ? (
                                <img src={kotak} alt="Loan Type" width={30} height={30} />
                              ) : item?.member_name === "ICICI BANK" || item?.member_name === "ICICI BANK LIMITED" ? (
                                <img src={icici} alt="Loan Type" width={30} height={30} />
                              ) : item?.member_name === "PUNJAB NATIONAL BANK" ? (
                                <img src={pnb} alt="Loan Type" width={30} height={30} />
                              ) : item?.member_name === "BANK OF BARODA" ? (
                                <img src={bob} alt="Loan Type" width={30} height={30} />
                              ) : (
                                <img src={other} alt="Loan Type" width={30} height={30} />
                              )}
                            </div>
                            <div className="col">
                              <h4>{item?.member_name}</h4>
                              <p> ****{item?.account_number}</p>
                            </div>
                           
                            <div className="col">
                          {item?.loan_type === "credit_card" || item?.loan_type === "Credit Card" ? (
                            <img
                              src={credit_card}
                              alt="Loan Type"
                              width={30}
                              height={30}
                            />
                          ) : item?.loan_type === "personal_loan" || item?.loan_type === "Personal Loan" ? (
                            <img
                              src={personal_loan}
                              alt="Loan Type"
                              width={30}
                              height={30}
                            />
                          ) : item?.loan_type === "p2p_personal_loan" || item?.loan_type === "Personal Loan" ? (
                            <img
                              src={personal_loan}
                              alt="Loan Type"
                              width={30}
                              height={30}
                            />
                          ) : item?.loan_type ===
                            "(blps-agr)_business_loan_–_priority_sector_–_agriculture" || item?.loan_type === "Personal Loan" ? (
                            <img
                              src={personal_loan}
                              alt="Loan Type"
                              width={30}
                              height={30}
                            />
                          ) : item?.loan_type === "short_term_personal_loan" || item?.loan_type === "Personal Loan" ? (
                            <img
                              src={personal_loan}
                              alt="Loan Type"
                              width={30}
                              height={30}
                            />
                          ) : item?.loan_type === "auto_loan_(personal)" || item?.loan_type === "Auto Loan" ? (
                            <img src={car} alt="Loan Type" width={30} height={30} />
                          ) : item?.loan_type === "housing_loan" || item?.loan_type === "Housing Loan" ? (
                            <img src={home} alt="Loan Type" width={30} height={30} />
                          ) :(
                            <img src={personal_loan} alt="Loan Type" width={30} height={30} />
                          )}
                        </div>
                          </div>
                          <div className="lower-sc flex align-center space-between">
                            <div className="colm">
                            <p>{languageData?.Loan_Amount||"Loan Amount"}</p>
                              <h4>₹{new Intl.NumberFormat('en-IN').format(item?.sanctioned_amount)}</h4>
                            </div>
                            <div className="colm">
                              <p>{languageData?.Due_on||"Due on"}:</p>
                              <h4>{moment(item?.due_date).format("DD-MM-YYYY")}</h4>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <div className="text-center">
                  <img
                    src={Nodata}
                    alt="nodata"
                    width={212}
                    height={277}
                    className="mt-10"
                  />
                </div>
              )}
            </div>
          </div>    
      </div>
      <div className="goback text-center mt-75">
        <img src={goback} alt="goback" width={40} height={40} onClick={() => navigate(-1)}/>
      </div>
    </>
  );
};

export default TotalLoanAccounts;
