import mixpanel from 'mixpanel-browser';
import React, { useContext, useEffect, useState } from 'react';
import download from "../../src/assets/Download.png";
import { MyContext } from '../MyContext';
import ApiEndPoint from "../services/ApiEndPoint";
import { Equifac_report, appsFlyer } from '../services/ApiServices';
import { Loader } from "../Common/Loader";

mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
  debug: true,
});

const DownloadCibil = () => {
    const {languageData} = useContext(MyContext)
    const [loader, setLoader] = useState(false);
    const [token, setToken] = useState("");
    const [pan, setPan] = useState("");
    useEffect(() => {
      let panCard=localStorage.getItem("panCard")
      let accessToken=localStorage.getItem("accessToken")
      setToken(accessToken)
      setPan(panCard)
    }, [pan, token]);

    const handleEquifaxCibilReportForm = () => {
      setLoader(true)
      const data = {
        pan_card: localStorage.getItem("panCard")
      };
      Equifac_report(data)
      .then((res)=>{
          if (res?.status === 200) {
            setLoader(false)
            console.log(res, "equifax");
            window.location.href = res?.data?.url;
          }
      }).catch((err)=>{
            setLoader(false)
        if (err?.response?.status === 401) {
          window.location.href = "/";
          localStorage.clear();
        }
      })
    }

    const handleCibilReport = () => {
      if(window.location.pathname === "/dashboard"){
        mixpanel.track("Interacted_CA_Page", {
          status:true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType"), "Clicked on":"Download Credit Report"
        })
        
        let data ={
          "event_name": "Interacted_CA_Page",
          "event_body": {}
        }
        appsFlyer(
          data
        )
        if(localStorage.getItem("isNew")==="true"){
          handleEquifaxCibilReportForm()
        }else{
          const OurUrl = `${ApiEndPoint.CIBIL_REPORT}${localStorage.getItem("panCard")}/?token=${token}`
          window.location.href = OurUrl;
        }
      }
      else{
        mixpanel.track("Interacted_Payment_Sucess", {
          status:true, "Platform": localStorage.getItem("platform"), "Source": localStorage.getItem("openFrom"), "userType": localStorage.getItem("userType"), "Clicked on":"Download Credit Report"
        })
        
        let data ={
          "event_name": "Interacted_Payment_Sucess",
          "event_body": {}
        }
        appsFlyer(
          data
        )

        if(localStorage.getItem("isNew")==="true"){
          handleEquifaxCibilReportForm()
        }else{
          const OurUrl = `${ApiEndPoint.CIBIL_REPORT}${localStorage.getItem("panCard")}/?token=${token}`
          window.location.href = OurUrl;
        }
      }
      };
    
  return (
    <>
      {loader ? <Loader /> : null}
      <div className='text-center mt-15'>
        <a className="download-link" onClick={handleCibilReport} download target='_blank' data-testid="dlink">
          {languageData?.Download_Credit_Report || "Download Report"}
          <img src={download} alt="download" width={18} height={18} />
        </a>
      </div>
    </>
  );
}

export default DownloadCibil