import React, {useContext} from 'react';
import profile from "../assets/profile.png";
import logo from "../assets/logo-m.png";
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '../MyContext';
mixpanel.init(process.env.REACT_APP_MIX_PANNEL, {
    debug: true,
});

const Header = ({subscription,cibilString}) => {
    const navigate = useNavigate()
    const {languageData,switchLanguage,setLanguage,language} = useContext(MyContext)
  return (
    <>
        <div className='site-header'>
            <div className='logo' >
                <img src={logo} alt='profile' width={135} height={40} />
            </div>
            <div className='leftsc flex align-center space-between'>
                    {subscription === true ?
                        <div onClick={()=>{navigate('/profile')}} className='top-profile mb-30  mr-5 flex align-center'>
                            {/* <a href='/profile'> */}
                            <img src={profile} alt='profile' width={39} height={39} />
                            <span>{languageData?.hi|| "Hi"} <b>{cibilString?.name?.split(" ")[0] || null}</b></span>
                            {/* </a> */}
                        </div>
                        :
                        <div className='top-profile mb-30  mr-5 flex align-center'>
                            {/* <a href='/profile'> */}
                            <img src={profile} alt='profile' width={39} height={39} />
                            <span>{languageData?.hi|| "Hi"} <b>{cibilString?.name?.split(" ")[0]}</b></span>
                            {/* </a> */}
                        </div>
                    }
                    {subscription === true ?
                    null
                    :
                    <div className='flex align-center  mb-30'>
                        <p className={!language?"coloured":""}>English</p> &nbsp;
                        <input type="checkbox" id="switch" checked={language} onChange={(e)=>{
                            if(e.target.checked){
                                localStorage.setItem("flag",true)
                                mixpanel.track("Interacted_languageToggle", { "Clicked on": "Hindi", "Platform": "app" })
                            }
                            else{
                                mixpanel.track("Interacted_languageToggle", { "Clicked on": "English", "Platform": "app" })
                                localStorage.setItem("flag",false)
                            }
                            switchLanguage(e.target.checked)
                            setLanguage(e.target.checked)
                        }} />
                        <label for="switch">Toggle</label>
                        &nbsp;
                        <p className={language?"coloured":""}>हिन्दी</p>
                    </div>
                    }
            </div>
            </div>
    </>
  )
}

export default Header